html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    background: @bg-body;
    font-family: "Myriad Pro", sans-serif;
    font-size: 14px;
    line-height: 1;
    display: table!important;
    height: auto;
    min-height: 100vh;
    width: 100%;
}

.body-white {
    background: #fff;
}

h1, h2, h3, p, a {
    word-break: break-word;
}

p, .font-gothic {
    font-family: 'Didact Gothic', sans-serif;
}

a:active, a:hover, a:focus, button:active, button:focus, th:active, th:focus {
    outline: none !important;
    box-shadow: none !important;
    color: inherit;
    text-decoration: none;
}
.btn:active, .btn:focus {
    outline: none !important;
}
a {
    text-decoration: none;
}
main {
    height: 100%;
    display: table-row;
}
.main-wrapper {
    padding: 40px 0 80px;
}
.main-height {
    flex: 1;
}
.owl-carousel .owl-item img {
    display: inline-block;
    width: auto;
}
svg.bigHalfCircle {
    position: absolute;
    left: 0;
    bottom: -1px;
    max-height: 7%;
    height: 100%;
    width: 100%;

    &.bottom {
        top: -1px;
        bottom: auto;
        transform: rotate(180deg);
    }
}

.icon {
    display: inline-block;
}

.remove-filter {
    color: #fff;
    padding: 0 0 0 10px;
    &:hover, &:focus {
        color: #fff;
        text-decoration: none;
    }
}

.messages {
    display: none;
    font-size: 18px;
    text-align: center;
    position: absolute;
    right: 50px;
}

aside {
    float: left;
    width: 270px;
}

.content-right {
    margin-left: 287px;
}

select {
    option {
        color: black;

        &:checked {
            color: grey;
        }
    }

    &.empty {
        color: grey;
    }

    option[disabled]:first-child {
        display: none;
    }
}
.btn-default {
    padding: 8px 12px 5px;
    border-radius: 10px;
}
.input-group {
    .btn {
        border-radius: 4px;
    }
}

.modal {
    z-index: 2050;
}

.btn-default, .dropdown-content .type {
    color: #fff;
    font-weight: 600;
    &.red, &.red:active:focus {
        background-color: @color-red;
        border-color: @color-red;
        color: #fff;
        &:hover {
            background-color: @color-red-hover;
            border-color: @color-red-hover;
        }
    }
    &.black, &.black:active:focus {
        background-color: @color-black;
        border-color: @color-black;
        color: #fff;
        &:hover {
            background-color: @color-black-hover;
            border-color: @color-black-hover;
        }
    }
    &.green, &.green:active:focus {
        background-color: @color-green;
        border-color: @color-green;
        color: #fff;
        &:hover {
            background-color: @color-green-hover;
            border-color: @color-green-hover;
        }
    }
    &.purple, &.purple:active:focus {
        background-color: @color-purple;
        border-color: @color-purple;
        color: #fff;
        &:hover {
            background-color: @color-purple;
            border-color: @color-purple;
        }
    }
    &.yellow, &.yellow:active:focus {
        background-color: @color-yellow;
        border-color: @color-yellow;
        color: @color-black;
        &:hover {
            background-color: @color-yellow-hover;
            border-color: @color-yellow-hover;
        }
    }
    &.blue, &.blue:active:focus {
        background-color: @color-blue;
        border-color: @color-blue;
        color: #fff;
        &:hover {
            background-color: @color-blue-hover;
            border-color: @color-blue-hover;
        }
    }
    &.yellow-lighter, &.yellow-lighter:active:focus {
        background-color: @yellow-lighter;
        border-color: @yellow-lighter;
        color: @color-black;
        &:hover {
            background-color: @yellow-lighter-hover;
            border-color: @yellow-lighter-hover;
        }
    }
    &.pink, &.pink:active:focus {
        background-color: @color-pink;
        border-color: @color-pink;
        &:hover {
            background-color: @color-pink-hover;
            border-color: @color-pink-hover;
            color: #fff;
        }
    }
    &.tranp {
        background: transparent;
        border-color: @color-black;
        color: @color-black;
        &:hover {
            background-color: @color-blue;
            color: #fff;
        }
        &:active:focus {
            background-color: @color-blue-hover;
            color: #fff;
        }
    }
    &.btn-circle {
        background: transparent;
        border-color: transparent;
        border-radius: 50%;
        position: relative;
        width: 40px;
        height: 40px;
        text-decoration: none;
        color: @color-gray;

        span {
            font-size: 30px;
            line-height: 0.2;
            margin: 0 0 0 -7px;
            letter-spacing: 3px;
        }
        &:hover {
            background-color: #e6e6e6;
            border-color: #adadad;
            text-decoration: none!important;
        }
    }
}
.btn.btn-larg {
    border-radius: 8px;
    padding: 14px 12px 9px;
    min-width: 170px;
}
.btn-lg {
    height: 46px;
    padding: 12px 17px;
}

.switch-btn {
    .on {
        background: #fafafa;
        color: @color-black;
    }
    .off {
        background: @color-yellow;
        border-color: @color-yellow;
        color: @color-black;
        display: none;
    }
    &.On {
        .on {
            display: none;
        }
        .off {
            display: inline-block;
        }
    }
}

.text-black {
    color: @color-black;
}

.switch-checkbox {

    label {
        position: relative;
        display: inline-block;
        color: #ccc;
        height: 30px;
        border-radius: 40px;
        background-color: #ececed;
        width: 50px;
        cursor: pointer;
        vertical-align: middle;
        margin: 0 5px;
    }

    input {
        visibility: hidden;
        position: absolute;
    }

    span {
        font-weight: 600;
    }

    .toggle {
        background-color: @color-red;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04), 0 0 3px rgba(0, 0, 0, 0.07), 0 8px 0 rgba(0, 0, 0, 0.03);
        position: absolute;
        top: 1px;
        left: 0;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        z-index: 999;
        transition: all 0.30s linear;
    }

    &.On {

        .toggle {
            right: 0;
            left: auto;
            background-color: @color-green;
        }

        .on {
            color: @color-green;
        }
    }
    &.Off {
        .off {
            color: @color-red;
        }
    }
}

.form-group {

    label {
        color: #1a1a1a;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 10px;
        margin-top: 5px;
    }

    .form-control {
        padding: 10px 12px;
        height: auto;
    }
}

.select2-container {
    display: block;

    &.select2 {
        width: 100%!important;
    }

    .select2-selection__rendered {
        padding-top: 5px!important;
        position: relative;
        z-index: 1;
    }

    span.placeholder {
        position: absolute;
        top: 12px;
        left: 6px;
        opacity: 0.5;
    }
    &.select2-container--open {
        span.placeholder {
            display: none;
        }
    }
    .select2-selection__clear {
        display: none;
    }
}

.text-green, a.text-green {color: @color-green!important;}
.text-yellow, a.text-yellow {color: @color-yellow!important;}
.text-gray, a.text-gray {color: @color-gray!important;}
.text-red, a.text-red {color: @color-red!important;}
.text-blue, a.text-blue {color: @color-blue!important;}

.small-img {
    overflow: hidden;
    height: 60px;
    width: 60px;

    .avatar {
        border-right: 50%;
    }
}
.disabled {
    pointer-events: none;
    opacity: 0.6;
}
img[src=""] {
    display:none;
}
.noresize {
    resize: none;
}
.btn-block {
     & +.btn-block {
        margin-top: 0;
     }
 }
.dropdown-menu {
    label {
        cursor: pointer;
        padding: 3px 20px;
        margin: 0;
        display: block;
        font-weight: 100;
        width: 100%;

        &:hover {
            background: #f5f5f5;
        }
    }
}

.confirm-modal {
    text-align: center;
      &:before {
        display: inline-block;
        vertical-align: middle;
        content: " ";
        height: 100%;
      }
      .modal-dialog {
        display: inline-block;
        text-align: left;
        vertical-align: middle;
      }
      &.fade .modal-dialog {
        transform: scale(0.9);
        opacity: 0;
        transition: all 0.3s ease;
      }
      &.fade.in .modal-dialog {
        transform: scale(1);
        opacity: 1;
      }
      .modal-footer {
        text-align: center;
      }
      .btn {
        border-radius: 6px;
        font-size: 15px;
        margin: 0 14px;
        width: 100px;
      }
}
.confirm-modal {
    .modal-dialog {
        max-width: 400px;
    }
    .modal-header {
        border: none;
        .modal-title {
            max-width: 270px;
            margin: 0 auto;
        }
    }
    .modal-body {
        max-width: 340px;
        margin: 0 auto;
    }
    .modal-footer .btn {
        margin: 0;

        & + .btn {
            margin-left: 10px;

            @media (max-width: 420px) {
                margin-left: 0;
                
            }
        }
    }
    .modal-footer {
        border: none;
    }
}
.adv-green {
    width: 100%;
    max-width: 262px;
    padding: 11px 0 8px;
    border: none;
    font-size: 13px;
    border-radius: 7px;
}
.adv-blue {
    width: 100%;
    max-width: 262px;
    padding: 11px 0 8px;
    border: none;
    font-size: 13px;
    border-radius: 7px;
}
textarea {
    resize: none;
}
.table-responsive {
    .table {
        width: 99.9%;
    }
}
form .btn-file {
    font-size: 0!important;
    position: relative;
    color: #FFFFFF;
    input[type="file"] {
        display: block;
        position: absolute;
        opacity: 0;
        left: 0;
        width: 100%;
        top: 0;
        height: 100%;
    }
    *:not(span) {
        display: none;
    }
    span {
        font-size: 12px!important;
    }
}
.ie-upload-img {
    .advertise-upload-logo {
        img {
            height: auto;
            width: 270px;
        }
    }
}
.small-avatar {
    height: 32px;
    width: 32px;
}
.middle-text {
    font-family: @Didact-Gothic;
    font-size: 16px;
    line-height: 24px;
}
.block-gray {
    background-color: #FFFFFF;
    border: 1px solid #ebebeb;
    border-radius: 4px;
    padding: 12px;
    &.job-plan {
        box-shadow: none;
        padding: 0;
        .btn-bottom {
            padding-right: 15px;
            padding-left: 15px;
        }
    }
    .job-search-item {
        word-break: break-word;
        word-wrap: break-word;
    }
    .btn {
        text-transform: uppercase;
    }
}
.title-h1 {
    margin-top: 40px;
    margin-bottom: 40px;
}
.mt0 {
    margin-top: 0 !important;
}
.mt30 {
    margin-top: 30px;
}
strong small {
    font-size: 12px;
}
.error-list {
    margin: 0;
    padding: 0;
    li {
        color: red;
        font-size: 12px;
        margin-top: 5px;
        line-height: 26px;
    }
}
