.slider-filter-group {
    margin-bottom: 15px;
    .slider-wrap {
        margin-left: 10px;
        margin-right: 14px;
    }
    .item {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        width: 108px;
    }
    .dash {
        background: #2C3E50;
        display: inline-block;
        vertical-align: middle;
        margin: 0 4px;
        height: 1px;
        width: 12px;
    }
}
.slider.slider-horizontal {
    height: 24px;
    width: 100%;
    .slider-track {
        background: #D8D8D8;
        border-radius: 9px;
        margin-top: -2px;
        height: 4px;
        box-shadow: none;
    }
    .slider-selection {
        .slider-track();
        background: #2C3E50;
        margin-top: 0;
    }
    .slider-handle {
        background: #00A859;
        text-align: center;
        height: 24px;
        width: 24px;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        &:after {
            content: "";
            background: #2C3E50;
            border-radius: 50%;
            display: inline-block;
            margin: 6px auto 0;
            height: 12px;
            width: 12px;
        }
    }
}

.block-job {
    background-color: #FFFFFF;
    border: 1px solid @color-blue;
    border-radius: 4px;
    margin-top: 30px;
    padding:10px 20px 20px;
    position: relative;
    .name {
        margin-bottom: 3px;
        margin-top: 1px;
        word-break: break-word;
        word-wrap: break-word;
        max-width: 380px;
    }
    .description {
        color: @color-black;
        font-size: 16px;
        line-height: 24px;
        margin-top: 10px;
        word-break: break-word;
        word-wrap: break-word;
    }
    .media {
        margin-top: 8px;
    }
    .small-avatar {
        border-radius: 50%;
    }
}
.featured-tittle {
    font-family: @Didact-Gothic;
    font-size: 10px;
    background: @color-green;
    display: inline-block;
    padding: 3px 20px;
    color: #FFFFFF;
    margin-top: 30px;
    letter-spacing: 1px;
    line-height: 13px;
    font-weight: 400;
    text-transform: uppercase;
    border-radius: 6px 6px 0 0;
    & + .block-job {
        margin-top: 0;
        border-radius: 0 4px 4px 4px;
    }
}
.featured-tittle-top {
    background: @color-green;
    padding: 1px 0 0;
    font-size: 12px;
    line-height: 24px;
    border-radius: 4px;
    display: inline-block;
    color: #fff;
    margin-bottom: 15px;
    width: 71px;
    text-align: center;
}
.basic-tittle-top {
    .featured-tittle-top();
    color: @color-blue;
    background: #EEEEEE;
}
.lightSlider {
    .block-job {
        display: table;
        margin: 0;
        border: 1px solid #ebebeb;
        padding: 12px;
        height: 330px;
        width: 270px;
        .name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 242px;
        }
        .btn {
            border-radius: 8px;
            padding: 12px 0;
            font-size: 13px;
            margin: 16px 0 10px;
            line-height: 1;
            width: 100%;
        }
        .media {
            opacity: 1;
        }
        .description {
            display: table-row;
            height: 100%;
            min-height: 100%;
            div {
                width: 242px;
            }
        }
    }
}
.job-details-wrapper {
    display: table-row;
    height: 100%;
    .block-info {
        overflow: hidden;
        flex: 1;
        .job-info {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            .item {
                display: inline-block;
                flex: 1;
                margin-top: 15px;
                padding-right: 16px;
                display: flex;
                align-items: flex-start;
                .icon {
                    margin-right: 5px;
                    margin-top: -3px;
                    display: table;
                }
            }
        }
    }
    .job-name {
        font-size: 26px;
        line-height: 38px;
        margin: 0;
        word-break: break-word;
        word-wrap: break-word;
    }
    .description {
        white-space: pre-wrap;
        word-break: break-word;
        word-wrap: break-word;
        margin: 30px 0 0;
    }
    .btns-job {
        margin-top: 15px;
        & > * {
            margin: 0;
        }
        a {
            margin: 15px 5px;
            text-transform: uppercase;
        }
        .btn {
            min-width: 170px;
            &:last-child {
                margin-right: auto;
            }
        }
    }
}
.salary-block {
    background: @color-blue;
    border-radius: 8px;
    color: #FFFFFF;
    padding: 10px 20px;
    .larg {
        font-size: 24px;
        line-height: 30px;
    }
}

.between {
    margin-left: -15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    > * {
        margin-left: 15px;
    }
    &.center {
        align-items: center;
    }
}
.media.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .media-body {
        flex: 1;
        width: auto;
    }
    .form-inline {
        label {
            margin-top: 0;
            line-height: 1;
        }
    }
}
.prev-btn-link {
    color:@color-black;
    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
    .glyphicon {
        font-size: 21px;
        vertical-align: top;
    }
}
.remove-link {
    color:@color-red;
    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
}
.list-job-applications {
    background-color: #fff;
    border: 1px solid @color-blue;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 30px;
    .tittle {
        background-color: @color-blue;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        padding: 20px;
        text-transform: uppercase;
    }
    .item {
        padding: 15px;
        & +.item {
            border-top: 1px solid #ededed;
        }
        .media, p {
            margin-bottom: 15px;
        }
    }
}
.dotdotdot {
  overflow: hidden;
  display: block;
  .text-gray {
    margin-top: 15px;
  }
}
.dotdotdot.open {
  height: auto!important;
}
.btn-more {
    cursor: pointer;
    .icon.top {
        transform: rotate(180deg);
        margin-bottom: 3px;
    }
}

#modal-share {
    .register-form-wrapper {
        border: none;
        margin: 0 15px;
        width: auto;
    }
    .modal-dialog {
        max-width: 400px;
        .modal-header {
            h4 {
                float: left;
                width: 96%;
                margin: 0;
            }
        }
        .modal-footer {
            padding: 15px 30px;
        }
    }
}

.apply-job-form {
    max-width: 570px;
    float: right;
    margin-right: 20px;
    width: 100%;
    .register-form-wrapper {
        .form-wrapper {
            padding: 30px 15px;
            & > p {
                word-break: break-word;
                word-wrap: break-word;
            }
        }
    }
}

.file-group, .register-form-wrapper form .form-group .file-group {
    .btn-file-input {
        position: relative;
        padding-right: 80px;
        line-height: 1;
        cursor: pointer;
        margin: 0;
        font-size: 0;
        width: 100%;
        input {
            display: none;
        }
    }
    .btn-file-name {
        padding: 14px 12px 8px;
        text-align: center;
        background: @color-blue;
        border-radius: 0 4px 4px 0;
        color: #fff;
        position: absolute;
        font-size: 14px;
        right: 0;
        top: 0;
        height: 100%;
        width: 90px;
    }
    .file-name {
        line-height: 1.42857143;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        span {
            opacity: 0.6;
        }
    }
}
.caption-table {
    margin-bottom: 24px;
    h1 {
        margin-top: 7px;
        margin-bottom: 5px;
    }
}
.active-tble {
    background-color: #fff;
    border: 1px solid #2c3e50;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 20px;
    .table-responsive  {
        .table {
            margin-bottom: 0;
            width: 100%;
            &>thead {
                background: #F0F0F0;
                &>tr>th {
                    border: none;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 19px;
                    padding-top: 15px;
                    text-transform: uppercase;
                    white-space: nowrap;
                }
            }
            tbody>tr>td {
                vertical-align: middle;
                font-size: 13px;
                word-break: break-word;
                word-wrap: break-word;
            }
            .nowrap {
                white-space: nowrap;
            }
            .td-btn {
                max-width: 186px;
                white-space: normal;
            }
            .finish-btn {
                width: 200px;
            }
            .icons-th {
                width: 40px;
            }
            .checkbox-th {
                width: 38px;
                text-align: center;
                .check {
                    label {
                        margin: 0 auto;
                        display: block;
                        padding: 0;
                        min-height: 18px;
                        line-height: 19px;
                        width: 16px;
                    }
                    .checed {
                        background: #fff;
                    }
                    input:checked + .checed {
                        background: @color-green;
                    }
                }
            }
            .featured-tittle-top, .basic-tittle-top {
                margin-bottom: 0;
                line-height: 17px;
                font-size: 10px;
            }
        }
    }
    & + .btn-bottom .btn {
        text-transform: uppercase;
    }
}
.active-jobs {
    .pagination-wrapper .pagination {
        margin-top: 10px;
    }
}
.job-plan-name {
    font-size: 36px;
    font-weight: 700;
    line-height: 40px;
}
.job-search-wrapper {
    margin: 40px 0;
    display: table;
    table-layout: fixed;
    width: 100%;
    .forms-filter {
        display: table-cell;
        vertical-align: top;
        padding: 0;
        width: 270px;
    }
    .left-sidebar {
        float: none;
        padding: 0 13px;
        margin: 0;
    }
    .middle-content {
        display: table-cell;
        vertical-align: top;
        padding: 0 310px 40px 30px;
        width: 100%;
        .input-group {
            width: 100%;
        }
    }
    .right-sidebar {
        display: inline-block;
        vertical-align: top;
        margin-top: 0;
    }
    .btn.post-new-job {
        padding: 4px 12px;
        margin-left: 5px;
    }
}
#jobs-search-input {
    z-index: 0;
}
