.adv-form {
	&__status {
		padding: 10px 0px;
		small {
			display: inline-block;
			margin-bottom: 15px;
		}
	}
	&__title {
    margin: 0px 0px 15px;
		color: #1a1a1a;
    font-family: @Didact-Gothic;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
	}
}
.adv-delete-form {
	.is_deleted {
		display: none;
	}
}