.message-chat-wrapper {
    background-color: #fff;
    border: 1px solid @color-blue;
    margin: 40px 0 80px;
    overflow: hidden;
    // max-height: 680px;
    position: relative;
    display: block;
    width: 100%;

    ul {
        padding: 0;
        margin: 0;
        li {
            list-style: none;
        }
    }
    .last-message, .message {
        font-family: @Didact-Gothic;
        font-size: 14px;
        font-weight: 400;
        color: #959595;
        line-height: 20px;
        white-space: pre-line;
        word-break: break-word;
    }

    .name {
        color: #252525;
        font-size: 16px;
        font-weight: 600;
        white-space: nowrap;
        margin: 0 0 3px;
        text-overflow: ellipsis;
        position: absolute;
        top: 0;
        right: 0;
        overflow: hidden;
        width: 100%;
    }

    .timestamp {
        .last-message;
        white-space: nowrap;
        display: block;
    }
    .last-message {
        max-height: 42px;
        overflow: hidden;
    }

    .message-list-wrapper {
        float: left;
        display: table-cell;
        vertical-align: top;
        width: 35%;

        .header {
            background-color: #2c3e50;
            padding: 20px 32px 5px;

            .action-header {
                .dropdown-toggle {
                    color: #feffff;
                    font-size: 18px;
                    font-weight: 600;
                    text-transform: uppercase;
                    text-decoration: none;
                }
            }

            .messages-search {
                display: block;
                margin: 10px 0;

                .form-control {
                    border-right: none;
                }

                .btn {
                    border-left: none;
                    box-shadow: none;
                    padding-bottom: 5px;
                    height: 34px;

                    .glyphicon {
                        color: @color-blue;
                    }
                    &:hover {
                        background: #fff;
                    }
                }

            }
        }
        .message-list {
            overflow-x: hidden;
            overflow-y: auto;
            max-height: 575px;

            .mark-as-unread {
                background-color: @color-blue;
                border-radius: 50%;
                display: block;
                position: absolute;
                left: -18px;
                top: 50%;
                margin-top: -5px;
                height: 9px;
                width: 9px;
            }

            li {
                a {
                    border-bottom: 1px solid #ebebeb;
                    display: block;
                    text-decoration: none;
                    padding: 13px 30px 6px;

                    &.active {
                        background: @color-green;
                        color: #fff;

                        .name, .last-message, .timestamp {
                            color: #fff;
                        }
                        .mark-as-unread {
                            background-color: #fff;
                        }
                    }
                }
            }

            .media {
                overflow: visible;
            }


            .small-img {
                height: 48px;
                width: 48px;
            }

            .media-middle {
                position: relative;
            }

            .media-body {
                position: relative;
                padding-top: 17px;
            }

            .count {
                background: @color-blue;
                border: 2px solid #fff;
                border-radius: 50%;
                display: block;
                color: #fff;
                font-weight: 700;
                font-size: 18px;
                text-align: center;
                line-height: 52px;
                margin: -50px 0 0 20px;
                position: relative;
                height: 52px;
                width: 52px;

                span {
                    font-size: 14px;
                    position: relative;
                    top: -2px;
                }
            }
        }
    }

    .chat-wrapper {
        border-left: 2px solid #ebebeb;
        display: inline-block;
        vertical-align: top;
        width: 65%;

        .header {
            background-color: #2c3e50;
            padding: 6px 30px 10px;
            color: #fff;

            .select2 {
                color: #333;
                padding-top: 5px !important;
            }

            .settings-show {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
            }

            .message-list-show, .chat-list-show {
                font-size: 11px;
                color: #fff;
                position: absolute;
                top: 40%;
                left: 10px;
            }

            .media {
                overflow: visible;
                position: relative;

                .media-body {
                    padding-top: 17px;
                }
            }

            .type {
                background: @color-black;
                border-radius: 6px;
                color: #fafafa;
                font-family: @Didact-Gothic;
                font-size: 10px;
                font-weight: 400;
                text-transform: uppercase;
                display: inline-block;
                padding: 5px 20px;
                margin: 5px 0 7px;
            }
            .btn.btn-default.btn-circle {
                color: #fff;
                margin-top: -9px;

                span {
                    font-size: 27px;
                    letter-spacing: 4px;
                }
                &:hover, &:active {
                    color: #000;
                }
            }

            .name {
                color: #fff;
            }

            .compose_wrapper {
                margin-top: 20px;
            }
        }


        .chat-list {
            overflow-x: hidden;
            overflow-y: auto;
            height: 457px;

            ul {
                > li {
                    padding: 20px 30px;
                }
            }

            .message {
                background: #F7F7F7;
                border-radius: 0 15px 15px 15px;
                clear: both;
                margin-top: 4px;
                display: inline-block;
                padding: 10px 15px;
                max-width: 80%;
                font-size: 19px;
                line-height: 1.4;
                width: auto;

                &.pull-right {
                    background: #15B4F1;
                    border-radius: 15px 0 15px 15px;
                    color: #fff;
                }

            }

            .media-body {
                padding-top: 17px;
            }
        }

        .date-info {
            text-align: center;
            color: #2c3e50;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            margin: 15px auto;

            span {
                background: #fff;
                padding: 0 15px;
                display: table;
                margin: -7px auto 0;
            }

            &:before {
                background-color: #ebebeb;
                content: "";
                width: 100%;
                display: block;
                margin: 0 auto;
                height: 1px;
            }
        }

        .media-body {
            position: relative;
        }

        .footer {
            background-color: #2c3e50;
            padding: 23px 30px 0;
            // height: 160px;

            .media, .media-body {
                overflow: visible;
            }

            .message-form {
                position: relative;

                textarea {
                    resize: none;
                    padding-right: 32px;
                    height: 100px;
                }

                .sticker {
                    position: absolute;
                    right: 15px;
                    top: 15px;
                }

                button {
                    border: none;
                    background: transparent;
                }
            }

            .attachments-bar {
                font-size: 14px;
                font-weight: 700;
                margin-top: 10px;

                label {
                    position: relative;
                    cursor: pointer;
                    float: left;

                    input {
                        position: absolute;
                        visibility: hidden;
                    }

                    .icon {
                        vertical-align: text-bottom;
                    }
                }
                .pull-right {
                    margin-top: 4px;
                }
            }
        }
    }

    .conversation-settings {
        position: fixed;
        left: 0;
        right: auto;
        top: 0;
        height: 100%;
        display: block;
        opacity: 0;
        transform: translateX(100%);
        will-change: transform, opacity;
        transition: all 0.3s ease-in-out;
        z-index: 10;
        width: 100%;

        .header {
            .chat-wrapper .header;
            padding: 3px 12px 5px 40px;
            position: fixed;
            top: 0;
            z-index: 100;
            width: 100%;
            a {
                color: #fff;
            }
            .media {
                margin-top: 6px;
                .media-body {
                    padding: 11px 0 7px;
                }
            }
        }

        .wrapper {
            padding: 50px 5px 0;
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 100%;

            > .btn {
                margin: 10px 0;
                font-size: 16px;
            }
        }

        .list-group {
            border: 1px solid @color-blue;

            .small-img {
                height: 48px;
                width: 48px;
            }

            .list-group-item, a {
                border-radius: 0;
                color: @color-blue;
                font-weight: 600;
                font-size: 16px;
            }

            .tittle {
                background: @color-blue;
                border: none;
                font-size: 12px;
                color: #fff;
            }
        }

        .settings-list-links {
            .list-group-item {
                padding-top: 20px;
                padding-bottom: 20px;
            }
        }

    }

    .compose_wrapper{
        display: none;
    }
}

.emojioneemoji {
    margin: 0 2px;
    height: 20px;
    width: 20px;
}
.emojionearea {
    .emojionearea-editor {
        min-height: 7em;
        max-height: 7em;
        word-break: break-word;
    }
}

.media-user {
    overflow: visible;
    position: relative;

    .media-body {
        padding-top: 17px;

        .name {
            color: #252525;
            font-size: 16px;
            font-weight: 600;
            white-space: nowrap;
            margin: 0 0 3px;
            text-overflow: ellipsis;
            position: absolute;
            top: 0;
            overflow: hidden;
            width: 97%;
        }

        .type {
            background: #1a1a1a;
            border-radius: 6px;
            color: #fafafa;
            font-family: @Didact-Gothic;
            font-size: 10px;
            font-weight: 400;
            text-transform: uppercase;
            display: inline-block;
            padding: 5px 20px;
            margin: 5px 0 7px;

            &.red {
                background: @color-red;
            }
            &.green {
                background: @color-green;
            }
            &.yellow {
                background: @color-yellow;
            }
            &.purple {
                background: @color-purple;
            }
        }
    }
}

.chat-live-fixed {
    position: fixed;
    bottom: 0;
    z-index: 1000;
    right: 10px;
    will-change: transform, opacity;
    transition: all 0.3s ease-in-out;
    &.closed {
        transform: translateY(100%);
        .btn-chat-live {
            display: inline-block;
        }
    }

    .new-message, .add-people-select {
        display: none;
    }

    .btn.btn-sm {
        color: #333;
    }

    .chat-live-wrapper-show {
        font-size: 11px;
        color: #fff;
        position: absolute;
        top: 17px;
        left: 10px;
    }

    .btn-chat-live {
        background: @color-blue;
        border-radius: 6px 6px 0 0;
        border: 1px solid #fafafa;
        font-weight: 700;
        font-size: 15px;
        color: #fff;
        padding: 11px 30px 8px;
        text-decoration: none;
        display: none;
        position: absolute;
        right: 0;
        top: -40px;
        transition: all 0.3s ease-in-out;

        .icon {
            margin-right: 10px;
            vertical-align: middle;
        }
    }

    .chat-live-wrapper {
        .message-chat-wrapper;
        display: block;
        // overflow: hidden;
        border: none;
        border-radius: 4px 4px 0 0;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
        margin: 0;
        // max-width: 370px;
        float: right;
        opacity: 1;
        will-change: transform, opacity;
        transition: all 0.3s ease-in-out;

        & > .close {
            color: #fff;
            opacity: 1;
            margin-left: 15px;
            font-weight: 100;
            position: absolute;
            right: 11px;
            top: 12px;
            z-index: 1000;
        }

        ul {
            margin: 0 0 10px 0;
        }
        .header {
            padding: 16px 22px 4px 30px!important;
            position: relative;
            max-height: 100%;
            .name {
                position: static;
                text-align: left;
                color: #fff;
                float: left;
            }
            .btn.btn-default.btn-circle {
                background: none!important;
                border: none!important;
                width: 34px;
                height: 34px;
                &:hover, &:active, &:focus {
                    color: #fff;
                }
                span {
                    font-size: 19px;
                    color: #fff;
                }
            }
            .form-group {
                margin-top: 15px;
            }
            .btn-group {
                margin: -11px 12px 8px 0;;
            }
            .compose-btn {
                margin: -3px 14px 3px 0;
                display: block;
            }
        }
        .message-list-wrapper {
            max-width: 370px;
            width: 100%;
            will-change: transform, opacity;
            transition: all 0.3s ease-in-out;
            .message-list {
                max-height: 292px;
                min-height: 292px;
                ul {
                    padding: 0 0 57px 0;
                }
                .see-all-conversations {
                    position: absolute;
                    bottom: 0;
                    text-align: center;
                    width: 100%;
                    background: #2c3e50;
                    color: #fff;
                    padding: 25px 0 17px;
                    text-decoration: none;
                    font-size: 15px;
                    z-index: 100;
                }
            }
            &.closed {
                opacity: 0;
                position: absolute;
                transform: translateX(-100%);
            }
        }
        .chat-live {
            .chat-wrapper;
            max-width: 370px;
            margin-left: 0;
            width: 100%;
            border: none;
            will-change: transform, opacity;
            transition: all 0.3s ease-in-out;
            .new-message-name {
                display: none;
            }
            &.closed {
                opacity: 0;
                position: absolute;
                transform: translateX(100%);
            }
            &.compose {
                .chat-list ul, .baron__track, .new-message-name + .name {
                    display: none;
                }
                .new-message-name {
                    position: static;
                    text-align: left;
                    color: #fff;
                    float: left;
                    max-width: 74%;
                }
                .new-message, .new-message-name {
                    display: block;
                }
                .chat-list {
                    height: 123px;
                    .message {
                        font-style: 13px;
                    }
                }
            }
            &.more-people {
                .add-people-select {
                    display: block;
                }
                .btn-group {
                    display: none;
                }
                .chat-list {
                    height: 100px;
                }
            }
            .header {
                .btn.btn-default.btn-circle {
                    background: none!important;
                    border: none!important;
                    width: 34px;
                    height: 34px;
                    margin-top: -3px;
                    &:hover, &:active, &:focus {
                        color: #fff;
                    }
                    span {
                        font-size: 19px;
                    }
                }
            }
            .date-info {
                &:before {
                    width: 100%;
                }
            }
            .footer {
                background-color: #2c3e50;
                padding: 7px 10px 0;
                // height: 100px;
                .message-form {
                    textarea, .emojionearea .emojionearea-editor {
                        height: 50px;
                        font-size: 12px;
                        min-height: 100%;
                    }
                }
                .attachments-bar {
                    font-size: 11px;
                    label {
                        margin-right: 10px;
                    }
                    .close {
                        color: #333;
                    }
                }
            }
            .chat-list {
                height: 193px;
                width: 370px;
                .message {
                    max-width: 100%;
                    font-size: 16px;
                }
                .small-img {
                    overflow: hidden;
                    height: 45px;
                    width: 45px;
                }
                ul {
                    > li {
                        padding: 10px 0 0;
                        .media {
                            padding: 0 13px;
                            position: relative;
                            .media-body {
                                padding-left: 0;
                                padding-top: 19px;
                                .name {
                                    top: 3px;
                                    &.text-right {
                                        max-width: 86%;
                                    }
                                }
                                .timestamp {
                                    float: right;
                                    margin-top: -3px;
                                }
                            }
                        }
                        .message-attach-file {
                            padding-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
    .select2 {
        margin: 0 0 5px;
    }
    .select2-container--default .select2-selection--multiple .select2-selection__choice {
        display: block;
        background: #1a1a1a;
        font-weight: 600;
        color: #fff;
        padding: 6px 12px;
        line-height: 1.42857143;
        text-align: center;
        vertical-align: middle;
        border-radius: 4px;
        margin: 2px 2px;
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
.dropdown-menu {
    &.dropdown-menu-right {
        &:after {
            content: '';
            position: absolute;
            top: -6px;
            right: 10px;
            display: inline-block;
            border-right: 6px solid transparent;
            border-bottom: 6px solid #fff;
            border-left: 6px solid transparent;
        }
    }
}
.list-group-people {
    overflow: hidden;
    max-height: 140px;
    transition: max-height 0.15s ease-out;
    &.open {
        max-height: 500px;
        transition: max-height 0.25s ease-in;
    }
}
.mobile-version {
    .chat-live-fixed {
        display: none;
    }
}
.attachment-file-wrapper {
    display: none;
    background: #fff;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    border-radius: 3px;
    position: relative;
    clear: both;
    margin: 27px 0 10px;
    padding: 7px 20px 7px 5px;
    width: 260px;
    .top-right {
        position: absolute;
        right: 5px;
        top: 5px;
        .close {
            opacity: 1;
            font-size: 18px;
            font-weight: 100;
        }
        .ajax-loader {
            width: 15px;
            height: 15px;
            position: absolute;
            left: -4px;
        }
    }
}
.message-attach-file {
    clear: both;
    display: table;
    background: #F7F7F7;
    margin-top: 4px;
    border-radius: 0 15px 15px;
    padding: 10px 15px;
    color: #333;
    a {
        color: #333;
    }
    > div {
        display: inline-block;
        > * {
            display: block;
        }
        .name-file {
            white-space: nowrap;
            max-width: 143px;
            display: inline-block;
            text-overflow: ellipsis;
            margin: 0 -3px -2px 0;
            overflow: hidden;
        }
        .size-file {
            color: #959595;
            margin: 3px 0;
            font-weight: 100;
        }
    }
    &.pull-right {
        background: #15B4F1;
        border-radius: 15px 0 15px 15px;
        color: #fff;
        a {
            color: #fff;
        }
        > div {
            .size-file {
                color: #fff;
            }
        }
    }
}

.icon-type {
  background-image: url(../images/types_files.png);
  width: 42px;
  height: 42px;
  display: inline-block;
  vertical-align: middle;
  float: left;
  margin-right: 10px;
  &.docx {
    background-position: 0 0;
  }
  &.doc {
    background-position: -42px 0;
  }
  &.jpg {
    background-position: -84px 0;
  }
  &.png {
    background-position: -126px 0;
  }
  &.jpeg {
    background-position: -168px 0;
  }
  &.pdf {
    background-position: 0 -42px;
  }
  &.xls {
    background-position: -42px -42px;
  }
  &.xlsx {
    background-position: -84px -42px;
  }
}

.bell-anime {
    background: red;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    top: -12px;
    right: 10px;
    height: 25px;
    width: 25px;
    &.off {
        display: none;
    }
    &.bounceIn {
        animation: bounceIn 0.7s linear;
    }
    &:after {
        background: red;
        border-radius: 50%;
        content: "";
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        animation: burst 5s 1s linear infinite;
    }
    img{
        margin: 0 auto;
        padding-top: 7px;
        width: 10px;
        animation: ring 5s 1s ease-in-out infinite;
        transform-origin: 50% 10px;
        position: relative;
        z-index: 1;
    }
}

@keyframes ring {
    0% { transform: rotate(0); }
    9% { transform: rotate(10deg); }
    13% { transform: rotate(-13deg); }
    17% { transform: rotate(15deg); }
    21% { transform: rotate(-17deg); }
    25% { transform: rotate(15deg); }
    29% { transform: rotate(-13deg); }
    33% { transform: rotate(11deg); }
    37% { transform: rotate(-19deg); }
    41% { transform: rotate(17deg); }
    45% { transform: rotate(-15deg); }
    49% { transform: rotate(13deg); }
    53% { transform: rotate(-11deg); }
    57% { transform: rotate(9deg); }
    61% { transform: rotate(-7deg); }
    65% { transform: rotate(5deg); }
    69% { transform: rotate(-3deg); }
    73% { transform: rotate(1deg); }
    77% { transform: rotate(1deg); }
    93% { transform: rotate(0); }
    100% { transform: rotate(0); }
}
@-webkit-keyframes ring {
    0% { transform: rotate(0); }
    9% { transform: rotate(10deg); }
    13% { transform: rotate(-13deg); }
    17% { transform: rotate(15deg); }
    21% { transform: rotate(-17deg); }
    25% { transform: rotate(15deg); }
    29% { transform: rotate(-13deg); }
    33% { transform: rotate(11deg); }
    37% { transform: rotate(-19deg); }
    41% { transform: rotate(17deg); }
    45% { transform: rotate(-15deg); }
    49% { transform: rotate(13deg); }
    53% { transform: rotate(-11deg); }
    57% { transform: rotate(9deg); }
    61% { transform: rotate(-7deg); }
    65% { transform: rotate(5deg); }
    69% { transform: rotate(-3deg); }
    73% { transform: rotate(1deg); }
    77% { transform: rotate(1deg); }
    93% { transform: rotate(0); }
    100% { transform: rotate(0); }
}

@keyframes burst {
    0% {opacity:0.5;}
    10% {transform:scale(2); opacity:0;}
    100% {opacity:0;}
}
@-webkit-keyframes burst {
    0% {opacity:0.5;}
    10% {transform:scale(2); opacity:0;}
    100% {opacity:0;}
}

@keyframes bounceIn {
    0% {opacity:0;transform:scale(0.2);}
    20% {transform:scale(0.6);}
    30% {transform:scale(1);}
    50% {opacity:1;transform:scale(1.2);}
    70% {transform:scale(1);}
    90% {transform:scale(1.1);}
    100% {opacity:1;transform:scale(1);}
}
@-webkit-keyframes bounceIn {
    0% {opacity:0;transform:scale(0.2);}
    20% {transform:scale(0.6);}
    30% {transform:scale(1);}
    50% {opacity:1;transform:scale(1.2);}
    70% {transform:scale(1);}
    90% {transform:scale(1.1);}
    100% {opacity:1;transform:scale(1);}
}
