// media
@media (max-width:992px) {
    .profile-info {
        .vertical-line {
            right: 340px;
        }
    }
    .activity-wrapper {
        .left-content {
            margin: 40px -6px;
        }
        .right-sidebar {
            display: none;
        }
    }
    .block-job {
        .name {
            max-width: 254px;
        }
    }
    .job-search-wrapper {
        display: block;
        position: relative;
        min-height: 550px;
        .forms-filter {
            position: absolute;
            left: 0;
            top: 0;
        }
        .middle-content {
            display: block;
            padding: 0 0 40px 310px;
        }
        .right-sidebar {
            display: inline-block;
        }
    }
}
@media (max-width:767px) {
    body {
        display: block!important;
    }
    .advertising-item {
        &.advertising-item--no-margin {
            margin-bottom: 30px;
        }
    }
    .header-main {

        .header-top {
            padding: 5px 0;
            &.header-top-logout {
                padding-bottom: 40px;
                #google_translate_element {
                    position: absolute;
                    right: 10px;
                    bottom: -80px;
                    margin: 0px;
                }
            }
        }

        &.header-auth {
            .navbar {
                .navbar-header {
                    position: absolute;
                    top: -108px;
                    right: 26px;
                }
            }
            .navbar-toggle {
                margin: 0;
            }
        }

        .logo {
            img {
                position: static;
                max-width: 137px;
            }
        }

        .menu-top {
            margin: 20px 0 0;
            .user-avatar-wrapper, .dropdown-content {
                display: none !important;
            }
            .notification-wrapper {
                margin: -3px 50px 0 2px;
            }
            .menu-chat-wrapper {
                margin-right: 16px;
                margin-top: 0px;
            }
        }

        .search-navbar-form {
            display: none;
            margin: 19px 0 0;

            .input-group {

                input {
                    height: 38px;
                    width: 60%;
                }
                select {
                    height: 38px;
                    width: 40%;
                }
                button {
                    padding: 10px 12px;
                    font-size: 13px;
                }
            }
        }

        .navbar {
            min-height: 0;

            .navbar-header {
                position: absolute;
                top: -90px;
                right: 26px;
            }
        }
        .navbar-inverse {
            .navbar-nav {
                li {
                    a {
                        padding: 5px 30px;
                    }
                }
            }
        }

    }

    .slider-section {

        .slider-one-rows {
            
            .lightSlider {
                li {
                    height: 560px;
                }

                .item {
                    .wrap {
                        height: 360px;
                    }
                }
            }

        }

    }

    .navbar-toggle {
        float: none;
        margin: 8px auto;
        display: table;
    }

    .sign-in-form {
        .form-group {
            margin-top: 20px;
        }
    }

    .footer-main {
        padding-top: 20px;

        ul {
            li {
                margin-bottom: 4px;
                a {
                    font-size: 11px;
                }
            }
        }

        .title {
            margin: 0 0 15px;
        }

        .logo {
            margin: 5px 0 10px;

        }

        .social-links {
            margin-bottom: 15px;
        }

        .copy {
            text-align: center;
        }

        .footer-nav-bottom {
            float: none;
            text-align: center;

            li {
                float: none;
                display: inline-block;
            }
        }
    }

    svg.bigHalfCircle {
        max-height: 2%;
    }

    .banner-top {

        p {
            font-size: 15px;
            line-height: 25px;
        }
    }

    section {

        h2 {
            font-size: 21px;
            margin: 43px 0 35px;
        }
    }

    .about-project {

        .two-colors {
            background: none;

            h2 {
                margin-top: 0;
                padding-top: 17px;
            }
            p {
                margin-bottom: 0;
                padding-bottom: 10px;
            }

            .col-sm-6:nth-child(1) {
                padding-right: 15px;
                background: red;
            }
            .col-sm-6:nth-child(2) {
                padding-left: 15px;
                background: @color-green;
            }

            .top {
                fill: rgb(255,0,0);
                top: -2%;
                bottom: auto;
            }
            .bottom {
                fill: rgb(0,168,89);
                bottom: -1.9%;
                top: auto;
                z-index: 1;
            }
        }
    }

    .left-sidebar {
        margin-top: 0;
        position: fixed;
        left: 0;
        right: auto;
        overflow-y: auto;
        overflow-x: hidden;
        top: 2%;
        height: 90%;
        display: block;
        opacity: 0;
        transform: translateX(-100%);
        will-change: transform, opacity;
        transition: all 0.3s ease-in-out;
        z-index: 10;

        &.open {
            display: block;
            transform: translateX(0);
            opacity: 1;
            top: 60px;
            z-index: 100;
        }
    }

    .right-content {
        margin: 40px 0;
    }

    .btn-filter-mobile {
        display: block;
        transform: translateX(0);
        will-change: transform;
        transition: all 0.3s ease-in-out;
        z-index: 100;
        &.open {
            transform: translateX(270px);
        }
    }

    .right-sidebar {
        display: none;
    }

    .left-content {
        margin: 40px -6px;
    }
    .middle-content {
        flex: 0 0 100%;
        padding: 0;
        width: 100%;
        & + .right-sidebar {
            display: block;
            margin: 0 auto;
            float: none;
        }
    }
    .forum-wrapper, .job-details-wrapper {
        .left-content {
            float: none;
            padding-right: 0;
        }
        .right-sidebar {
            display: block;
            float: none;
            margin: 0 auto;
        }
    }
    .forum-info {
        .icons-legend {
            width: 100%;
        }
        >div:nth-child(1) {
            width: 100%;
            margin: 0 auto 20px;
        }
        >div:nth-child(2) {
            width: 100%;
        }
    }
    .profile-info {
        .title {
            .setting-btn {
                max-width: 100px;
            }
            .qualifications {
                bottom: -26px;
                border-radius: 0 0 6px 6px;
                left: 50%;
                transform: translateX(-50%);
            }
            .setting-btn, .switch-btn {
                width: 50%;
                max-width: 100%;
                margin-right: 0;
                min-width: auto;
            }
        }
        .avatar-profile {
            margin: 30px auto 0;
            float: none;
        }
        .info-right {
            width: auto;
            float: none;
            .edit {
                right: 0;
            }
        }
        .vertical-line {
            display: none;
        }
    }

    aside {
        float: none;
        width: 100%;
    }

    .content-right {
        margin-left: 0;
    }

    .profile-settings {

        .menu-settings {
            position: static;
        }
    }

    .modile-message-chat-wrapper {
        .container {
            padding: 0;
        }
        .row, .col-sm-12 {
            padding: 0;
            margin: 0;
        }
        .conversation-settings {
            &.open {
                transform: translateX(0);
                opacity: 1;
            }
        }
        .message-chat-wrapper {
            margin: 0;
            border: none;
            .message-list-wrapper {
                margin-top: 0;
                position: fixed;
                top: 0;
                height: 100%;
                display: block;
                opacity: 0;
                transform: translateX(-100%);
                will-change: transform, opacity;
                transition: all 0.3s ease-in-out;
                z-index: 10;
                width: 100%;

                &.open {
                    display: block;
                    transform: translateX(0);
                    opacity: 1;

                }
                .header {
                    padding: 10px 6px 15px;
                    position: fixed;
                    top: 0;
                    z-index: 100;
                    width: 100%;
                    .messages-search {
                        margin: 0;
                        width: 86%;
                        float: left;
                    }
                    > .compose-btn {
                        text-align: right;
                        margin: 6px 7px 0 0;
                    }
                }
                .baron {
                    padding-top: 59px;
                    overflow-x: hidden;
                    overflow-y: auto;
                    max-height: 100%;
                    min-height: 100%;
                }
                .message-list {
                        max-height: 100%;
                        overflow: visible;
                    li {
                        a {
                            padding: 10px 6px 5px 30px;
                        }
                    }
                    .media {
                        position: relative;
                        .timestamp {
                            position: absolute;
                            right: 0;
                            top: -3px;
                        }
                    }
                    .name {
                        padding-right: 53px;
                        width: 74%;
                    }
                }
            }

            .chat-wrapper {
                border: none;
                margin: 0;
                bottom: 0;
                top: 0;
                left: 0;
                position: fixed;
                width: 100%;
                will-change: transform, opacity;
                transition: all 0.3s ease-in-out;
                &.closed {
                    opacity: 0;
                    transform: translateX(100%);
                }
                &.closed-left {
                    opacity: 0;
                    transform: translateX(-100%);
                }
                .header {
                    padding: 0 10px 0 40px;
                    position: fixed;
                    top: 0;
                    z-index: 100;
                    width: 100%;
                    .media {
                        margin-top: 6px;
                    }
                    .name {
                        top: 14px;
                        font-size: 14px;
                    }
                    .small-img {
                        height: 28px;
                        width: 28px;
                    }
                    .type, p {
                        display: none;
                    }
                    .btn.btn-default.btn-circle {
                        margin: 0;
                    }
                    .media-left {
                        vertical-align: middle;
                    }
                }
                .chat-list {
                    padding-top: 6px;
                    height: 100%;
                    ul {
                        >li {
                            padding: 10px 6px;
                        }
                    }
                    .small-img {
                        height: 48px;
                        width: 48px;
                    }
                    .media-left {
                        padding-right: 0;
                    }
                    .name {
                        padding-right: 68px;
                        width: 95%;
                    }
                }
                .baron {
                    height: 100%;
                    padding: 45px 0 100px;
                    overflow-x: hidden;
                    overflow-y: auto;
                }
                .footer {
                    padding: 10px 10px 5px 0;
                    height: auto;
                    position: fixed;
                    bottom: 0;
                    .message-form {
                        textarea {
                            height: 50px;
                        }
                    }
                    .media-left {
                        display: none;
                    }
                    .attachments-bar {
                        label {
                            margin-left: 15px;
                            margin-right: 15px;
                        }
                    }
                    .pull-right {
                        margin: 0;
                    }
                }

                &.compose_form_wrapper {
                    overflow: hidden;
                    .header {
                        position: relative;
                        padding: 6px 30px 10px;
                    }
                    .baron {
                        height: auto;
                        padding: 0;
                    }
                    .footer {
                        position: relative;
                        .emojionearea-button {
                            display: none;
                        }
                    }

                }
            }
        }
    }

    .mobile-version {
        .header-main, .footer-main {
            display: none;
        }
        .emojionearea .emojionearea-editor {
            min-height: 50px;
        }
        .chat-live-fixed {
            display: none;
        }
    }
    .job-plan-wrapper {
        .job-plan {
            width: 100%;
        }
    }
    .between {
        margin-left: 0;
        & > * {
            margin-left: 0;
        }
    }
    .job-details-wrapper {
        .block-info {
            flex: 0 0 100%;
            .job-info .item {
                flex: 0 0 100%;
            }
        }
        .description-wrapper, .job-name-wrapper {
            display: table;
            table-layout: fixed;
            width: 100%;
        }
        .salary-block {
            margin-top: 12px;
        }
        .btns-job {
            margin-left: 0;
            text-align: center;
            & > * {
                margin: 0 auto;
                width: 100%;
            }
            a {
                margin: 7px auto;
                display: table;
            }
            .prev-btn-link {
                margin: 15px auto;
                order: 1;
            }
        }
    }
    .apply-job-form {
        float: none;
        margin-right: 0;
    }
    .register-form-wrapper form .two-btn {
        margin: 0;
        .btn {
            float: none!important;
            margin: 10px auto;
            display: table;
            width: 100%;
        }
    }
    .active-jobs {
        .table-responsive {
            margin-bottom: 0;
        }
    }
    .error404, .error500 {
        #google_translate_element {
            margin-top: 0
        }
    }
    .confirm-modal {
        .modal-footer {
            .btn {
                margin: 8px auto 0;
            }
        }
    }
    .block-job {
        .name {
            max-width: 200px;
        }
    }
    .job-search-wrapper {
        margin: 0;
        .middle-content {
            padding: 0;
        }
    }
}

@media (max-width:480px) {

    .register-form-wrapper {
        .steps-wrapper {
            .line-step {
                margin: 0;
                width: 5%;
            }
        }
        #step-1 {
            .radio-wraper {
                padding: 30px 0;
                height: 90px;
                width: 90%;
            }
        }
    }

    .block-brofile {
        float: none;
        margin-left: auto;
        margin-right: auto;
    }

    .share-wrapper {
        text-align: center;

        .share-buttons {
            margin-top: 15px;
        }

        .pull-right {
            float: none !important;
        }
    }

    .followers-list-wrapper {
        .media-right, .btn {
            display: block;
            width: 100% !important;
        }
    }

    .partners {
        .pull-right {
            float: none !important;
        }
    }

    .activity-wrapper {
        .h1 {
            font-size: 30px
        }
        .activity-list {
            >.media {
                >.media-left {
                    display: block;
                    padding-right: 30px;
                    padding-bottom: 5px;
                }
                .media {
                    overflow: hidden;
                }
                > .media-body {
                    padding: 10px;
                }
                .media-right {
                    display: block;
                    margin: 10px 0;
                    padding: 0;
                }
            }
        }
    }

    .successfully-wrapper {
        padding: 17px 0;
        margin: 20px auto 20px;

        .successfully {
            .logo {
                margin-bottom: 25px;
            }
        }
    }

    .search-block-wrapper  {
        .media-user {
            .media-right {
                display: block;
            }
        }
    }

    .share-wrapper .addthis_sharing_toolbox {
        margin: 15px;
    }

    .post-info {
        .icon {
            margin-right: 8px;
        }
        > span {
            margin-right: 10px;
        }
    }
    .chat-live-fixed {
        right: auto;
        left: 0;
        .chat-live-wrapper {
            .chat-live .chat-list {
                max-width: 320px;
            }
            .message-list-wrapper {
                max-width: 320px;
            }
        }
    }
    .slider-section .slider-one-rows .lightSlider {
        .item {
            padding-top: 150px;
            .wrap {
                height: auto;
            }
        }
        li {
            height: auto;
        }
    }
    .register-form-wrapper form .form-group label {
        margin-bottom: 4px;
    }
    .register-form-wrapper form label.error {
        margin-top: 0;
    }
    .register-form-wrapper form .form-group label {
        line-height: 1.2;
    }
    .register-form-wrapper {
        // border: none;
        margin: 0 auto 10px;
    }
    .register-form-wrapper #step-3 .prev-step {
        margin: 10px auto 5px;
    }
    .croppie-container .cr-boundary {
        width: 100% !important;
    }
    .language-formset .form-group {
        width: 100%;
    }
    .profile-info .list-languages .item .name {
        width: 64%;
    }
    .profile-info h3 {
        width: 57%;
    }
    .profile-settings .upload-logo-wrapper .btn {
        & + .btn {
            margin-top: 10px;
        }
    }
    .modal-header {
        padding: 6px;
    }
    .modal-body {
        padding: 5px;
    }
    .crop-img-wrapper.ready #upload-advertise, .crop-img-wrapper.ready #upload-img {
        margin: 3px auto 24px;
    }
    .profile-settings .settings-block .item .short {
        max-width: 248px;
    }
    .add-comment-wrapper .comment-list .comment-item .media-right {
        display: block;
    }
    #form-subscribe .btn {
        width: 100px;
    }
    .footer-main .social-links a {
        margin: 0 1px;
        width: 15%;
    }
    .accordion-simple .panel-body {
        width: 100%;
    }
    .message-chat-wrapper .chat-wrapper .chat-list {
        li {
            position: relative;
        }
        .message {
            max-width: 90%;
        }
        .media-right {
            position: absolute;
            right: 12px;
            top: 6px;
        }
        .name.text-right {
            padding-right: 68px;
        }
    }
    .table-responsive {
        .username {
            white-space: normal !important;
        }
    }

    .team {
        .social-links {
            .icon {
                background-size: 100%;
                background-position: center;
                background-repeat: no-repeat;
                margin: 0 3px;
                height: 30px;
                width: 30px;
            }
            .icon-facebook {
                background-image: url(../images/facebook.png);
            }
            .icon-google {
                background-image: url(../images/google.png);
            }
            .icon-linkedin {
                background-image: url(../images/linkedin.png);
            }
            .icon-twitter {
                background-image: url(../images/twitter.png);
            }
        }
    }
    .job-steps.steps-wrapper {
        &:after {
            display: none;
        }
        .step {
            font-size: 14px;
            width: 57px;
            height: 57px;
            &:before {
                height: 20px;
                width: 11px;
            }
        }
    }
}

@media (min-width:768px) {
    .header-main {
        height: 190px;
        &.header-home {
            height: 167px;
        }
        &.header-auth {
            height: 202px;
        }
        .header-content {
            position: fixed;
            top: 0px;
            left: 0px;
            right: 0px;
            background-color: @color-blue;
        }
        .nav-wrapper {
            border-color: @color-blue-hover;
        }
    }
    .profile-info {
        #edit-description, #edit-hiring, .col-sm-12 #edit-skills {
            width: 45%;
        }
    }
}

@media (max-width: 1200px) {
    .header-main {
        .navbar-inverse {
            .navbar-nav {
                li {
                    a {
                        padding-left: 25px;
                        padding-right: 25px;
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .header-main {
        .navbar-inverse {
            .navbar-nav {
                li {
                    a {
                        padding-left: 10px;
                        padding-right: 10px;
                        font-size: 15px;
                    }
                }
            }
        }
    }
}
