.banner-top {
    background-image: linear-gradient(to right,rgba(255,212,0,0.7) 0,rgba(26,26,26,0.7) 33%,rgba(0,168,89,0.7) 66%,rgba(255,0,0,0.7) 100%), url("../images/banner-home.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    padding: 80px 0 0;
    position: relative;
    display: table;
    width: 100%;

    h2 {
        color: #fff;
        margin-bottom: 12px;
        margin-top: 0;
    }

    p {
      font-size: 22px;
      line-height: 32px;
    }

    .bigHalfCircle {
      fill: #fafafa;
    }
    & ~ section h2 {
        margin: 50px 0 40px;
    }
}
section {
    .clearfix();

    h2 {
        font-size: 38px;
        font-weight: 700;
        color: #363636;
        text-transform: uppercase;
        margin: 100px 0 65px;
        letter-spacing: 1.2px;
    }
    &.slider-section:last-of-type {
        padding-bottom: 50px;
    }
}

.slider-section {

    h2 {
        margin: 10px 0;
    }

    .lightSlider {
        list-style: none outside none;
        padding-left: 0;
        margin-bottom:0;

        li {
            display: block;
            float: left;
            // width: 270px;
            width: 100%;
        }
    }

    .btn-controls {
        .clearfix();
        text-align: center;
        margin-bottom: 30px;
        div {
            cursor: pointer;
        }

        .prevSlide, .nextSlide {
            border-bottom: 1px solid #363636;
            border-left: 1px solid #363636;
            height: 15px;
            width: 15px;
        }

        .prevSlide {
            float: left;
            margin-left: 10px;
            transform: rotate(45deg);
        }

        .nextSlide {
            float: right;
            margin-right: 10px;
            transform: rotate(-135deg);
        }

        .playSlide {
            display: none;
            border-bottom: 8px solid transparent;
            border-left: 13px solid #2c3e50;
            border-top: 7px solid transparent;
            margin: 0 auto;
            height: 0;
            width: 0;
        }

        .pauseSlide {
            border-left: 2px solid #363636;
            border-right: 2px solid #363636;
            width: 7px;
            height: 15px;
            margin: 0 auto;
        }
    }

    .slider-one-rows {

        .lightSlider {

            li {
                height: 540px;
            }

            .item {
                background-color: @color-blue;
                margin: 0 auto;
                max-width: 370px;
                position: relative;
                padding-top: 200px;

                > img {
                    position: absolute;
                    top: 0;
                }

                .wrap {
                    background: #fff;
                    border: 1px solid #ebebeb;
                    padding: 0 13px 60px;
                    position: relative;
                    height: 330px;

                    p {
                        color: #7b7b7b;
                        font-family: @Didact-Gothic;
                        font-size: 16px;
                        line-height: 24px;
                    }

                    h3 {
                        color: #252525;
                        font-size: 16px;
                        text-transform: uppercase;
                        font-weight: 700;
                        letter-spacing: 1px;
                    }

                    .info {
                        color: #bdbdbd;
                        font-size: 12px;
                        margin-bottom: 20px;
                        .icon {
                            display: inline-block;
                            vertical-align: bottom;
                            margin-right: 14px;
                        }
                        .date {
                            margin-right: 32px;
                        }
                    }

                    .btn {
                        border: none;
                        color: #fff;
                        margin-top: 15px;
                        font-size: 13px;
                        padding: 11px 36px;
                        border-radius: 8px;
                        text-transform: uppercase;
                        position: absolute;
                        bottom: 12px;
                        left: 13px;
                    }
                }

            }
        }
    }

    .slider-two-rows {

        .lightSlider {

            .item {
                .block-brofile;
                margin: 30px auto 0;
            }
        }
        .block-brofile {
            margin-top: 0;
            & + .block-brofile {
                margin-top: 30px;
            }
        }
    }

    .slider-one-item {
        .slider-two-rows;
        margin-bottom: 30px;

        .lightSlider  {
            // margin-top: 30px;
            .item {
                height: auto;
                padding-bottom: 20px;
                margin-top: 0;

                p {
                    padding: 0;
                    word-wrap: break-word;
                }
            }
            .block-brofile {
                margin-top: 0;
            }
        }
    }
}

.block-brofile {
    background-color: #fff;
    text-align: center;
    float: left;
    margin: 30px 9px 0;
    border: 1px solid #ebebeb;
    padding: 0 24px 128px;
    position: relative;
    height: 400px;
    width: 270px;

    .title {
        color: #fafafa;
        width: 160px;
        padding: 3px 0;
        margin: 0 auto 15px;
        letter-spacing: 1px;
        font-size: 10px;
        text-transform: uppercase;
        border-radius: 0 0 6px 6px;
    }

    p {
        color: #252525;
        font-size: 12px;
        padding: 0;
        word-wrap: break-word;
        line-height: 18px;
        overflow: hidden;
    }

    .name {
        color: #252525;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        margin: 15px 0 6px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .location {
        color: #bdbdbd;
        font-size: 12px;
        margin: 0 0 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .expertise {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .btn {
        font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
        border-radius: 8px;
        padding: 12px 0;
        font-size: 13px;
        margin: 7px 0 10px;
        line-height: 1;
        width: 100%;
    }

    .two-btn {
        > * {
            width: 45%;
        }
    }

    footer {
        position: absolute;
        max-width: 220px;
        bottom: 0;
        height: 133px;
        width: 100%;

        .description {
            margin-top: 15px;
        }
    }

    &.black {
        .color-slider(@color-black, title);
    }
    &.red {
        .color-slider(@color-red, title);
    }
    &.green {
        .color-slider(@color-green, title);
    }
    &.yellow {
        .color-slider(@color-yellow, title);
    }
    &.purple {
        .color-slider(@color-purple, title);
    }
}


.subscribe {
    background-color: rgba(0, 142, 74, 0.8);
    position: relative;
    padding: 25px 0;
    margin-top: 100px;

    .bigHalfCircle {
        fill: #fafafa;
    }

    h2 {
        color: #fff;
        margin-bottom: 0;
    }
}

#form-subscribe {
    margin: 25px auto 40px;
    max-width: 570px;

    .form-control {
        border: 2px solid #ffd400;
        border-radius: 4px 0 0 4px;
        padding-top: 13px;
        padding: 0 13px;
        font-size: 13px;
        height: 46px;
    }

    .btn {correct color type
        border-radius: 0 4px 4px 0;
        color: #2f2f2f;
        padding: 0;
        height: 46px;
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        width: 150px;
    }
}

.activity-wrapper {
    margin-bottom: 40px;

    .perspectives-slid {
        .slider-section .slider-one-item .lightSlider .item {
            height: 340px;
            .btn {
                position: absolute;
                margin: 0 0 0 24px;
                width: 220px;
                left: 0;
                bottom: 20px;
            }
            .wrap {
                position: relative;
                padding-bottom: 60px;
                height: 100%;
            }
        }
    }
    .slider-section {

        & > .tittle {
            font-size: 16px;
            text-transform: uppercase;
            margin-bottom: 30px;
        }
    }
    &.mt0 {
        .slider-section {
            .slider-one-item {
                .lightSlider {
                    margin-top: 0;
                }
            }
        }
    }
}
.activity-list {
    margin-top: 30px;

    > .media {
        margin-bottom: 30px;

        > .media-left {
            padding-right: 30px;

            img {
                width: 70px;
                height: 70px;
            }
        }

        > .media-body {
            background: #fff;
            border: 1px solid @color-blue;
            border-radius: 4px;
            padding: 30px 30px 10px 30px;


            .media {
                & + .media {
                    border-top: 1px solid #ebebeb;
                    margin: 0;
                    padding-top: 15px;
                }
            }

            .media-body {
                padding: 0;

                .name {
                    position: static;
                    white-space: normal;
                    line-height: 21px;
                }
            }

            .media-right {
                white-space: nowrap;

                 > * {
                    display: inline-block;
                    vertical-align: top;
                 }

                .btn {
                    width: auto;
                    margin-left: 15px;
                }
                .follow-form {
                    .btn {
                        width: 100px;
                    }
                }
            }
        }
    }
}
