.relevant-documents-list {
  padding: 5px 0px 0px 0px;
  margin-bottom: 0px;
  list-style: none;
  li {
    line-height: 16px;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  a {
    position: relative;
    width: 75%;
    padding-top: 2px;
    padding-left: 26px;
    float: left;
    color: #333;
    font-size: 16px;
    transition: .3s;
    &:hover {
      color: #337ab7;
    }
    &:before {
      content: url('../images/external-link.png');
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }
  .document-delete {
    width: 25%;
    float: right;
    padding-top: 2px;
    border: none;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: right;
    text-transform: uppercase;
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .relevant-documents-list {
    a {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}