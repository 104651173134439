.sign-in {
    padding: 40px 0px 35px;
    margin-top: 65px;
    background-color: rgba(44, 62, 80, 0.9);
}

.sign-in-form {
    max-width: 400px;
    width: 100%;
    margin: 0px auto;
    .form-group {
        margin-top: 40px;
        margin-bottom: 20px;
    }
    .alert-error {
        margin-top: 20px;
    }
    .form-control {
        border: 2px solid #ffd400;
        &.error {
            border-color: red;
        }
    }
    button[type="submit"] {
        width: 100%;
        margin-top: 20px;
        border-radius: 4px;
        padding: 10px 12px 7px;
    }
    .forgot-password {
        color: red;
        font-size: 16px;
        letter-spacing: 1px;
    }
    .register-now {
        margin-top: 20px;
        margin-bottom: 0px;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 1px;
        a {
            color: @color-yellow;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    label.error {
        width: 100%;
        margin-bottom: 0px;
        color: red;
        font-size: 14px;
        text-align: left;
    }
}

.signin-form-wrapper {
    background-color: @color-green;
    border: 1px solid #ebebeb;
    margin: 80px auto;
    max-width: 629px;
    width: 100%;

    .form-wrapper {
        max-width: 370px;
        padding: 50px 15px 35px;
        margin: 0 auto;

        &.done {
            max-width: 506px;
        }
    }

    form {

        .form-control {
            padding: 10px 12px;
            height: auto;

            &.error {
                border-color: @color-red;
            }

        }

        label.error {
            color: @color-red;
            font-size: 12px;
            margin-top: 5px;
        }

        .btn[type=submit] {
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            padding: 10px 12px 5px;
            margin: 25px 0;
            width: 100%;
            display: inline-block;
        }
    }

    header {
        .logo {
            img {
                width: 251px;
                height: 86px;
            }
        }
        h3 {
            color: #fafafa;
            font-size: 16px;
            font-weight: 700;
            line-height: 26px;
            margin: 30px 0 20px;
            text-transform: uppercase;
        }
        h4 {
            color: #fafafa;
        }
    }

    .link-forgot-password {
        color: @color-red;
        font-size: 16px;
    }

    footer {
        font-size: 16px;
        color: #fafafa;

        a {
            color: #eabc33;
            font-weight: 700;
        }
    }

}
