.error-page-wrapper {
    background-color: #fff;
    border: 1px solid @color-blue;
    margin: 80px auto;

    .logo {
        display: block;
        margin: 30px auto;

        img {
            max-width: 250px;
        }
    }

    .btn {
        padding: 11px 60px 6px;
    }

    &.maintenance-error {
        padding-bottom: 50px;
    }

    &.not-found-error {
        padding: 30px;
    }

    p {
        line-height: 26px;
    }

    a {
        text-decoration: none;
    }

    .blocked-img {
        display: table;
        margin: -34px auto 0;
    }

    .line {
        border-right: 1px solid #ebebeb;
    }
}

.error500 {

    > .container {
        flex: 1;
    }

    .header-main {
        .header-top {
            padding-bottom: 15px;
        }
        .logo img {
            position: static;
        }
    }
    #google_translate_element {
        margin-top: -53px;
    }

    .navbar, .menu-top, .search-navbar-form, .footer-nav-bottom {
        display: none;
    }

    .footer-main {
        padding: 15px 0 5px;

        > .container {
            > .row:nth-child(1) {
                display: none;
            }
        }
        hr {
            display: none;
        }
    }
}

.error404 {

    > .container {
        flex: 1;
    }

    .header-main {
        .header-top {
            padding-bottom: 15px;
        }
        .logo img {
            position: static;
        }
    }
    #google_translate_element {
        margin-top: -53px;
    }

    .navbar, .menu-top, .search-navbar-form {
        display: none;
    }

}
.has-error {
    .control-label {
        color: red;
    }
    .form-control {
        border-color: red;
    }
}
.field-errors{
    color: red;
    .error {
        color: red;
        font-size: 12px;
        margin-top: 5px;
        margin-bottom: 0;
        margin-left: 5px;
        line-height: 1;
    }
}
.errorlist {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
        color: red;
        font-size: 12px;
    }
}
p.error, .errors .error{
    .field-errors .error;
}

.has-error {
    .help-block {
        color: red;
        font-size: 12px;
    }
}
