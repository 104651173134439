.right-sidebar {
    float: right;
    margin-top: 58px;
    width: 270px;
}

.left-content {
    margin: 40px 287px 40px 0;
}
.breadcrumb {
    background: @color-green;
    a {
        color: #fff;
    }
}
.forum-wrapper, .job-details-wrapper {
    display: inline-block;
    width: 100%;
    .left-content {
        margin: 40px 0 40px 0;
        width: 100%;
        display: inline-block;
        float: left;
        padding-right: 287px;
    }
    .right-sidebar {
        margin-top: 58px;
        width: 270px;
        margin-left: -270px;
    }
    h2 {
        margin: 40px 0 10px;
    }
    .btn-default {
        border-radius: 4px;
        border: none;
        padding: 6px 12px 5px;
    }
    .panel-default {
        border: none;
        border-radius: 0;
        .panel-heading {
            border-radius: 0;
        }
        .panel-body {
            border: 1px solid #ddd;
            border-top: none;
            display: inline-block;
            width: 100%;
        }
    }
    .preview {
        display: inline-block;
        width: 100%;
    }
    .topic-review {
        .avatar {
            float: left;
            margin-right: 10px;
        }

    }
}
.job-details-wrapper {
    .right-sidebar {
        margin-top: 40px;
    }
    .btn-default {
        border-radius: 8px;
        padding: 14px 12px 9px;
    }
}
.forum-table {
    margin-bottom: 30px;

    .avatar {
        float: left;
        margin-right: 9px;
    }

    .category {
        .subject {
            margin-top: 0;
        }
        a {
            text-decoration: none;
        }
    }

    &.yellow {
        thead {
            background-color: #ffd400;
        }
    }

    &.green {
        thead {
            background-color: @color-green;

            th {
                color: #fff;
            }
        }
    }

    thead {
        >tr {
            >th {
                font-size: 16px;
                font-weight: 400;
                padding-top: 11px;
                text-transform: uppercase;
                border: none;

                &:last-child {
                    width: 30%;
                }
            }
        }
    }

    tbody {
        background: #fff;
        border-right: 1px solid #ebebeb;
        border-left: 1px solid #ebebeb;
        >tr {
            border-bottom: 1px solid #ebebeb;

            >td {
                padding-top: 15px;
                padding-bottom: 15px;
            }
        }
    }

    .category {
        .icon-wrap {
            background-color: #ebebeb;
            border-radius: 50%;
            padding: 10px;
            vertical-align: middle;
            display: inline-block;
            margin: 0 30px 0 15px;
            width: 40px;
            height: 40px;
        }
        .forumdescription {
            vertical-align: middle;
            display: inline-block;
            max-width: 357px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            a {
                font-size: 16px;
                font-weight: 700;
                color: @color-black;
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            p {
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    .forumlastpost{

        .username-avatar{
            display: table-cell;
            vertical-align: top;
            padding-right: 15px;

            img {
                height: 40px;
                width: 40px;
            }
        }

        .forumlastpost-info {
            font-family: @Didact-Gothic;
            font-weight: 400;
            display: table-cell;
            vertical-align: top;

            .lastpostname {
                font-size: 14px;
                color: @color-black;
            }

            .lastpostusername {
                font-size: 12px;
            }

            .lastpostdate {
                color: #959595;
                font-size: 12px;
            }

            .icon {
                margin-right: 5px;
                width: 10px;
            }

            p {
                margin-bottom: 2px;
            }

        }
    }

    .topic, .amountspost {
        color: #959595;
        font-family: @Didact-Gothic;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
        padding-top: 22px;
    }

}
.forum-info {
    font-size: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    > div {
        display: flex;

        &:nth-child(1) {
            width: 60%;
        }
        &:nth-child(2) {
            width: 40%;
        }
    }

    .icons-legend {
        background: #fff;
        width: 96%;

        li {
            margin: 25px;
        }
    }

    .permissions {
        background: #fff;
        width: 100%;

        ul {
            padding: 20px;
            li {
                margin: 10px 0;
            }
        }
    }

    .title {
        background-color: @color-black;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        padding: 9px 10px 5px;
        text-transform: uppercase;
    }

    ul  {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            display: block;
            font-family: @Didact-Gothic;
            font-size: 16px;
            font-weight: 400;

            .icon {
                vertical-align: middle;
                margin-right: 25px;
                float: left;
            }
        }
    }
}

.twitter {
    .twitter-widget {
        margin-bottom: 30px;
    }
    .title {
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 15px;
    }
}

.partners {

    h2 {
        margin: 40px 0 10px;
        display: inline;
    }

    .item {
        background-color: #ebebeb;
        padding: 0 40px;
        margin-bottom: 40px;
        margin-top: 40px;
        height: 360px;
        display: flex;
        align-items: center;

        > div {
            margin: 0 auto;
        }

        h4 {
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
        }

        p {
            font-family: @Didact-Gothic;
            font-size: 14px;
            font-weight: 400;
            line-height: 26px;
        }

        img {
            height: auto;
            width: 100%;
        }
    }
}

.post-edit {
    & > .panel-heading {
        background: @yellow-lighter;
    }
}
.topic-review {
    .panel-heading {
        background: @yellow-lighter;
    }
}
.reply {
    min-width: 80px;
    padding: 6px 12px;
    margin-bottom: 20px;
}

.sign-in-option {
    font-size: 16px;
    margin: 20px 0px;
    a {
        font-weight: bolder;
    }
    .color-red {
        color: @color-red;
    }
    .color-green {
        color: @color-green;
    }
}
