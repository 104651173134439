/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-arrow-bottom {
  background-image: url(../images/sprites.png);
  background-position: -117px -68px;
  width: 14px;
  height: 6px;
}
.icon-arrow-right {
  background-image: url(../images/sprites.png);
  background-position: -117px -74px;
  width: 9px;
  height: 9px;
}
.icon-attach_photo {
  background-image: url(../images/sprites.png);
  background-position: -37px -69px;
  width: 19px;
  height: 16px;
}
.icon-calendar {
  background-image: url(../images/sprites.png);
  background-position: -76px -69px;
  width: 16px;
  height: 15px;
}
.icon-category {
  background-image: url(../images/sprites.png);
  background-position: -21px -26px;
  width: 22px;
  height: 20px;
}
.icon-chat-live {
  background-image: url(../images/sprites.png);
  background-position: 0px -26px;
  width: 21px;
  height: 22px;
}
.icon-chat {
  background-image: url(../images/sprites.png);
  background-position: -24px 0px;
  width: 27px;
  height: 20px;
}
.icon-check-ok {
  background-image: url(../images/sprites.png);
  background-position: -125px -83px;
  width: 8px;
  height: 8px;
}
.icon-clock {
  background-image: url(../images/sprites.png);
  background-position: -117px -58px;
  width: 10px;
  height: 10px;
}
.icon-comments {
  background-image: url(../images/sprites.png);
  background-position: -18px -69px;
  width: 19px;
  height: 17px;
}
.icon-company-name {
  background-image: url(../images/sprites.png);
  background-position: 0px -87px;
  width: 18px;
  height: 16px;
}
.icon-compose {
  background-image: url(../images/sprites.png);
  background-position: -36px -48px;
  width: 18px;
  height: 18px;
}
.icon-country {
  background-image: url(../images/sprites.png);
  background-position: -18px -48px;
  width: 18px;
  height: 19px;
}
.icon-email {
  background-image: url(../images/sprites.png);
  background-position: -56px -69px;
  width: 20px;
  height: 15px;
}
.icon-external-link {
  background-image: url(../images/sprites.png);
  background-position: -87px -53px;
  width: 16px;
  height: 16px;
}
.icon-eye-b {
  background-image: url(../images/sprites.png);
  background-position: -82px -87px;
  width: 20px;
  height: 12px;
}
.icon-eye {
  background-image: url(../images/sprites.png);
  background-position: -16px -103px;
  width: 20px;
  height: 11px;
}
.icon-facebook {
  background-image: url(../images/sprites.png);
  background-position: -66px -87px;
  width: 16px;
  height: 16px;
}
.icon-file-attach {
  background-image: url(../images/sprites.png);
  background-position: 0px -69px;
  width: 18px;
  height: 18px;
}
.icon-file-black {
  background-image: url(../images/sprites.png);
  background-position: -71px 0px;
  width: 16px;
  height: 21px;
}
.icon-file-gray {
  background-image: url(../images/sprites.png);
  background-position: -87px 0px;
  width: 16px;
  height: 21px;
}
.icon-file-green {
  background-image: url(../images/sprites.png);
  background-position: -71px -21px;
  width: 16px;
  height: 21px;
}
.icon-file-red {
  background-image: url(../images/sprites.png);
  background-position: -71px -42px;
  width: 16px;
  height: 21px;
}
.icon-google {
  background-image: url(../images/sprites.png);
  background-position: -34px -87px;
  width: 16px;
  height: 16px;
}
.icon-linkedin {
  background-image: url(../images/sprites.png);
  background-position: -50px -87px;
  width: 16px;
  height: 16px;
}
.icon-locked {
  background-image: url(../images/sprites.png);
  background-position: 0px -48px;
  width: 18px;
  height: 21px;
}
.icon-logout {
  background-image: url(../images/sprites.png);
  background-position: -117px 0px;
  width: 16px;
  height: 12px;
}
.icon-man2 {
  background-image: url(../images/sprites.png);
  background-position: -117px -83px;
  width: 8px;
  height: 9px;
}
.icon-manage_job {
  background-image: url(../images/sprites.png);
  background-position: -103px 0px;
  width: 14px;
  height: 16px;
}
.icon-notifications {
  background-image: url(../images/sprites.png);
  background-position: 0px 0px;
  width: 24px;
  height: 26px;
}
.icon-print {
  background-image: url(../images/sprites.png);
  background-position: -51px -20px;
  width: 20px;
  height: 19px;
}
.icon-profile {
  background-image: url(../images/sprites.png);
  background-position: -54px -48px;
  width: 16px;
  height: 16px;
}
.icon-reply {
  background-image: url(../images/sprites.png);
  background-position: 0px -103px;
  width: 16px;
  height: 14px;
}
.icon-settings {
  background-image: url(../images/sprites.png);
  background-position: -18px -87px;
  width: 16px;
  height: 16px;
}
.icon-small-card {
  background-image: url(../images/sprites.png);
  background-position: -117px -12px;
  width: 14px;
  height: 11px;
}
.icon-small-date {
  background-image: url(../images/sprites.png);
  background-position: -117px -34px;
  width: 12px;
  height: 12px;
}
.icon-small-email {
  background-image: url(../images/sprites.png);
  background-position: -117px -23px;
  width: 14px;
  height: 11px;
}
.icon-small-locked {
  background-image: url(../images/sprites.png);
  background-position: -117px -46px;
  width: 11px;
  height: 12px;
}
.icon-sticker {
  background-image: url(../images/sprites.png);
  background-position: -51px 0px;
  width: 20px;
  height: 20px;
}
.icon-time {
  background-image: url(../images/sprites.png);
  background-position: -87px -37px;
  width: 16px;
  height: 16px;
}
.icon-twitter {
  background-image: url(../images/sprites.png);
  background-position: -87px -21px;
  width: 16px;
  height: 16px;
}
