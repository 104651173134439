.footer-main {
    background-color: @gray-dark;
    color: #fff;
    padding-top: 60px;

    .title {
        margin: 0 0 15px 0;
        color: #fff;
        font-size: 19px;
        font-weight: 400;
        line-height: 26px;
        text-transform: uppercase;
    }

    .logo {
        margin: 5px 0 25px 0;
        display: block;
        img {
            left: 0;
            top: 0;
            max-height: 133px;
            max-width: 385px;
            height: auto;
            width: 100%;
        }
        .hover {
            position: absolute;
            visibility: hidden;
        }
        &:hover {
            .visible {
                visibility: hidden;
                position: absolute;
            }
            .hover {
                visibility: visible;
                position: relative;
            }
        }
    }
    .footer-nav {
        li {
            list-style: none;
        }
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            list-style-position: inside;
            margin-top: 14px;

            a {
                .title;
                font-size: 15px;
                margin: 0;
                text-decoration: none;
            }
        }
    }

    hr {
        border-top-color: #5D6165;
        box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.05);
    }

    .social-links {
        a {
            display: inline-block;
            margin: 0 9px;
            width: 10%;

            img {
               .img-responsive;
            }
        }
    }

    .footer-nav-bottom {
        float: right;
        li {
            list-style: none;
            float: left;
            margin: -8px 0 0 40px;
            a {
                font-size: 12px;
            }
            .color-green {
                color: @color-green;
                &:hover {
                    color: @color-green-hover;
                }
            }
            .color-yellow {
                color: @color-yellow;
                &:hover {
                    color: @color-yellow-hover;
                }
            }
        }
    }

    .copy {
        font-size: 12px;
    }
}
