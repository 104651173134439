.accordion-simple {

    .panel {
        box-shadow: none;
        position: relative;

        &:before {
            background: linear-gradient(to right, #1a1a1a 0%, #1a1a1a 5%, #1a1a1a 10%, #1a1a1a 15%, red 30%, red 35%, red 40%, #ffd400 55%, #ffd400 65%, #ffd400 70%, #00a859 85%, #00a859 90%, #00a859 95%, #00a859 100%);
            content: "";
            height: 3px;
            position: absolute;
            bottom: 0;
            width: 100%;
        }
    }

    .panel-heading {
        padding: 0;
        > a {
            padding: 20px 0;
            color: @color-blue;
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
            text-transform: uppercase;
            text-decoration: none;

            &[aria-expanded="true"] {
                .edit-btn {
                    transform: rotate(45deg);
                }
            }
        }
        .edit-btn {
            color: @color-green;
            display: block;
            font-weight: 700;
            cursor: pointer;
            font-size: 25px;
            line-height: 0.8;
            transition: all 0.3s ease 0s;
        }
    }
    .panel-body {
        border: none!important;
        color: #363636;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        margin: 0 auto;
        word-wrap: break-word;
        width: 90%;
    }
}

