.check {
    label {
        position: relative;
        cursor:pointer;
        color: @color-black;
        font-family: @Didact-Gothic;
        font-size: 14px;
        font-weight: 400;
        padding-left: 30px;
        line-height: 1;
    }

    .checed {
        border: 2px solid #ebebeb;
        border-radius: 4px;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;

        .icon {
            display: none;
            margin: 2px auto;
        }
    }

    input {
        opacity: 0;
        position: absolute;

        &:checked {
            & + .checed {
                background: @color-green;
                border-color: @color-green;

                .icon {
                    display: block;
                }
            }
            &.minus {
                & + .checed {
                    .icon {
                        background: #fff;
                        height: 2px;
                        margin: 5px auto 0;
                    }
                }
            }
        }
    }
}

.radio {
    .check;
    label {
        padding: 0;

        &:before {
            border: 2px solid #ebebeb;
            border-radius: 50%;
            display:inline-block;
            content: "";
            margin-right: 15px;
            margin-bottom: 4px;
            vertical-align:middle;
            width: 16px;
            height: 16px;
        }
    }
    input {
        &:checked {
            & + label:before {
                background-color: @color-green;
                border-color: @color-green;
            }
            & + label:after {
                content: "";
                width: 8px;
                height: 8px;
                left: 5px;
                position: absolute;
                top: 5px;
            }
        }
    }
    &.radio-right {
        label {
            padding-left: 0;
            width: 100%;

            &:before {
                float: right;
                margin-left: 15px;
                margin-right: 0;
            }
        }
    }
}

.form-group-checkbox {
    margin-bottom: 15px;

     .check {
        margin-bottom: 15px;
     }
}
.steps-wrapper {
    margin: 0 auto;
    text-align: center;

    .step {
        border: 2px solid #ebebeb;
        border-radius: 50%;
        color: @color-black;
        vertical-align: middle;
        padding-top: 17px;
        font-size: 24px;
        font-weight: 400;
        text-align: center;
        text-transform: uppercase;
        display: inline-block;
        width: 80px;
        height: 80px;

        &.active {
            background-color: @color-black;
            color: #fafafa;
        }

        &.done {
            background: @color-green;
            font-size: 0;
            &:before {
                border-right: 2px solid #fff;
                border-bottom: 2px solid #fff;
                content: "";
                display: inline-block;
                height: 30px;
                width: 14px;
                transform: rotate(40deg);
            }
            span {
                display: none;
            }
        }

        span {
            font-size: 14px;
            text-transform: none;
            display: block;
        }
    }
    .line-step {
        background-color: #ebebeb;
        border-radius: 2px;
        display: inline-block;
        vertical-align: middle;
        margin: 0 1%;
        height: 4px;
        width: 15%;
    }
}
.job-steps.steps-wrapper {
    display: flex;
    max-width: 870px;
    flex-wrap: wrap;
    position: relative;
    justify-content: space-between;
    margin-bottom: 20px;
    &:after {
        content: "";
        background-color: #ebebeb;
        border-radius: 2px;
        vertical-align: middle;
        height: 4px;
        width: 98%;
        align-self: center;
        position: absolute;
        left: 1%;
        top: 50%;
        margin-top: -2px;
        z-index: -2;
    }
    .step:not(.active) {
        background: @color-green;
        & > * {
            display: none;
        }
    }
    .step {
        position: relative;
        &:before {
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            content: "";
            display: inline-block;
            height: 30px;
            width: 14px;
            transform: rotate(40deg);
        }
        &:after {
            content: "";
            background: @bg-body;
            position: absolute;
            border-radius: 50%;
            display: block;
            left: -20%;
            top: -20%;
            height: 140%;
            width: 140%;
            z-index: -1;
        }
        & > * {
            position: relative;
        }
        &.active {
            &:before {
                display: none;
            }
        }
        &.active ~ .step {
            background: @bg-body;
            &:before {
                display: none;
            }
            & > * {
                display: block;
            }
        }
    }
}
.register-form-wrapper {
    background-color: #fff;
    border: 1px solid #2c3e50;
    margin: 40px auto 80px;
    max-width: 629px;
    width: 100%;

    .form-wrapper {
        max-width: 420px;
        padding: 0 15px 20px;
        margin: 0 auto;
        width: 100%;

        > p {
            font-size: 16px;
            line-height: 26px;
        }
    }

    form {

        .form-control {
            padding: 12px 12px 8px;
            height: auto;
            line-height: 1;

            &.error {
                border-color: @color-red;
            }

        }

        .form-group {
            label {
                color: @color-black;
                font-family: @Didact-Gothic;
                font-size: 16px;
                font-weight: 400;
                line-height: 26px;
                margin-bottom: 15px;
                margin-top: 10px;
                width: 80%;

                > div {
                    line-height: 1;
                }
            }
        }

        label.error {
            color: @color-red;
            font-size: 12px;
            line-height: 1.2;
            margin-top: 5px;
            margin-bottom: 0;
            width: 100%;
        }

        .btn {
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            padding: 14px 12px 9px;
            width: 100%;
            max-width: 170px;
        }
        .clearfix {
            > .btn {
                width: 49%;
            }
        }
        .prev-step {
            color: #eabc33;
            font-size: 16px;
            font-weight: 700;
            margin-top: 12px;
            background: none;
            border: none;
        }

        .required {
            font-family: @Didact-Gothic;
            font-size: 12px;
            color: @color-red;
            float: right;
            margin-top: 13px;
        }

        .upload-logo-wrapper {

            label {
                word-wrap: break-word;
            }

            .upload-logo {
                width: 100%;
                display: inline-block;
                float: right;
                text-align: right;

                .preview-logo {
                    display: inline-block;
                    margin-right: 20px;
                    width: 60px;
                    text-align: center;
                    height: 60px;
                    background: #ececed;
                    float: left;
                    vertical-align: middle;
                    overflow: hidden;
                    border-radius: 4px;
                    border: 1px solid #ccc;

                    img {
                        height: 100%;
                        width: auto;
                        margin: 0 auto;
                    }
                }

                .preview-avatar {
                    display: inline-block;
                    margin-right: 20px;
                    width: 60px;
                    float: left;
                    text-align: center;
                    height: 60px;
                    vertical-align: middle;
                    overflow: hidden;

                    img {
                        height: 100%;
                        width: auto;
                        margin: 0 auto;
                    }
                }

            }
        }
        .radio {
            border-color: transparent;
            box-shadow: none;
            padding: 0;

            label {
                font-family: @Didact-Gothic;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                padding-left: 20px;
                width: 100%;
            }
        }

        .select2.error {
            & + .select2-container {
                .select2-selection {
                    border-color: @color-red;
                }
            }
        }
    }

    header {
        padding-top: 30px;

        .logo {
            img {
                width: 251px;
                height: 86px;
            }
        }
        h3 {
            color: @color-black;
            font-size: 16px;
            font-weight: 700;
            margin: 30px 0 20px;
            padding: 0 5px;
            text-transform: uppercase;
        }
    }

    .title-step {
        color: #2c3e50;
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        padding: 0 15px;
        margin: 30px 0;

        p {
            color: #7b7b7b;
            font-family: @Didact-Gothic;
            font-size: 16px;
            font-weight: 400;
        }
    }

    #step-1 {
        margin: 10px 0;
        text-align: center;

        .radio-wraper {
            margin: 1%;
            display: inline-block;
            font-size: 24px;
            padding: 30px;
            font-weight: 600;
            line-height: 24px;
            vertical-align: top;
            cursor: pointer;
            height: 250px;
            width: 43%;

            &.company {
                background: #ffd400 url('../images/building.png') no-repeat center bottom;
                color: @color-black;
            }

            &.individual {
                background: @color-green url('../images/man-bg.png') no-repeat center bottom;
                color: #fff;
            }

            &:hover {
                text-decoration: none;
            }

        }
    }

    #step-3 {
        .prev-step {
            margin: 25px auto 5px;
            display: table;
        }
        .btn {
            margin-top: 20px;
            margin-bottom: 20px;
            width: 45%;
        }
        .currently {
            display: none;
        }
        .upload-logo {
            .btn {
                width: 106px;
                margin-top: 8px;
            }
        }
    }

    .link-forgot-password {
        color: @color-red;
        font-size: 16px;
    }

    footer {
        font-size: 16px;
        color: #7b7b7b;;

        a {
            color: #eabc33;
            font-weight: 700;
        }
    }

}

.crop-img-wrapper {

    #upload-img, &.ready .upload-msg, #upload-advertise {
        display: none;
    }

    &.ready #upload-img, &.ready  #upload-advertise {
        display: block;
        margin: 0 auto 30px;
        padding: 0;
        height: 300px;
    }

    .upload-msg {
        text-align: center;
        margin: 24px auto;
        padding: 135px 12px 0;
        font-size: 22px;
        color: #aaa;
        border: 1px solid #aaa;
        height: 300px;
        max-width: 300px;
    }
    .croppie-container .cr-slider-wrap {
        margin: 16px auto;
    }

}
#btnCrop, #advertiseBtnCrop {
    width: 100px;
}
.rotation-btns {
    text-align: center;
    margin-top: 64px;
    margin-bottom: 16px;

    .rotate-cw-btn {
        cursor: pointer;
    }
}

.upload-logo-btn {
    position: relative;
    margin: 0 !important;
    cursor: pointer;
    width: 100px;

    input[type="file"] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
}

.select2-container--default {
    .select2-selection--single {
        border-color: #ccc;
        height: auto;

        .select2-selection__rendered {
            font-size: 14px;
            padding-left: 12px;
            padding-top: 7px;
            padding-bottom: 4px;
        }

        .select2-selection__arrow {
            top: 5px;
        }
    }
}
#id_step3-skills {
    & + .select2 {
        .select2-selection {
            padding-left: 6px;
        }
        span.placeholder {
            left: 12px;
        }
    }
}
.advertise_form {
    form {
        .upload-logo-wrapper {

            label {
                margin-bottom: 0;
                width: 80%;
            }

            .advertise-upload-logo {

                .preview-logo {
                    background: #ececed url('../images/no-logo2.png') no-repeat center;
                    border-radius: 0;
                    margin: 0 auto 13px;
                    overflow: hidden;
                    display: block;
                    float: none;
                    height: 160px;
                    width: 270px;

                    img {
                        height: 160px;
                        width: 270px;
                    }
                }

                .btn {
                    max-width: 270px;
                    margin: 0 auto 20px;
                    display: table;
                }

            }
        }
        .helper {
            line-height: 18px;
            margin: 5px 0 20px;
            clear: both;
            display: block;
        }
    }
}

.successfully-wrapper  {
    background-color: #fff;
    border: 1px solid @color-blue;
    margin: 40px auto 80px;
    max-width: 630px;
    text-align: center;
    padding: 30px 0;
    width: 100%;

    .successfully {
        margin: 0 auto;
        padding: 0 15px;

        .logo {
            display: block;
            margin-bottom: 80px;

            img {
                display: block;
                max-width: 80%;
                height: auto;
                margin: 0 auto;
            }
        }

        p {
            color: #7b7b7b;
            font-family: @Didact-Gothic;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 25px;
            word-break: break-word;
            word-wrap: break-word;
            a {
                color: @color-black;
            }
        }

        .h1 {
            text-transform: uppercase;
        }
    }
}

.left-inner-addon {
    position: relative;

    input {
        padding-left: 30px !important;
    }

    i {
        position: absolute;
        left: 10px;
        top: 14px;
        pointer-events: none;
    }
}

.right-inner-addon {
    position: relative;

    input {
        padding-right: 30px !important;
    }

    i {
        position: absolute;
        right: 10px;
        top: 14px;
        pointer-events: none;
    }
}

.modal-open {

    .modal#modal-payment {
        height: 100%;

        .modal-dialog {
            margin: auto;
            transform: translate(0, 30%);
        }
    }

}

.payment-modal-wrapper {

    .modal-header {
        background: @color-blue;
        border-radius: 4px 4px 0 0;
        border: none;
        padding: 15px 25px;
        text-align: center;
        color: #fff;

        .logo {
            margin: -48px 0 15px 0;
        }

        h2 {
            font-size: 17px;
            font-weight: 600;
            line-height: 26px;
            text-transform: uppercase;
            margin: 0;
        }
        p {
            font-family: @Didact-Gothic;
            font-size: 13px;
            line-height: 20px;
        }

        .close {
            color: #fff;
            opacity: 1;
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }

    .modal-content {
        box-shadow: none;
        border: none;
        margin: auto;
        width: 300px;
        .small {
            line-height: 16px;
            text-align: left;
        }
    }

    .payment-form-wrapper {
        color: @color-blue;
        margin: auto;
        padding: 30px 0;
        width: 230px;

        .card-payment {
            .form-control {
                border-radius: 4px 4px 0 0;
                border-bottom-color: transparent;
                &:focus {
                    border-color: #66afe9;
                }
            }
        }

        .date-payment {
            width: 50%;
            display: inline-block;
            float: left;

            .form-control {
                border-radius: 0 0 0 4px;
                border-right-color: transparent;
                &:focus {
                    border-color: #66afe9;
                }
            }
        }

        .cvc-payment {
            width: 50%;
            display: inline-block;

            .form-control {
                border-radius: 0 0 4px 0;
            }
        }

        .btn {
            padding: 11px 0;
            margin-top: 18px;
        }
    }
}
.payment-errors {
    color: #f00;
}

.certificate-formset {
    .date-wrapper {
        position: relative;
        .form-group {
            padding: 0 5px;
        }

        .line {
            position: absolute;
            left: 50%;
            margin: -8px 0 0 -8px;
            width: 20px;
            height: 2px;
            background: #ccc;
            top: 50%;
        }
    }
}

.styler {
    cursor: pointer;
    select {
        display: none !important;
    }
    .jq-selectbox__dropdown {
        background: #fff;
        border: 1px solid #aaa;
        box-shadow: 0 0 4px #ccc;
        left: 0;
        margin-top: 4px;
        width: 100%;
        ul {
            padding: 0;
            margin: 0;
            li {
                padding: 13px 10px;
                margin: 0;
                line-height: 18px;
                cursor: pointer;
                &:hover {
                    border-color: #2c3e50;
                    background: #2c3e50;
                    color: #fff;
                }
                & + li {
                    border-top: 1px solid #ccc;
                }
            }
        }
    }
    .jq-selectbox__trigger-arrow {
        position: absolute;
        top: 14px;
        right: 0;
        width: 0;
        height: 0;
        border-top: 5px solid #999;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
    }
    .jq-selectbox__select-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.symbol {
    display: block;
    padding-left: 10px;
    margin-bottom: 5px;
    clear: both;
}

.success-info {
    color: #fff;
    padding-bottom: 43px;
    h1 {
        font-weight: 700;
        line-height: 1;
        font-size: 39px;
        margin: 78px 0 3px;
    }
    p {
        font-size: 16px;
    }
    header {
        margin-bottom: 80px;
    }
    .btn {
        font-size: 14px;
        padding: 10px 0;
        border-radius: 8px;
    }
}
.create-job-form {
    .full-form {
        max-width: 100%;
    }
    header {
        h3 {
            margin-top: 0;
            margin-bottom: 30px;
            text-transform: none;
        }
    }
    form {
        .prev-step {
            color:@color-black;
            font-size: 14px;
            font-weight: 600;
            line-height: 26px;
            margin-top: 30px;
            .glyphicon {
                font-size: 21px;
                vertical-align: top;
            }
        }
    }
    .two-btn {
        margin-top: 30px;
    }
}
.row.small-p {
    margin-right: -5px;
    margin-left: -5px;
    & > * {
        padding-right: 5px;
        padding-left: 5px;
    }
    .form-control {
        height: 40px;
    }
}
.after-add-more {
    margin-top: 30px;
    .middle-text {
        line-height: 1;
    }
    & ~ .item {
        display: table;
        width: 100%;
        .added_questions {
            display: table-cell;
        }
        .remove {
            display: table-cell;
            color: @color-red;
            font-size: 30px;
            line-height: 1;
            text-align: center;
        }
        & + .item {
            margin-top: 20px;
        }
    }
}
.icon-plus {
    color: #00A859;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    vertical-align: middle;
    margin-right: 6px;
    display: inline-block;
    border: 1px solid;
    border-radius: 50%;
    width: 17px;
    text-align: center;
    // padding-left: 1px;
    height: 17px;
}
.job-plan-wrapper {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    .job-plan {
        width: 48%;
    }
}
.job-plan {
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 16px 0 rgba(0,0,0,0.2);
    .name {
        font-size: 36px;
        font-weight: bold;
        line-height: 40px;
        text-align: center;
        padding: 20px 0 10px;
    }
    .prise {
        background: @color-blue;
        color: #FFFFFF;
        text-align: center;
        display: flex;
        height: 90px;
        justify-content: center;
        align-items: center;
        .larg {
            font-size: 32px;
            line-height: 40px;
        }
    }
    .option {
        margin: 0;
        padding: 0 15px;
        li {
            list-style: none;
            text-align: center;
            padding: 20px 0;
            & + li {
                border-top: 1px solid #EBEBEB;
            }
        }
    }
    .btn-bottom {
        border-top: 1px solid #EBEBEB;
        text-align: center;
        padding: 20px 0;
    }
    &.plan-type-1 {
        .name {
            color: @color-yellow;
        }
    }
    &.plan-type-2 {
        .name {
            color: @color-green;
        }
    }
}
.job-step5 {
    h2 {
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
        text-transform: uppercase;
        margin: 30px 0 0 0;
    }
    .item {
        margin-top: 25px;
        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
        }
        h3 {
            color: #9e9e9e;
            margin: 4px 0 2px;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
        }
        .edit-btns, .simle-btns {
            a, button {
                font-size: 12px;
                font-weight: 600;
                display: inline-block;
                text-transform: uppercase;
                text-decoration: none;
                margin: 0;
            }
            .save {
                display: none;
            }
        }
        .fild-val {
            font-size: 16px;
            line-height: 26px;
            word-break: break-word;
            word-wrap: break-word;
            &.descripton {
                    white-space: pre-wrap;
                    word-break: break-word;
                    word-wrap: break-word;
            }
        }
        .form-group {
            margin-top: 15px;
        }
    }
    &.questions {
        .item {
            border-bottom: 1px solid #CCCCCC;
            margin: 10px 0;
            .title {
                flex-wrap: nowrap;
                align-items: flex-start;
            }
            .questions-btns {
                margin: 10px 0 0 20px;
                white-space: nowrap;
                .edit-btns {
                    display: inline-block;
                }
                .remove {
                    font-size: 12px;
                    font-weight: 600;
                    display: inline-block;
                    text-transform: uppercase;
                    text-decoration: none;
                    margin-right: 7px;
                }
            }
        }
    }
    & + .after-add-more {
        .middle-text {
            color: #9e9e9e;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
        }
        &:after {
            content: '';
            display: block;
            background: #CCCCCC;
            height: 1px;
            width: ~"calc(100% - 30px)";
            position: relative;
            left: 15px;
        }
        & ~ .item {
            margin-top: 20px;
        }
    }
}
.succes-btns .btn {
    max-width: 230px;
    margin: 15px auto;
}
