// Mixins
.clearfix() {
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

.img-responsive(@display: inline-block) {
    display: @display;
    max-width: 100%;
    height: auto;
}

.color-slider(@color, @item){
    border-top: 2px solid @color;

    .@{item} {
        background-color: @color;
    }
}
