@font-face {
    font-family: "Myriad Pro";
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/MyriadPro-Regular.eot');
    src: url('../fonts/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MyriadPro-Regular.woff2') format('woff2'),
        url('../fonts/MyriadPro-Regular.woff') format('woff'),
        url('../fonts/MyriadPro-Regular.ttf')  format('truetype'),
        url('../fonts/MyriadPro-Regular.svg') format('svg');
}

@font-face {
    font-family: "Myriad Pro";
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/MyriadPro-Semibold.eot');
    src: url('../fonts/MyriadPro-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/MyriadPro-Semibold.woff2') format('woff2'),
        url('../fonts/MyriadPro-Semibold.woff') format('woff'),
        url('../fonts/MyriadPro-Semibold.ttf')  format('truetype'),
        url('../fonts/MyriadPro-Semibold.svg') format('svg');
}
