.about-project {

    .yellow {
        background-color: #ffd400;
        padding-top: 20px;
        padding-bottom: 50px;
    }

    .two-colors {
        background: linear-gradient(to right, rgb(255,0,0) 50%,rgb(0,168,89) 50%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0000', endColorstr='#00a859',GradientType=1 );
        color: #fff;
        position: relative;

        h2 {
            color: #fff;
        }

        .col-sm-6:nth-child(1) {
            padding-right: 80px;
        }
        .col-sm-6:nth-child(2) {
            padding-left: 80px;
        }

        .top {
            fill: url("#linearGradient-svg");
            top: -6.9%;
            bottom: auto;
        }
        .bottom {
            fill: url(#linearGradient-svg-b);
            bottom: -6.9%;
            top: auto;
            z-index: 1;
        }

    }

    h2 {
        margin: 40px 0 10px;
    }

    p {
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
    }
}

.features {
    background: @color-black;
    color: #fff;
    position: relative;
    padding-bottom: 50px;

    .bigHalfCircle {
        fill: @bg-body;
    }

    h2 {
        color: #fff;
    }

    .img {
        width: 140px;
        height: 140px;
        border-radius: 50%;
        margin: 0 auto;
        overflow: hidden;

        img {
            display: block;
            width: 140px;
            height: 140px;
        }
    }

    .name {
        font-size: 22px;
        text-transform: uppercase;
        margin: 35px 0 20px;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 40px;
    }
}

.team {
    padding-bottom: 50px;

    h2 {
        margin: 60px 0;
    }

    .name {
        color: #252525;
        font-size: 16px;
    }

    .work-position {
        color: #bdbdbd;
        font-size: 12px;
    }

    .description {
        text-align: left;
        color: #252525;
        font-size: 12px;
        font-weight: 400;
        line-height: 26px;
    }

    .img {
        margin: 0 auto 25px;
        display: inline-block;
        img {
            width: 100%;
            height: auto;
        }
    }
}

.about-video {
    padding: 0px 15px 50px;
    iframe {
        width: 100%;
        height: 220px;
    }
}

.social-links {
    margin: 15px 0 5px;

    a {
        padding: 6px;
    }
}

@media(min-width: 568px) {
    .about-video {
        iframe {
            height: 300px;
        }
    }
}

@media(min-width: 768px) {
    .about-video {
        iframe {
            max-width: 500px;
            height: 300px;
        }
    }
    .about-project {
        .two-colors {
            padding-bottom: 30px;
        }
    }
}

@media(min-width: 992px) {
    .about-video {
        iframe {
            max-width: 600px;
            height: 350px;
        }
    }
}

@media(min-width: 1200px) {
    .about-video {
        iframe {
            max-width: 800px;
            height: 500px;
        }
    }
}
