.advertising-item {
	position: relative;
  display: block;
  max-width: 270px;
  height: 475px;
  padding-bottom: 50px;
  margin: 0px auto 40px;
	border: 1px solid #ebebeb;
  &--small {
    height: auto;
    min-height: 220px;
    padding-bottom: 0px;
    .advertising-item__title {
      white-space: normal;
    }
  }
  &--no-margin {
    margin-bottom: 0px;
  }
	&__status {
		position: absolute;
		left: 0;
		bottom: 100%;
		display: block;
		min-width: 80px;
		font-size: 10px;
		letter-spacing: 1px;
		text-align: center;
    text-transform: uppercase;
		padding: 5px 0px 3px;
    border-radius: 6px 6px 0px 0px;
    &.active {
    	color: #ffffff;
    	background-color: #00a859;
    }
    &.inactive {
    	color: #ffffff;
    	background-color: #1a1a1a;
    }
	}
	&__photo {
		img {
			display: block;
			max-width: 100%;
			margin: 0px auto;
		}
	}
	&__content {
		padding: 10px 15px 0px;
	}
	&__title {
		margin: 0px 0px 20px;
		color: #252525;
		font-family: 'Didact Gothic',sans-serif;
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	&__location {    
		color: #bdbdbd;
    font-size: 14px;
    margin-bottom: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__specialty {
  	margin-bottom: 10px;
  	color: #252525;
  	font-size: 13px;
  	line-height: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__text {
  	margin-bottom: 30px;
  	color: #252525;
  	font-size: 13px;
  	overflow: hidden;
  	line-height: 18px;
  	word-wrap: break-word;
  }
  &__caption {
    position: relative;
  	p {
  		margin-bottom: 3px;
  		font-size: 13px;
  	}
  }
  &__switcher {
  	margin-bottom: 30px;
  }
	&__btn {
		min-width: 105px;
		padding: 10px 20px 6px;
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
	}
  .two-btn {
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 10px;
  }
}