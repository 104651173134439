.prifile-wrapper {
    margin: 80px 0;

}
.profile-info {
    background-color: #fff;
    border: 1px solid #2c3e50;
    padding: 0 15px;
    margin-bottom: 25px;
    position: relative;

    .skills-btns {
        .tag-selected {
            margin: 10px 0;
            & + .tag-selected {
                margin-left: 10px;
            }
        }
    }

    .title {
        position: absolute;
        left: 0;
        bottom: 100%;
        width: 100%;

        .qualifications {
            display: inline-block;
            padding: 6px 20px;
            color: #fafafa;
            font-family: @Didact-Gothic;
            font-size: 14px;
            font-weight: 400;
            text-transform: uppercase;
            border-radius: 6px 6px 0 0;
            bottom: 0;
            left: -1px;
            position: absolute;
        }
        .setting-btn {
            border-radius: 8px 8px 0 0;
            background-color: #2c3e50;
            border-color: #2c3e50;
            margin-right: -1px;
            float: right;
            font-size: 12px;
            font-weight: 600;
            width: 100%;
            max-width: 200px;
            top: -2px;
            text-align: right;
            position: relative;
            padding: 10px 16px 5px;
            z-index: 1;
        }
        .switch-btn {
            float: right;
            margin-right: -18px;
            min-width: 210px;
            position: relative;
            top: -2px;
            .btn {
                border-bottom-color: transparent;
                border-radius: 8px 8px 0 0;
                padding: 10px 16px 5px;
                text-align: left;
                z-index: 0;
            }
        }
    }

    .user-name {
        color: #252525;
        font-weight: 600;
        letter-spacing: 2px;
        word-wrap: break-word;
        float: left;
        line-height: 1;
        margin: 0;
        width: 80%;
    }

    .btn-group {
        position: absolute;
        right: 20px;
        top: 0;
    }

    p {
        font-size: 16px;
        clear: both;
        width: 100%;
        word-wrap: break-word;
    }
    h3 {
        color: #9e9e9e;
        margin: 4px 0 2px;
        font-size: 13px;
        float: left;
        font-weight: 600;
        text-transform: uppercase;
    }
    a.edit, .btn {
        font-size: 12px;
        font-weight: 600;
        float: right;
        margin-top: 6px;
        display: inline-block;
        text-transform: uppercase;
        text-decoration: none;
    }
    .two-btn {
        margin-bottom: 20px;
        .btn, a {
            float: none;
            padding: 6px 8px;
            vertical-align: baseline;
            text-decoration: none;
        }
    }
    a.edit {
        display: none;
        text-decoration: none;
        &.collapsed {
            display: inline-block;
        }
    }
    .marg-row {
        margin: 15px 0;
        .clearfix;

        .item {
            .collapse.in, .collapsing {
                margin-top: 0;
            }
        }
        #edit-location, #edit-certificates, #edit-languages {
            .two-btn {
                margin-bottom: 4px;
            }
        }
    }

    &.green {
        border-top: 2px solid @color-green;
        .qualifications {
            background-color: @color-green;
        }
    }
    &.black {
        border-top: 2px solid @color-black;
        .qualifications {
            background-color: @color-black;
        }
    }
    &.red {
        border-top: 2px solid @color-red;
        .qualifications {
            background-color: @color-red;
        }
    }
    &.yellow {
        border-top: 2px solid @color-yellow;
        .qualifications {
            background-color: @color-yellow;
        }
    }
    &.purple {
        border-top: 2px solid @color-purple;
        .qualifications {
            background-color: @color-purple;
        }
    }
    .info-right {
        width: 360px;
        text-align: center;
        float: right;
        position: relative;
        margin: 0;
        .edit {
            display: block;
            position: absolute;
            right: 15px;
            top: 15px;
        }
        #edit-avatar {
            position: absolute;
            right: 15px;
            top: 65px;
            z-index: 2;
            width: 200px;
            border-radius: 4px;
            overflow: hidden;
            .btn {
                display: block;
                float: none;
                border-radius: 0;
                width: 100%;
                margin: 0;
            }
            .avatar-profile {
                display: none;
            }
        }
    }
    .avatar-profile {
        background: #ccc;
        margin: -36px auto 0;
        display: table;
        z-index: 2;
        position: relative;
        height: 170px;
        width: 170px;

        img {
            height: 170px;
            width: 170px;
        }
    }
    .followers, .following {
        margin: 30px 0 20px;
    }

    .vertical-line {
        background: #ededed;
        height: 100%;
        width: 1px;
        display: block;
        position: absolute;
        right: 390px;
        top: 0;
    }
    .list-certifications {
        border-top: 1px solid #ccc;
        clear: both;
        margin-top: 25px;
        font-family: @Didact-Gothic;
        height: auto !important;
        opacity: 1 !important;

        .item {
            border-bottom: 1px solid #ccc;
            overflow: hidden;
            padding: 0;
            width: 100%;

            > p {
                margin: 5px 0;
            }

            .name {
                margin-top: 3px;
                display: inline-block;
            }

            .defete-item, .edit-item {
                font-size: 12px;
                font-weight: 600;
                margin-top: 6px;
                margin-left: 10px;
                display: inline-block;
                text-transform: uppercase;
                text-decoration: none;
            }
        }
    }
    .list-languages {
        height: auto !important;
        opacity: 1 !important;
        .list-certifications;
        border: none;
        clear: both;
        .item {
            border: none;
        }
    }
    .collapse.in, .collapsing {
        display: inline-block;
        margin-top: -30px;
        clear: both;
        overflow: hidden;
        width: 100%;
        & + * {
            opacity: 0;
            height: 0;
        }
    }
    .active-tble {
        border: none;
        border-radius: 0;
        margin-bottom: 0;
    }
}
.followers, .following {
    display: inline-block;
    text-align: center;
    width: 49%;
    clear: both;

    p {
        font-size: 12px;
    }
    span {
        font-size: 36px;
    }
}
.profile-detail {
    .profile-info {
        h3 {
            float: none;
        }
    }
}
.relevant-documents {
    .file-group {
        .file-name {
            line-height: 24px;
        }
        .btn-file-name {
            line-height: 10px;
        }
    }
}
.edit-btn-cancel {
    color: @color-black;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}
.employer_profile {
    margin: 40px 0;

    .employer-name {
        font-weight: 700;
        word-wrap: break-word;
        width: 85%;
        &.pull-left {
            margin-top: 0;
        }
    }

    .profile-info {
        padding: 30px 30px 0;
        display: flex;

        > .row {
            width: 100%;
            flex: 1;
        }

        .two-btn {
            > * {
                display: inline-block;
                margin: 0 1%;
                width: 47%;
            }
        }

        .marg-row {
            margin-top: 0;
        }
        p {
            transition: all 0.4s ease 0s;
            width: 100%;
            word-wrap: break-word;
        }
        .collapse.in, .collapsing {
            display: block;
            margin-top: -30px;
            overflow: hidden;
            clear: both;
            width: 100%;
            & + * {
                opacity: 0;
                height: 0;
            }
        }
    }

    aside {
        .two-btn {
            max-width: 270px;
            margin: 0 auto;
            .btn {
                margin: 10px 0;
            }
        }
        .profile-settings {
            margin: 0 auto;
            text-align: right;
            .edit {
                font-size: 12px;
                font-weight: 600;
                margin-bottom: 20px;
                display: inline-block;
                text-transform: uppercase;
                text-decoration: none;
            }
            #edit-avatar {
                margin-bottom: 15px;
                .btn {
                    display: inline-block;
                    margin: 0 1%;
                    font-size: 12px;
                    padding: 6px 8px;
                    vertical-align: baseline;
                    text-decoration: none;
                    width: 47%;
                }
            }
        }
    }

    .company-logo {
        background: #fff;
        border: 1px solid @color-blue;
        max-width: 270px;
        text-align: center;
        margin: 0 auto 10px;

        img {
            height: auto;
            width: 100%;
        }

        .title {
            padding: 5px 0;
            margin: 0 auto -24px;
            font-size: 14px;
            font-family: @Didact-Gothic;
            text-transform: uppercase;
            border-radius: 0 0 6px 6px;
            position: relative;
            width: 180px;
        }

        &.red {
            border-top: 2px solid @color-red;
            .title {
                color: #fafafa;
                background-color: @color-red;
            }
        }
    }
    .switch-btn {
        .btn {
            margin: 10px 0 20px;
        }
    }
    .followers, .following {
        margin-top: 10px;
    }
}

.unregistered-block {
    position: relative;
    padding: 25px 0;

    .info {
        font-size: 36px;
        font-weight: 700;
        margin: 30px auto;
        text-transform: uppercase;
    }

    &.yellow {
        background-color: #ffd400;
    }

    .bigHalfCircle {
        fill: #fafafa;
    }

    .btn {
        min-width: 175px;
    }
}

.profile-settings-wrapper {
    background-color: #fff;
    border: 1px solid #2c3e50;
    border-radius: 4px;
    padding: 0 30px 30px;
    margin: 80px auto;
    max-width: 368px;

    .avatar-profile {
        border-radius: 50%;
        overflow: hidden;
        margin: -40px auto 40px;
        height: 170px;
        width: 170px;

        img {
            height: 170px;
            width: 170px;
        }
    }

    .setting-btn-group {
        .btn {
            margin-top: 30px;
            padding-top: 8px;
            padding-bottom: 8px;
            width: 100%;
        }
    }
    .back-btn {
        background: none;
        border: none;
        color: #eabc33;
        font-size: 16px;
        font-weight: 700;
        display: table;
        margin: 20px auto 0;
    }

    .upload-logo-wrapper {

        span {
            font-size: 16px;
        }

        .btn {
            margin-top: -10px;
        }
    }
}

.profile-settings, .active-jobs {
    margin: 40px auto;

    .menu-settings, .menu-aside {
        background-color: #fff;
        border: 1px solid @color-blue;
        border-radius: 4px;
        overflow: hidden;
        margin-bottom: 20px;
        position: fixed;

        .tittle {
            background: @color-blue;
            color: #FFFFFF;
            padding: 15px 23px;
            font-size: 16px;
            font-weight: bold;
            line-height: 19px;
        }

        a {
            border-left: 4px solid transparent;
            color: #757575;
            display: block;
            font-size: 14px;
            font-weight: 700;
            padding: 20px;
            text-decoration: none;

            & + a {
                border-top: 1px solid #ededed;
            }
            &.active, &:hover {
                color: @color-green;
                border-left-color: @color-green;
            }
        }
    }

    .settings-block {
        background-color: #fff;
        border: 1px solid @color-blue;
        border-radius: 4px;
        overflow: hidden;
        margin-bottom: 40px;

        .panel {
            margin: 0;
            border: none;
            border-radius: 0;
            box-shadow: none;
        }

        .tittle {
            background-color: @color-blue;
            color: #fff;
            font-size: 18px;
            font-weight: 700;
            padding: 30px;
            text-transform: uppercase;
        }

        .item {

            & + .item {
                border-top: 1px solid #ededed;
            }

            h4 {
                font-size: 14px;
                font-weight: 700;
                text-transform: uppercase;
                margin: 0 0 6px;
            }

            p {
                color: #757575;
                font-family: @Didact-Gothic;
                font-size: 12px;
                font-weight: 400;
                width: 100%;
                word-wrap: break-word;
            }

            a {
                > p {
                    margin: 0;
                }
            }

            .wrap-item {
                border-left: 4px solid transparent;
                padding: 20px;
                display: block;
                color: @color-black;
                text-decoration: none;

                &[aria-expanded="true"] {
                    border-left-color: @color-green;

                    h4 {
                        color: @color-green;
                    }

                    .edit-btn {
                        transform: rotate(45deg);
                    }
                }

                & > p {
                    line-height: 18px;
                    width: 90%;
                }
            }

            .short {
                max-width: 350px;
            }

            .collapse, .collapsing {
                padding: 0 20px 20px;
                border-left: 4px solid @color-green;
            }

            .edit-btn {
                color: @color-green;
                display: block;
                font-weight: 700;
                cursor: pointer;
                padding: 0 10px;
                font-size: 25px;
                line-height: 0.8;
                transition: all 0.3s ease 0s;
            }

            .two-btn {
                .btn {
                    width: 45%;
                }
            }
            .radio-right {
                padding-left: 20px;
                border: none;
                box-shadow: none;
            }

            .avatar-profile {
                margin: 0 10px 10px;
                height: 60px;
                width: 60px;

                img {
                    height: 60px;
                    width: 60px;
                }
            }
            .upload-logo-wrapper {
                display: inline-block;
                margin-left: 10px;
            }
            .check {
                margin-bottom: 15px;
                label {
                    line-height: 18px;
                    width: 80%;
                }
            }
        }
    }
}
.active-jobs {
    margin: 40px 0;
    .menu-aside {
        position: static;
    }
}
.language-formset {

    .form-group {
        display: inline-block;
        vertical-align: top;
        margin: 0 1%;
        width: 47%;
    }
}

.fieldset {
    .field-errors, .errors {
        position: absolute;
        bottom: -15px ;
        left: 19px;
        @media (max-width:1200px){
           bottom: -20px;
        }
    }
}
#edit-certificates{
    .form-group {
        .col-sm-12.field-wrapper{
        margin-top: 20px;
        }
    }
    .line{
        top:43%;
    }
}
.modal-footer {
    .pull-left {
        position: relative;
    }
}
.full-avatar-url {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    .cropit-image-input, .cropit-image-input-ad {
        position: absolute;
        z-index: 1;
        width: 100%;
        top: 0;
        height: 100%;
    }
}
