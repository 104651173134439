.left-sidebar {
    background: #fff;
    border: 1px solid #ebebeb;
    float: left;
    margin-top: 40px;
    width: 270px;
}

.right-content {
    margin: 40px -12px 40px 287px;

    h2 {
        margin: 0;
        padding-left: 10px;
    }
}
.middle-content {
    .right-content();
    margin: 0;
    flex: 1;
    padding: 40px 30px;
    min-width: 270px;
    & + .right-sidebar {
        // position: absolute;
        // right: 15px;
        // top: 0;
        flex: 1;
        max-width: 270px;
        min-width: 270px;
        .slider-one-item {
            .lightSlider  {
                margin-top: 0;
            }
        }
        .block-gray {
            margin: 15px 0 35px 0;
            footer {
                border-top: 1px solid #2c3e50;
                padding-top: 15px;
            }
        }
    }
}
.forms-filter {
    padding: 0 13px;

    .item {
        .title {
            color: @color-black;
            padding: 12px 0;
            border-bottom: 1px solid #2c3e50;
            margin-bottom: 15px;

            small {
                display: block;
                color: #959595;
                font-size: 12px;
                margin-top: 5px;
            }
        }
        .list-find {
            font-family: @Didact-Gothic;
            font-size: 14px;
            line-height: 18px;
            padding-top: 5px;
            padding-bottom: 10px;

            > div {
                margin: 8px 0;
            }
            .col-sm-4 {
                color: #959595;
            }
            a {
                color: @color-black;

                &.active {
                    color: @color-green;
                }
            }
        }
    }

    .btn-reset {
        display: table;
        margin: 0 auto 25px;
        width: 100%;
        max-width: 164px;
        padding: 9px 0;
        border-radius: 8px;

        .glyphicon {
            margin-right: 10px;
            font-size: 15px;
            top: 4px;
        }
    }
    a {
        text-decoration: none;
    }
}

.result-btns, .keywords {
    padding: 7px 0 6px;

    .btn {
        margin: 6px 8px 0;
        border-radius: 5px;
        padding: 5px 18px 5px;
        border: none;
        letter-spacing: 0.8px;
        font-family: 'Didact Gothic';
        &.black {
            cursor: default;
        }
        .remove-filter {
            padding: 0 0 0 6px;
            position: relative;
            font-family: "Myriad Pro";
            top: -1px;
        }
    }
}

.result-num {
    border-top: 1px solid #2c3e50;
    padding-top: 20px;
    margin: 0 10px;
    display: inline-block;
    width: 97%;

    .dropdown-toggle, .pull-left {
        color: @color-black;
        font-size: 14px;
    }
}

.list-find-checkbox {
    .check {
        margin: 15px 0;
    }
}

.show-more-group {
    margin-bottom: 20px;

    .check {
        margin: 0 0 15px;
    }

    a {
        text-decoration: none;
    }

    .glyphicon {
        color: #2c3e50;
        font-size: 11px;
        margin-right: 6px;
    }

    .collaps-link {
        color: @color-red;
        font-family: @Didact-Gothic;
        font-size: 14px;
        font-weight: 400;
    }

    .collapse.in {

        & + .accordion-toggle {
            .glyphicon {
                transform: rotate(-180deg);
            }
        }
    }
}

.chosen-list {
    padding: 0;
    margin: 10px 0;

    li {
        list-style: none;
        color: @color-black;
        font-family: @Didact-Gothic;
        padding: 15px 0;
        font-size: 14px;

        a {
            color: @color-black;
            text-decoration: none;
            line-height: 7px;
            font-size: 23px;
        }
    }
}

.btn-filter-mobile {
    border-radius: 0 5px 5px 0;
    position: fixed;
    left: -2px;
    top: 62px;
    display: none;
    z-index: 1;
}

.pagination-wrapper {
    .pagination {
        margin-top: 30px;
        width: 100%;

        li {
            margin: 3px 4px;
            display: inline-block;

            a {
                border-radius: 2px;
                color: #959595;
                font-size: 16px;
                font-weight: 400;
                height: 32px;

                .glyphicon {
                    vertical-align: middle;
                    margin-top: -3px;
                }

                &.active {
                    background-color: #2c3e50;
                    border-color: #2c3e50;
                    color: #fff;
                    &:hover {
                        background-color: #2c3e50;
                        color: #fff;
                    }
                }

                &:hover {
                    background: #fff;
                    border-color: #2c3e50;
                    color: #2c3e50;
                }
                &.green {
                    color: @color-green;
                    &:hover {
                        background: #fff;
                        border-color: @color-green;
                        color: @color-green;
                    }
                }
            }
        }
    }
}

.larg-text {
    font-family: @Didact-Gothic;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
}

.advertising-partners {
    &.advertising-list-empty {
        padding: 80px 0px 100px;
    }
    .block-brofile {
        padding: 0 0 128px;
        overflow: hidden;
        height: 500px;

        img {
            display: block;
            height: auto;
            max-height: 160px;
            margin-bottom: 30px;
            width: 100%;
        }
        p {
            padding: 0;
        }
        .wrap {
            padding: 0 28px;
        }
        footer {
            left: 50%;
            height: 72px;
            transform: translateX(-50%);
        }
    }
}
.select2-selection__choice {
    display: none;
}

.filter-btns, .skills-btns, .keywords-btns {
    padding: 0;
    margin: 0;
    display: inline-block;
    vertical-align: middle;

    li {
        float: left;
        display: inline-block;
        list-style: none;
        .btn {
            border-radius: 5px;
        }
    }
    #id_all_clear {
        margin-bottom: 6px;
    }

    .tag-selected, .tag-checked {
        background: @color-black;
        font-weight: 600;
        color: #fff;
        padding: 6px 20px 6px 12px;
        line-height: 1.42857143;
        vertical-align: middle;
        border-radius: 4px;
        margin: 5px 10px;
        word-break: break-word;
        word-wrap: break-word;
        position: relative;

        a {
            float: right;
            margin-left: 10px;
            color: #fff;
            text-decoration: none;
            cursor: pointer;
            position: absolute;
            right: 6px;
            top: 6px;
        }
        &.green {
            background: @color-green;
        }
    }
}

.selected-btns {
    padding: 0;
    margin: 0;

    li {
        list-style: none;
        margin: 20px 0;
        position: relative;

        a {
            color: @color-black;
            position: absolute;
            top: 0;
            right: 0;
            text-decoration: none;
            cursor: pointer;
        }
    }
}

.advertising-partners {
    .left-sidebar {
        margin-top: 10px;
    }
    .right-content {
        margin-top: 0;
    }
}
