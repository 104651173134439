.search-block-wrapper {
    padding: 40px 0 80px;

    .content-right {
        padding-left: 15px;
    }

    h1 {
        margin: 0 0 20px;
    }

    ul.list-items {
        padding: 0;
        margin: 0;

        li {
            list-style: none;
            padding-bottom: 10px;

            & + li {
                border-top: 1px solid @color-gray;
                padding-top: 25px;
            }
        }
    }

    .list-group {
        a {
            padding: 15px 5px 15px 30px;
        }
        .badge {
            background: none;
            color: @color-gray;
        }
        .active {
            background: @color-blue;
            border-color: @color-blue;
            color: #fff;
            .badge {
                color: #fff;
            }
        }
    }

    .media-user {
        .media-body {
            padding-top: 0;

            .name {
                position: static;
                white-space: normal;
            }
        }
        .media-right {
            white-space: nowrap;

             > * {
                display: inline-block;
                vertical-align: top;
             }

            .btn {
                width: auto;
                margin-left: 15px;
            }
            .follow-form .btn {
                width: 100px;
            }
        }

        .small-img {
            margin-right: 12px;
            height: 70px;
            width: 70px;
        }

        .description {
            color: #7b7b7b;
            font-size: 16px;
            line-height: 24px;
        }
    }
}
