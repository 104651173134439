.post-wrapper {
    h2 {
        margin: 10px 0;
    }

    .post-item-wrapper {
        &:nth-child(3n-2){
            .btn {
                background-color: #1a1a1a;
                border-color: #1a1a1a;
                color: #fff;
            }
        }
        &:nth-child(3n-1){
            .btn {
                background-color: #00a859;
                border-color: #00a859;
                color: #fff;
            }
        }
        &:nth-child(3n){
            .btn {
                background-color: red;
                border-color: red;
                color: #fff;
            }
        }
    }

    .post-item {
        padding-bottom: 30px;
        margin-right: 15px;

        & + .post-item {
            border-top: 1px solid @color-blue;
            padding-top: 30px;
        }

        .post-image {
            img {
                display: block;
                width: 100%;
            }
        }

        .title {
            display: block;
            color: #252525;
            margin: 30px 0 15px;
            font-size: 27px;
            font-weight: 700;
        }

        .post-short-entry {
            margin: 20px 0;
            color: #7b7b7b;
            font-family: @Didact-Gothic;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }

        .post-entry {
            .post-short-entry;
        }

        .btn {
            padding: 8px 30px 5px;
            border-radius: 8px;
            margin-top: 16px;
        }
    }
}

.timestamp {
    font-size: 12px;
}
.post-info {
    font-size: 13px;

    .icon {
        display: inline-block;
        vertical-align: middle;
        margin-right: 14px;
    }

    > span {
        margin-right: 25px;
    }
}

.post-sidebar-wrapper {
    margin-bottom: 15px;
    .item {
        > .title {
            border-bottom: 1px solid @color-blue;
            font-size: 15px;
            font-weight: 700;
            padding: 10px 0;
            margin-bottom: 15px;
            text-transform: uppercase;
            text-align: center;
        }
        .recent-comments {
            p {
                font-family: @Didact-Gothic;
                line-height: 18px;
                margin-bottom: 15px;
            }
        }
    }
}

.list-popular-posts {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
        padding-bottom: 15px;

        & + li {
            border-top: 1px solid #ebebeb;
            padding-top: 15px;
        }

        .media-object {
            width: 100px;
            height: 70px;
            img {
                height: auto;
                width: 100%;
            }
        }

        .title {
            color: #252525;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
        }
    }
}

.add-comment-wrapper {

    .title {
        margin: 0 0 30px;
    }

    .add-comment-form {

        textarea {
            padding: 20px;
            min-height: 130px;
            resize: none;
        }

        .btn {
            margin: 20px 0 40px;
            height: 44px;
            width: 170px;
        }
    }

    .comment-list {
        .comment-item {
            margin-top: 25px;

            .btn-default {
                padding: 6px 12px 5px;
            }

            .comment-text {
                color: #757575;
                font-family: @Didact-Gothic;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                padding-top: 10px;
                & + div {
                    a {
                        text-decoration: none;
                    }
                }
            }

            .icon {
                .post-info .icon;
            }

            .avatar {
                height: 70px;
                width: 70px;
            }

            .media-body {
                margin-top: 0;
                padding-top: 0;
                padding-left: 20px;

                .name {
                    white-space: normal;
                    position: static;
                }
            }

            & > .comment-item {
                padding-left: 5%;
            }
        }
        .follow-form {
            .btn {
                width: 100px;
            }
        }
    }
}

.share-wrapper {
    font-family: @Didact-Gothic;
    margin: -25px 0 30px;
    font-size: 16px;

    strong {
        display: inline-block;
        vertical-align: middle;
        font-family: "Myriad Pro",sans-serif;
    }

    .addthis_sharing_toolbox{
        list-style: none;
        display: inline-block;
        vertical-align: middle;
        margin-left: 15px;
        padding: 0;

        .at-share-btn {
            text-align: center;
            border-radius: 50%;
            margin: 0 10px;

            .at-icon {
                width: 24px!important;
                height: 24px!important;
                margin-top: 4px;
            }
        }
    }

    a {
        color: #959595;
        font-size: 14px;
        margin-left: 18px;
        text-decoration: none;
    }

    .icon {
        .post-info .icon;
    }
}

.followers-list-wrapper {
    margin: 40px 0 80px;

    h1 {
        text-transform: uppercase;
    }

    ul.list-items {
        padding: 0;
        margin: 0;

        li {
            list-style: none;
            padding: 20px 0;

            & + li {
                border-top: 1px solid @color-gray;
            }

            .small-img {
                margin-right: 20px;
                height: 70px;
                width: 70px;
            }

            .btn {
                font-size: 12px;
                padding-top: 11px;
                padding-bottom: 10px;
                margin: 0 auto;
                height: 40px;
                width: 96px;
            }
            .media-body  {
                .name {
                    position: static;
                }
            }
        }
    }
}

.post-sidebar-wrapper {
    a {
        color: #333;
    }
}

#show_more_comments {
    margin-top: 15px;
}


.post-items {
    .post-item {
        padding-bottom: 15px;
        margin-right: 0px;
        margin-bottom: 60px;
        background: white;
        border: 1px solid #bdbdbd;
        .btn {
            margin-left: 15px;
        }
    }
    .description {
        padding: 0px 15px;
        .title {
            margin-top: 15px;
            font-size: 16px;
            overflow: hidden;
            text-transform: none;
        }
        .post-info {
            span {
                font-size: 12px;
                line-height: 12px;
            }
            .comment-amt {
                margin-right: 0px;
            }
            .views {
                display: inline-block;
                margin-right: 0px;
            }
        }
        p {
            font-size: 16px;
            line-height: 24px;
        }
    }
}

@media (min-width: 992px ) {
    .post-items {
        .post-item {
            position: relative;
            height: 460px;
            padding-bottom: 65px;
            .btn {
                position: absolute;
                left: 15px;
                bottom: 15px;
                margin-left: 0px;
            }
        }
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .post-items {
        .post-item-wrapper {
            &:nth-child(2n-1) {
                clear: left;
                &:after {
                    content: '';
                    display: block;
                    clear: both;
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    .post-items {
        .post-item {
            height: 480px;
            .post-info {
                .date {
                    margin-right: 15px;
                }
                .icon-calendar,
                .icon-comments {
                    margin-right: 5px;
                }
            }
        }
        .post-item-wrapper {
            &:nth-child(4n) {
                clear: left;
                &:after {
                    content: '';
                    display: block;
                    clear: both;
                }
            }
        }
    }
}


