@import "sprites.css";
@font-face {
  font-family: "Myriad Pro";
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/MyriadPro-Regular.eot');
  src: url('../fonts/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/MyriadPro-Regular.woff2') format('woff2'), url('../fonts/MyriadPro-Regular.woff') format('woff'), url('../fonts/MyriadPro-Regular.ttf') format('truetype'), url('../fonts/MyriadPro-Regular.svg') format('svg');
}
@font-face {
  font-family: "Myriad Pro";
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/MyriadPro-Semibold.eot');
  src: url('../fonts/MyriadPro-Semibold.eot?#iefix') format('embedded-opentype'), url('../fonts/MyriadPro-Semibold.woff2') format('woff2'), url('../fonts/MyriadPro-Semibold.woff') format('woff'), url('../fonts/MyriadPro-Semibold.ttf') format('truetype'), url('../fonts/MyriadPro-Semibold.svg') format('svg');
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  background: #fafafa;
  font-family: "Myriad Pro", sans-serif;
  font-size: 14px;
  line-height: 1;
  display: table!important;
  height: auto;
  min-height: 100vh;
  width: 100%;
}
.body-white {
  background: #fff;
}
h1,
h2,
h3,
p,
a {
  word-break: break-word;
}
p,
.font-gothic {
  font-family: 'Didact Gothic', sans-serif;
}
a:active,
a:hover,
a:focus,
button:active,
button:focus,
th:active,
th:focus {
  outline: none !important;
  box-shadow: none !important;
  color: inherit;
  text-decoration: none;
}
.btn:active,
.btn:focus {
  outline: none !important;
}
a {
  text-decoration: none;
}
main {
  height: 100%;
  display: table-row;
}
.main-wrapper {
  padding: 40px 0 80px;
}
.main-height {
  flex: 1;
}
.owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}
svg.bigHalfCircle {
  position: absolute;
  left: 0;
  bottom: -1px;
  max-height: 7%;
  height: 100%;
  width: 100%;
}
svg.bigHalfCircle.bottom {
  top: -1px;
  bottom: auto;
  transform: rotate(180deg);
}
.icon {
  display: inline-block;
}
.remove-filter {
  color: #fff;
  padding: 0 0 0 10px;
}
.remove-filter:hover,
.remove-filter:focus {
  color: #fff;
  text-decoration: none;
}
.messages {
  display: none;
  font-size: 18px;
  text-align: center;
  position: absolute;
  right: 50px;
}
aside {
  float: left;
  width: 270px;
}
.content-right {
  margin-left: 287px;
}
select option {
  color: black;
}
select option:checked {
  color: grey;
}
select.empty {
  color: grey;
}
select option[disabled]:first-child {
  display: none;
}
.btn-default {
  padding: 8px 12px 5px;
  border-radius: 10px;
}
.input-group .btn {
  border-radius: 4px;
}
.modal {
  z-index: 2050;
}
.btn-default,
.dropdown-content .type {
  color: #fff;
  font-weight: 600;
}
.btn-default.red,
.dropdown-content .type.red,
.btn-default.red:active:focus,
.dropdown-content .type.red:active:focus {
  background-color: #FF0000;
  border-color: #FF0000;
  color: #fff;
}
.btn-default.red:hover,
.dropdown-content .type.red:hover,
.btn-default.red:active:focus:hover,
.dropdown-content .type.red:active:focus:hover {
  background-color: #C41A1C;
  border-color: #C41A1C;
}
.btn-default.black,
.dropdown-content .type.black,
.btn-default.black:active:focus,
.dropdown-content .type.black:active:focus {
  background-color: #1a1a1a;
  border-color: #1a1a1a;
  color: #fff;
}
.btn-default.black:hover,
.dropdown-content .type.black:hover,
.btn-default.black:active:focus:hover,
.dropdown-content .type.black:active:focus:hover {
  background-color: #000;
  border-color: #000;
}
.btn-default.green,
.dropdown-content .type.green,
.btn-default.green:active:focus,
.dropdown-content .type.green:active:focus {
  background-color: #00a859;
  border-color: #00a859;
  color: #fff;
}
.btn-default.green:hover,
.dropdown-content .type.green:hover,
.btn-default.green:active:focus:hover,
.dropdown-content .type.green:active:focus:hover {
  background-color: #008E4A;
  border-color: #008E4A;
}
.btn-default.purple,
.dropdown-content .type.purple,
.btn-default.purple:active:focus,
.dropdown-content .type.purple:active:focus {
  background-color: #800080;
  border-color: #800080;
  color: #fff;
}
.btn-default.purple:hover,
.dropdown-content .type.purple:hover,
.btn-default.purple:active:focus:hover,
.dropdown-content .type.purple:active:focus:hover {
  background-color: #800080;
  border-color: #800080;
}
.btn-default.yellow,
.dropdown-content .type.yellow,
.btn-default.yellow:active:focus,
.dropdown-content .type.yellow:active:focus {
  background-color: #eabc33;
  border-color: #eabc33;
  color: #1a1a1a;
}
.btn-default.yellow:hover,
.dropdown-content .type.yellow:hover,
.btn-default.yellow:active:focus:hover,
.dropdown-content .type.yellow:active:focus:hover {
  background-color: #C09C26;
  border-color: #C09C26;
}
.btn-default.blue,
.dropdown-content .type.blue,
.btn-default.blue:active:focus,
.dropdown-content .type.blue:active:focus {
  background-color: #2c3e50;
  border-color: #2c3e50;
  color: #fff;
}
.btn-default.blue:hover,
.dropdown-content .type.blue:hover,
.btn-default.blue:active:focus:hover,
.dropdown-content .type.blue:active:focus:hover {
  background-color: #1A2B3A;
  border-color: #1A2B3A;
}
.btn-default.yellow-lighter,
.dropdown-content .type.yellow-lighter,
.btn-default.yellow-lighter:active:focus,
.dropdown-content .type.yellow-lighter:active:focus {
  background-color: #ffd400;
  border-color: #ffd400;
  color: #1a1a1a;
}
.btn-default.yellow-lighter:hover,
.dropdown-content .type.yellow-lighter:hover,
.btn-default.yellow-lighter:active:focus:hover,
.dropdown-content .type.yellow-lighter:active:focus:hover {
  background-color: #E6C106;
  border-color: #E6C106;
}
.btn-default.pink,
.dropdown-content .type.pink,
.btn-default.pink:active:focus,
.dropdown-content .type.pink:active:focus {
  background-color: #FFB2B2;
  border-color: #FFB2B2;
}
.btn-default.pink:hover,
.dropdown-content .type.pink:hover,
.btn-default.pink:active:focus:hover,
.dropdown-content .type.pink:active:focus:hover {
  background-color: #f17070;
  border-color: #f17070;
  color: #fff;
}
.btn-default.tranp,
.dropdown-content .type.tranp {
  background: transparent;
  border-color: #1a1a1a;
  color: #1a1a1a;
}
.btn-default.tranp:hover,
.dropdown-content .type.tranp:hover {
  background-color: #2c3e50;
  color: #fff;
}
.btn-default.tranp:active:focus,
.dropdown-content .type.tranp:active:focus {
  background-color: #1A2B3A;
  color: #fff;
}
.btn-default.btn-circle,
.dropdown-content .type.btn-circle {
  background: transparent;
  border-color: transparent;
  border-radius: 50%;
  position: relative;
  width: 40px;
  height: 40px;
  text-decoration: none;
  color: #959595;
}
.btn-default.btn-circle span,
.dropdown-content .type.btn-circle span {
  font-size: 30px;
  line-height: 0.2;
  margin: 0 0 0 -7px;
  letter-spacing: 3px;
}
.btn-default.btn-circle:hover,
.dropdown-content .type.btn-circle:hover {
  background-color: #e6e6e6;
  border-color: #adadad;
  text-decoration: none!important;
}
.btn.btn-larg {
  border-radius: 8px;
  padding: 14px 12px 9px;
  min-width: 170px;
}
.btn-lg {
  height: 46px;
  padding: 12px 17px;
}
.switch-btn .on {
  background: #fafafa;
  color: #1a1a1a;
}
.switch-btn .off {
  background: #eabc33;
  border-color: #eabc33;
  color: #1a1a1a;
  display: none;
}
.switch-btn.On .on {
  display: none;
}
.switch-btn.On .off {
  display: inline-block;
}
.text-black {
  color: #1a1a1a;
}
.switch-checkbox label {
  position: relative;
  display: inline-block;
  color: #ccc;
  height: 30px;
  border-radius: 40px;
  background-color: #ececed;
  width: 50px;
  cursor: pointer;
  vertical-align: middle;
  margin: 0 5px;
}
.switch-checkbox input {
  visibility: hidden;
  position: absolute;
}
.switch-checkbox span {
  font-weight: 600;
}
.switch-checkbox .toggle {
  background-color: #FF0000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04), 0 0 3px rgba(0, 0, 0, 0.07), 0 8px 0 rgba(0, 0, 0, 0.03);
  position: absolute;
  top: 1px;
  left: 0;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  z-index: 999;
  transition: all 0.30s linear;
}
.switch-checkbox.On .toggle {
  right: 0;
  left: auto;
  background-color: #00a859;
}
.switch-checkbox.On .on {
  color: #00a859;
}
.switch-checkbox.Off .off {
  color: #FF0000;
}
.form-group label {
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 10px;
  margin-top: 5px;
}
.form-group .form-control {
  padding: 10px 12px;
  height: auto;
}
.select2-container {
  display: block;
}
.select2-container.select2 {
  width: 100%!important;
}
.select2-container .select2-selection__rendered {
  padding-top: 5px!important;
  position: relative;
  z-index: 1;
}
.select2-container span.placeholder {
  position: absolute;
  top: 12px;
  left: 6px;
  opacity: 0.5;
}
.select2-container.select2-container--open span.placeholder {
  display: none;
}
.select2-container .select2-selection__clear {
  display: none;
}
.text-green,
a.text-green {
  color: #00a859 !important;
}
.text-yellow,
a.text-yellow {
  color: #eabc33 !important;
}
.text-gray,
a.text-gray {
  color: #959595 !important;
}
.text-red,
a.text-red {
  color: #FF0000 !important;
}
.text-blue,
a.text-blue {
  color: #2c3e50 !important;
}
.small-img {
  overflow: hidden;
  height: 60px;
  width: 60px;
}
.small-img .avatar {
  border-right: 50%;
}
.disabled {
  pointer-events: none;
  opacity: 0.6;
}
img[src=""] {
  display: none;
}
.noresize {
  resize: none;
}
.btn-block + .btn-block {
  margin-top: 0;
}
.dropdown-menu label {
  cursor: pointer;
  padding: 3px 20px;
  margin: 0;
  display: block;
  font-weight: 100;
  width: 100%;
}
.dropdown-menu label:hover {
  background: #f5f5f5;
}
.confirm-modal {
  text-align: center;
}
.confirm-modal:before {
  display: inline-block;
  vertical-align: middle;
  content: " ";
  height: 100%;
}
.confirm-modal .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
.confirm-modal.fade .modal-dialog {
  transform: scale(0.9);
  opacity: 0;
  transition: all 0.3s ease;
}
.confirm-modal.fade.in .modal-dialog {
  transform: scale(1);
  opacity: 1;
}
.confirm-modal .modal-footer {
  text-align: center;
}
.confirm-modal .btn {
  border-radius: 6px;
  font-size: 15px;
  margin: 0 14px;
  width: 100px;
}
.confirm-modal .modal-dialog {
  max-width: 400px;
}
.confirm-modal .modal-header {
  border: none;
}
.confirm-modal .modal-header .modal-title {
  max-width: 270px;
  margin: 0 auto;
}
.confirm-modal .modal-body {
  max-width: 340px;
  margin: 0 auto;
}
.confirm-modal .modal-footer .btn {
  margin: 0;
}
.confirm-modal .modal-footer .btn + .btn {
  margin-left: 10px;
}
@media (max-width: 420px) {
  .confirm-modal .modal-footer .btn + .btn {
    margin-left: 0;
  }
}
.confirm-modal .modal-footer {
  border: none;
}
.adv-green {
  width: 100%;
  max-width: 262px;
  padding: 11px 0 8px;
  border: none;
  font-size: 13px;
  border-radius: 7px;
}
.adv-blue {
  width: 100%;
  max-width: 262px;
  padding: 11px 0 8px;
  border: none;
  font-size: 13px;
  border-radius: 7px;
}
textarea {
  resize: none;
}
.table-responsive .table {
  width: 99.9%;
}
form .btn-file {
  font-size: 0!important;
  position: relative;
  color: #FFFFFF;
}
form .btn-file input[type="file"] {
  display: block;
  position: absolute;
  opacity: 0;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
}
form .btn-file *:not(span) {
  display: none;
}
form .btn-file span {
  font-size: 12px!important;
}
.ie-upload-img .advertise-upload-logo img {
  height: auto;
  width: 270px;
}
.small-avatar {
  height: 32px;
  width: 32px;
}
.middle-text {
  font-family: 'Didact Gothic', sans-serif;
  font-size: 16px;
  line-height: 24px;
}
.block-gray {
  background-color: #FFFFFF;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  padding: 12px;
}
.block-gray.job-plan {
  box-shadow: none;
  padding: 0;
}
.block-gray.job-plan .btn-bottom {
  padding-right: 15px;
  padding-left: 15px;
}
.block-gray .job-search-item {
  word-break: break-word;
  word-wrap: break-word;
}
.block-gray .btn {
  text-transform: uppercase;
}
.title-h1 {
  margin-top: 40px;
  margin-bottom: 40px;
}
.mt0 {
  margin-top: 0 !important;
}
.mt30 {
  margin-top: 30px;
}
strong small {
  font-size: 12px;
}
.error-list {
  margin: 0;
  padding: 0;
}
.error-list li {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  line-height: 26px;
}
.header-main {
  background-color: #2c3e50;
  color: #fff;
  position: relative;
  z-index: 2000;
}
.header-main .header-top {
  padding: 20px 0 0;
}
.header-main .header-top.header-top-logout {
  padding-bottom: 20px;
}
.header-main .header-top.no-padding-bottom {
  padding-bottom: 0px;
}
.header-main .logo {
  margin-top: 5px;
  display: block;
  position: relative;
}
.header-main .logo img {
  max-width: 228px;
  height: auto;
  position: absolute;
  top: -12px;
  width: 100%;
}
.header-main .search-navbar-form {
  margin: 7px 0;
}
.header-main .search-navbar-form .input-group input {
  border-right: none;
  height: 46px;
  width: 70%;
}
.header-main .search-navbar-form .input-group select,
.header-main .search-navbar-form .input-group .styler {
  height: 46px;
  line-height: 34px;
  width: 30%;
}
.header-main .search-navbar-form .input-group button {
  color: #fff;
  padding: 12px 17px;
  line-height: 1;
  font-size: 17px;
  height: 46px;
}
.header-main .search-navbar-form .jq-selectbox__select-text {
  padding-right: 10px;
}
.header-main .menu-top {
  padding: 0;
  margin: 30px 0 0 0;
}
.header-main .menu-top li {
  list-style: none;
  float: right;
  position: relative;
  margin: 0 0 0 20px;
}
.header-main .menu-top li a {
  font-size: 14px;
  text-transform: uppercase;
}
.header-main .menu-top li .color-red {
  color: #FF0000;
}
.header-main .menu-top li .color-yellow {
  color: #eabc33;
}
.header-main .navbar-inverse {
  background-color: transparent;
  border: none;
  margin: 0;
  border-top: 0.5px solid #959595;
}
.header-main .navbar-inverse .navbar-nav {
  float: none;
  margin: 0 auto;
  display: table;
}
.header-main .navbar-inverse .navbar-nav li:hover:nth-child(3n+1) a,
.header-main .navbar-inverse .navbar-nav li.active:nth-child(3n+1) a {
  color: #00a859;
}
.header-main .navbar-inverse .navbar-nav li:hover:nth-child(3n+2) a,
.header-main .navbar-inverse .navbar-nav li.active:nth-child(3n+2) a {
  color: #eabc33;
}
.header-main .navbar-inverse .navbar-nav li:hover:nth-child(3n+3) a,
.header-main .navbar-inverse .navbar-nav li.active:nth-child(3n+3) a {
  color: #FF0000;
}
.header-main .navbar-inverse .navbar-nav li a {
  color: #ccc;
  font-size: 16px;
  text-transform: uppercase;
  padding: 20px 30px 18px;
}
.header-main .navbar-inverse .navbar-nav li a .icon {
  margin-right: 5px;
}
.header-main .navbar-inverse .navbar-nav li hr {
  margin: 2px 0 6px;
}
.header-main .navbar-inverse .navbar-nav .active a {
  background-color: transparent;
  color: #00a859;
}
.header-main .user-menu-top {
  margin-top: 12px;
}
.header-main .user-menu-top .notification-wrapper {
  margin: 12px 30px 0 0;
}
.header-main .user-menu-top .notification-wrapper .dropdown-content a + a {
  border-top: 1px solid #ebebeb;
}
.header-main .user-menu-top .menu-chat-wrapper {
  margin: 12px 30px 0 0;
}
.header-main .user-menu-top .menu-chat-wrapper .dropdown-content a + a {
  border-top: 1px solid #ebebeb;
}
.header-main .user-menu-top .user-avatar-wrapper {
  margin: 0;
  height: 44px;
  width: 44px;
}
.header-main .user-menu-top .user-avatar-wrapper img {
  height: 44px;
  width: 44px;
}
.header-main .user-menu-top .user-avatar-wrapper .dropdown-content a:hover,
.header-main .user-menu-top .user-avatar-wrapper .dropdown-content a .active {
  background: #2c3e50;
  color: #fff;
  text-decoration: none;
}
.header-main .user-menu-top .user-avatar-wrapper .dropdown-content .type {
  border-radius: 6px;
  font-family: 'Didact Gothic', sans-serif;
  font-size: 10px;
  padding: 7px 20px;
  text-transform: uppercase;
  text-decoration: none;
}
.header-main .user-menu-top .user-avatar-wrapper .dropdown-content .type.yellow {
  background-color: #ffd400;
  border-color: #ffd400;
}
.header-main .user-menu-top > li {
  position: relative;
}
.header-main .user-menu-top > li:hover .dropdown-content {
  display: block;
}
.header-main .user-menu-top .count {
  background: #FF0000;
  font-family: 'Didact Gothic', sans-serif;
  font-size: 11px;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  width: 19px;
  height: 19px;
  display: inline-block;
  position: absolute;
  line-height: 17px;
  top: -6px;
  right: -4px;
}
.header-main .user-menu-top .dropdown-content {
  position: absolute;
  right: 0;
  top: 100%;
  padding-top: 10px;
  display: none;
  width: 280px;
  z-index: 10000;
}
.header-main .user-menu-top .dropdown-content .wrapper {
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  position: relative;
}
.header-main .user-menu-top .dropdown-content .wrapper:before {
  content: '';
  position: absolute;
  top: -7px;
  right: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
}
.header-main .user-menu-top .dropdown-content .wrapper:after {
  content: '';
  position: absolute;
  top: -6px;
  right: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffd400;
  border-left: 6px solid transparent;
}
.header-main .user-menu-top .dropdown-content .wrapper > * {
  background-color: #fff;
  display: block;
  padding: 15px 12px;
  color: #959595;
  text-transform: none;
  text-decoration: none;
}
.header-main .user-menu-top .dropdown-content .wrapper .tittle {
  background-color: #ffd400;
  border-radius: 4px 4px 0 0;
  color: #2c3e50;
  font-weight: 700;
  padding: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.header-main .user-menu-top .dropdown-content .wrapper .icon {
  margin-right: 5px;
  vertical-align: text-top;
}
.header-main .user-menu-top .dropdown-content .wrapper .logout {
  border-top: 1px solid #ccc;
  border-radius: 0 0 4px 4px;
}
.header-main .user-menu-top .dropdown-content .wrapper .media-user .media-body {
  padding-top: 0;
}
.header-main .user-menu-top .dropdown-content .wrapper .media .small-img {
  width: 48px;
  height: 48px;
}
.header-main .user-menu-top .dropdown-content .wrapper .media .name {
  font-size: 12px;
  position: relative;
  white-space: normal;
}
.header-main .user-menu-top .dropdown-content .wrapper .see-all {
  background: #00a859;
  border-radius: 0 0 4px 4px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
}
.top-alert {
  background: #ffd400;
  padding: 10px 0;
}
.top-alert button {
  background: transparent;
  border: none;
  font-weight: 700;
  text-decoration: underline;
  padding: 0;
}
.top-alert button:hover {
  text-decoration: none;
}
#google_translate_element {
  height: 28px;
  width: 220px;
  margin-top: 20px;
  margin-bottom: 20px;
  float: right;
}
#google_translate_element .goog-te-gadget-simple {
  background: #eabc33;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  padding: 4px 10px;
  border: none;
  display: block;
  width: 220px;
}
#google_translate_element .goog-te-gadget-simple .goog-te-gadget-icon {
  display: none;
}
#google_translate_element .goog-te-gadget-simple .goog-te-menu-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#google_translate_element .goog-te-gadget-simple .goog-te-menu-value span {
  border: none!important;
}
#google_translate_element .goog-te-gadget-simple .goog-te-menu-value span[aria-hidden="true"] {
  color: #1a1a1a !important;
  float: right;
  display: inline-block;
  font-size: 0;
  width: 0;
  height: 0;
  border-style: solid!important;
  border-width: 5px 5px 0 5px!important;
  border-color: #1a1a1a transparent transparent transparent !important;
}
.goog-te-menu-frame {
  margin-top: 4px;
  width: 220px!important;
}
.footer-main {
  background-color: #2c3e50;
  color: #fff;
  padding-top: 60px;
}
.footer-main .title {
  margin: 0 0 15px 0;
  color: #fff;
  font-size: 19px;
  font-weight: 400;
  line-height: 26px;
  text-transform: uppercase;
}
.footer-main .logo {
  margin: 5px 0 25px 0;
  display: block;
}
.footer-main .logo img {
  left: 0;
  top: 0;
  max-height: 133px;
  max-width: 385px;
  height: auto;
  width: 100%;
}
.footer-main .logo .hover {
  position: absolute;
  visibility: hidden;
}
.footer-main .logo:hover .visible {
  visibility: hidden;
  position: absolute;
}
.footer-main .logo:hover .hover {
  visibility: visible;
  position: relative;
}
.footer-main .footer-nav li {
  list-style: none;
}
.footer-main ul {
  margin: 0;
  padding: 0;
}
.footer-main ul li {
  list-style-position: inside;
  margin-top: 14px;
}
.footer-main ul li a {
  margin: 0 0 15px 0;
  color: #fff;
  font-size: 19px;
  font-weight: 400;
  line-height: 26px;
  text-transform: uppercase;
  font-size: 15px;
  margin: 0;
  text-decoration: none;
}
.footer-main hr {
  border-top-color: #5D6165;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.05);
}
.footer-main .social-links a {
  display: inline-block;
  margin: 0 9px;
  width: 10%;
}
.footer-main .social-links a img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.footer-main .footer-nav-bottom {
  float: right;
}
.footer-main .footer-nav-bottom li {
  list-style: none;
  float: left;
  margin: -8px 0 0 40px;
}
.footer-main .footer-nav-bottom li a {
  font-size: 12px;
}
.footer-main .footer-nav-bottom li .color-green {
  color: #00a859;
}
.footer-main .footer-nav-bottom li .color-green:hover {
  color: #008E4A;
}
.footer-main .footer-nav-bottom li .color-yellow {
  color: #eabc33;
}
.footer-main .footer-nav-bottom li .color-yellow:hover {
  color: #C09C26;
}
.footer-main .copy {
  font-size: 12px;
}
.sign-in {
  padding: 40px 0px 35px;
  margin-top: 65px;
  background-color: rgba(44, 62, 80, 0.9);
}
.sign-in-form {
  max-width: 400px;
  width: 100%;
  margin: 0px auto;
}
.sign-in-form .form-group {
  margin-top: 40px;
  margin-bottom: 20px;
}
.sign-in-form .alert-error {
  margin-top: 20px;
}
.sign-in-form .form-control {
  border: 2px solid #ffd400;
}
.sign-in-form .form-control.error {
  border-color: red;
}
.sign-in-form button[type="submit"] {
  width: 100%;
  margin-top: 20px;
  border-radius: 4px;
  padding: 10px 12px 7px;
}
.sign-in-form .forgot-password {
  color: red;
  font-size: 16px;
  letter-spacing: 1px;
}
.sign-in-form .register-now {
  margin-top: 20px;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
}
.sign-in-form .register-now a {
  color: #eabc33;
}
.sign-in-form .register-now a:hover {
  text-decoration: underline;
}
.sign-in-form label.error {
  width: 100%;
  margin-bottom: 0px;
  color: red;
  font-size: 14px;
  text-align: left;
}
.signin-form-wrapper {
  background-color: #00a859;
  border: 1px solid #ebebeb;
  margin: 80px auto;
  max-width: 629px;
  width: 100%;
}
.signin-form-wrapper .form-wrapper {
  max-width: 370px;
  padding: 50px 15px 35px;
  margin: 0 auto;
}
.signin-form-wrapper .form-wrapper.done {
  max-width: 506px;
}
.signin-form-wrapper form .form-control {
  padding: 10px 12px;
  height: auto;
}
.signin-form-wrapper form .form-control.error {
  border-color: #FF0000;
}
.signin-form-wrapper form label.error {
  color: #FF0000;
  font-size: 12px;
  margin-top: 5px;
}
.signin-form-wrapper form .btn[type=submit] {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 12px 5px;
  margin: 25px 0;
  width: 100%;
  display: inline-block;
}
.signin-form-wrapper header .logo img {
  width: 251px;
  height: 86px;
}
.signin-form-wrapper header h3 {
  color: #fafafa;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  margin: 30px 0 20px;
  text-transform: uppercase;
}
.signin-form-wrapper header h4 {
  color: #fafafa;
}
.signin-form-wrapper .link-forgot-password {
  color: #FF0000;
  font-size: 16px;
}
.signin-form-wrapper footer {
  font-size: 16px;
  color: #fafafa;
}
.signin-form-wrapper footer a {
  color: #eabc33;
  font-weight: 700;
}
.check label {
  position: relative;
  cursor: pointer;
  color: #1a1a1a;
  font-family: 'Didact Gothic', sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding-left: 30px;
  line-height: 1;
}
.check .checed {
  border: 2px solid #ebebeb;
  border-radius: 4px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
}
.check .checed .icon {
  display: none;
  margin: 2px auto;
}
.check input {
  opacity: 0;
  position: absolute;
}
.check input:checked + .checed {
  background: #00a859;
  border-color: #00a859;
}
.check input:checked + .checed .icon {
  display: block;
}
.check input:checked.minus + .checed .icon {
  background: #fff;
  height: 2px;
  margin: 5px auto 0;
}
.radio label {
  position: relative;
  cursor: pointer;
  color: #1a1a1a;
  font-family: 'Didact Gothic', sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding-left: 30px;
  line-height: 1;
}
.radio .checed {
  border: 2px solid #ebebeb;
  border-radius: 4px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
}
.radio .checed .icon {
  display: none;
  margin: 2px auto;
}
.radio input {
  opacity: 0;
  position: absolute;
}
.radio input:checked + .checed {
  background: #00a859;
  border-color: #00a859;
}
.radio input:checked + .checed .icon {
  display: block;
}
.radio input:checked.minus + .checed .icon {
  background: #fff;
  height: 2px;
  margin: 5px auto 0;
}
.radio label {
  padding: 0;
}
.radio label:before {
  border: 2px solid #ebebeb;
  border-radius: 50%;
  display: inline-block;
  content: "";
  margin-right: 15px;
  margin-bottom: 4px;
  vertical-align: middle;
  width: 16px;
  height: 16px;
}
.radio input:checked + label:before {
  background-color: #00a859;
  border-color: #00a859;
}
.radio input:checked + label:after {
  content: "";
  width: 8px;
  height: 8px;
  left: 5px;
  position: absolute;
  top: 5px;
}
.radio.radio-right label {
  padding-left: 0;
  width: 100%;
}
.radio.radio-right label:before {
  float: right;
  margin-left: 15px;
  margin-right: 0;
}
.form-group-checkbox {
  margin-bottom: 15px;
}
.form-group-checkbox .check {
  margin-bottom: 15px;
}
.steps-wrapper {
  margin: 0 auto;
  text-align: center;
}
.steps-wrapper .step {
  border: 2px solid #ebebeb;
  border-radius: 50%;
  color: #1a1a1a;
  vertical-align: middle;
  padding-top: 17px;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  width: 80px;
  height: 80px;
}
.steps-wrapper .step.active {
  background-color: #1a1a1a;
  color: #fafafa;
}
.steps-wrapper .step.done {
  background: #00a859;
  font-size: 0;
}
.steps-wrapper .step.done:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  content: "";
  display: inline-block;
  height: 30px;
  width: 14px;
  transform: rotate(40deg);
}
.steps-wrapper .step.done span {
  display: none;
}
.steps-wrapper .step span {
  font-size: 14px;
  text-transform: none;
  display: block;
}
.steps-wrapper .line-step {
  background-color: #ebebeb;
  border-radius: 2px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 1%;
  height: 4px;
  width: 15%;
}
.job-steps.steps-wrapper {
  display: flex;
  max-width: 870px;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;
  margin-bottom: 20px;
}
.job-steps.steps-wrapper:after {
  content: "";
  background-color: #ebebeb;
  border-radius: 2px;
  vertical-align: middle;
  height: 4px;
  width: 98%;
  align-self: center;
  position: absolute;
  left: 1%;
  top: 50%;
  margin-top: -2px;
  z-index: -2;
}
.job-steps.steps-wrapper .step:not(.active) {
  background: #00a859;
}
.job-steps.steps-wrapper .step:not(.active) > * {
  display: none;
}
.job-steps.steps-wrapper .step {
  position: relative;
}
.job-steps.steps-wrapper .step:before {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  content: "";
  display: inline-block;
  height: 30px;
  width: 14px;
  transform: rotate(40deg);
}
.job-steps.steps-wrapper .step:after {
  content: "";
  background: #fafafa;
  position: absolute;
  border-radius: 50%;
  display: block;
  left: -20%;
  top: -20%;
  height: 140%;
  width: 140%;
  z-index: -1;
}
.job-steps.steps-wrapper .step > * {
  position: relative;
}
.job-steps.steps-wrapper .step.active:before {
  display: none;
}
.job-steps.steps-wrapper .step.active ~ .step {
  background: #fafafa;
}
.job-steps.steps-wrapper .step.active ~ .step:before {
  display: none;
}
.job-steps.steps-wrapper .step.active ~ .step > * {
  display: block;
}
.register-form-wrapper {
  background-color: #fff;
  border: 1px solid #2c3e50;
  margin: 40px auto 80px;
  max-width: 629px;
  width: 100%;
}
.register-form-wrapper .form-wrapper {
  max-width: 420px;
  padding: 0 15px 20px;
  margin: 0 auto;
  width: 100%;
}
.register-form-wrapper .form-wrapper > p {
  font-size: 16px;
  line-height: 26px;
}
.register-form-wrapper form .form-control {
  padding: 12px 12px 8px;
  height: auto;
  line-height: 1;
}
.register-form-wrapper form .form-control.error {
  border-color: #FF0000;
}
.register-form-wrapper form .form-group label {
  color: #1a1a1a;
  font-family: 'Didact Gothic', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 15px;
  margin-top: 10px;
  width: 80%;
}
.register-form-wrapper form .form-group label > div {
  line-height: 1;
}
.register-form-wrapper form label.error {
  color: #FF0000;
  font-size: 12px;
  line-height: 1.2;
  margin-top: 5px;
  margin-bottom: 0;
  width: 100%;
}
.register-form-wrapper form .btn {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 14px 12px 9px;
  width: 100%;
  max-width: 170px;
}
.register-form-wrapper form .clearfix > .btn {
  width: 49%;
}
.register-form-wrapper form .prev-step {
  color: #eabc33;
  font-size: 16px;
  font-weight: 700;
  margin-top: 12px;
  background: none;
  border: none;
}
.register-form-wrapper form .required {
  font-family: 'Didact Gothic', sans-serif;
  font-size: 12px;
  color: #FF0000;
  float: right;
  margin-top: 13px;
}
.register-form-wrapper form .upload-logo-wrapper label {
  word-wrap: break-word;
}
.register-form-wrapper form .upload-logo-wrapper .upload-logo {
  width: 100%;
  display: inline-block;
  float: right;
  text-align: right;
}
.register-form-wrapper form .upload-logo-wrapper .upload-logo .preview-logo {
  display: inline-block;
  margin-right: 20px;
  width: 60px;
  text-align: center;
  height: 60px;
  background: #ececed;
  float: left;
  vertical-align: middle;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.register-form-wrapper form .upload-logo-wrapper .upload-logo .preview-logo img {
  height: 100%;
  width: auto;
  margin: 0 auto;
}
.register-form-wrapper form .upload-logo-wrapper .upload-logo .preview-avatar {
  display: inline-block;
  margin-right: 20px;
  width: 60px;
  float: left;
  text-align: center;
  height: 60px;
  vertical-align: middle;
  overflow: hidden;
}
.register-form-wrapper form .upload-logo-wrapper .upload-logo .preview-avatar img {
  height: 100%;
  width: auto;
  margin: 0 auto;
}
.register-form-wrapper form .radio {
  border-color: transparent;
  box-shadow: none;
  padding: 0;
}
.register-form-wrapper form .radio label {
  font-family: 'Didact Gothic', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  padding-left: 20px;
  width: 100%;
}
.register-form-wrapper form .select2.error + .select2-container .select2-selection {
  border-color: #FF0000;
}
.register-form-wrapper header {
  padding-top: 30px;
}
.register-form-wrapper header .logo img {
  width: 251px;
  height: 86px;
}
.register-form-wrapper header h3 {
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 700;
  margin: 30px 0 20px;
  padding: 0 5px;
  text-transform: uppercase;
}
.register-form-wrapper .title-step {
  color: #2c3e50;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  padding: 0 15px;
  margin: 30px 0;
}
.register-form-wrapper .title-step p {
  color: #7b7b7b;
  font-family: 'Didact Gothic', sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.register-form-wrapper #step-1 {
  margin: 10px 0;
  text-align: center;
}
.register-form-wrapper #step-1 .radio-wraper {
  margin: 1%;
  display: inline-block;
  font-size: 24px;
  padding: 30px;
  font-weight: 600;
  line-height: 24px;
  vertical-align: top;
  cursor: pointer;
  height: 250px;
  width: 43%;
}
.register-form-wrapper #step-1 .radio-wraper.company {
  background: #ffd400 url('../images/building.png') no-repeat center bottom;
  color: #1a1a1a;
}
.register-form-wrapper #step-1 .radio-wraper.individual {
  background: #00a859 url('../images/man-bg.png') no-repeat center bottom;
  color: #fff;
}
.register-form-wrapper #step-1 .radio-wraper:hover {
  text-decoration: none;
}
.register-form-wrapper #step-3 .prev-step {
  margin: 25px auto 5px;
  display: table;
}
.register-form-wrapper #step-3 .btn {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 45%;
}
.register-form-wrapper #step-3 .currently {
  display: none;
}
.register-form-wrapper #step-3 .upload-logo .btn {
  width: 106px;
  margin-top: 8px;
}
.register-form-wrapper .link-forgot-password {
  color: #FF0000;
  font-size: 16px;
}
.register-form-wrapper footer {
  font-size: 16px;
  color: #7b7b7b;
}
.register-form-wrapper footer a {
  color: #eabc33;
  font-weight: 700;
}
.crop-img-wrapper #upload-img,
.crop-img-wrapper.ready .upload-msg,
.crop-img-wrapper #upload-advertise {
  display: none;
}
.crop-img-wrapper.ready #upload-img,
.crop-img-wrapper.ready #upload-advertise {
  display: block;
  margin: 0 auto 30px;
  padding: 0;
  height: 300px;
}
.crop-img-wrapper .upload-msg {
  text-align: center;
  margin: 24px auto;
  padding: 135px 12px 0;
  font-size: 22px;
  color: #aaa;
  border: 1px solid #aaa;
  height: 300px;
  max-width: 300px;
}
.crop-img-wrapper .croppie-container .cr-slider-wrap {
  margin: 16px auto;
}
#btnCrop,
#advertiseBtnCrop {
  width: 100px;
}
.rotation-btns {
  text-align: center;
  margin-top: 64px;
  margin-bottom: 16px;
}
.rotation-btns .rotate-cw-btn {
  cursor: pointer;
}
.upload-logo-btn {
  position: relative;
  margin: 0 !important;
  cursor: pointer;
  width: 100px;
}
.upload-logo-btn input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.select2-container--default .select2-selection--single {
  border-color: #ccc;
  height: auto;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  font-size: 14px;
  padding-left: 12px;
  padding-top: 7px;
  padding-bottom: 4px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 5px;
}
#id_step3-skills + .select2 .select2-selection {
  padding-left: 6px;
}
#id_step3-skills + .select2 span.placeholder {
  left: 12px;
}
.advertise_form form .upload-logo-wrapper label {
  margin-bottom: 0;
  width: 80%;
}
.advertise_form form .upload-logo-wrapper .advertise-upload-logo .preview-logo {
  background: #ececed url('../images/no-logo2.png') no-repeat center;
  border-radius: 0;
  margin: 0 auto 13px;
  overflow: hidden;
  display: block;
  float: none;
  height: 160px;
  width: 270px;
}
.advertise_form form .upload-logo-wrapper .advertise-upload-logo .preview-logo img {
  height: 160px;
  width: 270px;
}
.advertise_form form .upload-logo-wrapper .advertise-upload-logo .btn {
  max-width: 270px;
  margin: 0 auto 20px;
  display: table;
}
.advertise_form form .helper {
  line-height: 18px;
  margin: 5px 0 20px;
  clear: both;
  display: block;
}
.successfully-wrapper {
  background-color: #fff;
  border: 1px solid #2c3e50;
  margin: 40px auto 80px;
  max-width: 630px;
  text-align: center;
  padding: 30px 0;
  width: 100%;
}
.successfully-wrapper .successfully {
  margin: 0 auto;
  padding: 0 15px;
}
.successfully-wrapper .successfully .logo {
  display: block;
  margin-bottom: 80px;
}
.successfully-wrapper .successfully .logo img {
  display: block;
  max-width: 80%;
  height: auto;
  margin: 0 auto;
}
.successfully-wrapper .successfully p {
  color: #7b7b7b;
  font-family: 'Didact Gothic', sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 25px;
  word-break: break-word;
  word-wrap: break-word;
}
.successfully-wrapper .successfully p a {
  color: #1a1a1a;
}
.successfully-wrapper .successfully .h1 {
  text-transform: uppercase;
}
.left-inner-addon {
  position: relative;
}
.left-inner-addon input {
  padding-left: 30px !important;
}
.left-inner-addon i {
  position: absolute;
  left: 10px;
  top: 14px;
  pointer-events: none;
}
.right-inner-addon {
  position: relative;
}
.right-inner-addon input {
  padding-right: 30px !important;
}
.right-inner-addon i {
  position: absolute;
  right: 10px;
  top: 14px;
  pointer-events: none;
}
.modal-open .modal#modal-payment {
  height: 100%;
}
.modal-open .modal#modal-payment .modal-dialog {
  margin: auto;
  transform: translate(0, 30%);
}
.payment-modal-wrapper .modal-header {
  background: #2c3e50;
  border-radius: 4px 4px 0 0;
  border: none;
  padding: 15px 25px;
  text-align: center;
  color: #fff;
}
.payment-modal-wrapper .modal-header .logo {
  margin: -48px 0 15px 0;
}
.payment-modal-wrapper .modal-header h2 {
  font-size: 17px;
  font-weight: 600;
  line-height: 26px;
  text-transform: uppercase;
  margin: 0;
}
.payment-modal-wrapper .modal-header p {
  font-family: 'Didact Gothic', sans-serif;
  font-size: 13px;
  line-height: 20px;
}
.payment-modal-wrapper .modal-header .close {
  color: #fff;
  opacity: 1;
  position: absolute;
  right: 10px;
  top: 10px;
}
.payment-modal-wrapper .modal-content {
  box-shadow: none;
  border: none;
  margin: auto;
  width: 300px;
}
.payment-modal-wrapper .modal-content .small {
  line-height: 16px;
  text-align: left;
}
.payment-modal-wrapper .payment-form-wrapper {
  color: #2c3e50;
  margin: auto;
  padding: 30px 0;
  width: 230px;
}
.payment-modal-wrapper .payment-form-wrapper .card-payment .form-control {
  border-radius: 4px 4px 0 0;
  border-bottom-color: transparent;
}
.payment-modal-wrapper .payment-form-wrapper .card-payment .form-control:focus {
  border-color: #66afe9;
}
.payment-modal-wrapper .payment-form-wrapper .date-payment {
  width: 50%;
  display: inline-block;
  float: left;
}
.payment-modal-wrapper .payment-form-wrapper .date-payment .form-control {
  border-radius: 0 0 0 4px;
  border-right-color: transparent;
}
.payment-modal-wrapper .payment-form-wrapper .date-payment .form-control:focus {
  border-color: #66afe9;
}
.payment-modal-wrapper .payment-form-wrapper .cvc-payment {
  width: 50%;
  display: inline-block;
}
.payment-modal-wrapper .payment-form-wrapper .cvc-payment .form-control {
  border-radius: 0 0 4px 0;
}
.payment-modal-wrapper .payment-form-wrapper .btn {
  padding: 11px 0;
  margin-top: 18px;
}
.payment-errors {
  color: #f00;
}
.certificate-formset .date-wrapper {
  position: relative;
}
.certificate-formset .date-wrapper .form-group {
  padding: 0 5px;
}
.certificate-formset .date-wrapper .line {
  position: absolute;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 20px;
  height: 2px;
  background: #ccc;
  top: 50%;
}
.styler {
  cursor: pointer;
}
.styler select {
  display: none !important;
}
.styler .jq-selectbox__dropdown {
  background: #fff;
  border: 1px solid #aaa;
  box-shadow: 0 0 4px #ccc;
  left: 0;
  margin-top: 4px;
  width: 100%;
}
.styler .jq-selectbox__dropdown ul {
  padding: 0;
  margin: 0;
}
.styler .jq-selectbox__dropdown ul li {
  padding: 13px 10px;
  margin: 0;
  line-height: 18px;
  cursor: pointer;
}
.styler .jq-selectbox__dropdown ul li:hover {
  border-color: #2c3e50;
  background: #2c3e50;
  color: #fff;
}
.styler .jq-selectbox__dropdown ul li + li {
  border-top: 1px solid #ccc;
}
.styler .jq-selectbox__trigger-arrow {
  position: absolute;
  top: 14px;
  right: 0;
  width: 0;
  height: 0;
  border-top: 5px solid #999;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}
.styler .jq-selectbox__select-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.symbol {
  display: block;
  padding-left: 10px;
  margin-bottom: 5px;
  clear: both;
}
.success-info {
  color: #fff;
  padding-bottom: 43px;
}
.success-info h1 {
  font-weight: 700;
  line-height: 1;
  font-size: 39px;
  margin: 78px 0 3px;
}
.success-info p {
  font-size: 16px;
}
.success-info header {
  margin-bottom: 80px;
}
.success-info .btn {
  font-size: 14px;
  padding: 10px 0;
  border-radius: 8px;
}
.create-job-form .full-form {
  max-width: 100%;
}
.create-job-form header h3 {
  margin-top: 0;
  margin-bottom: 30px;
  text-transform: none;
}
.create-job-form form .prev-step {
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
  margin-top: 30px;
}
.create-job-form form .prev-step .glyphicon {
  font-size: 21px;
  vertical-align: top;
}
.create-job-form .two-btn {
  margin-top: 30px;
}
.row.small-p {
  margin-right: -5px;
  margin-left: -5px;
}
.row.small-p > * {
  padding-right: 5px;
  padding-left: 5px;
}
.row.small-p .form-control {
  height: 40px;
}
.after-add-more {
  margin-top: 30px;
}
.after-add-more .middle-text {
  line-height: 1;
}
.after-add-more ~ .item {
  display: table;
  width: 100%;
}
.after-add-more ~ .item .added_questions {
  display: table-cell;
}
.after-add-more ~ .item .remove {
  display: table-cell;
  color: #FF0000;
  font-size: 30px;
  line-height: 1;
  text-align: center;
}
.after-add-more ~ .item + .item {
  margin-top: 20px;
}
.icon-plus {
  color: #00A859;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  vertical-align: middle;
  margin-right: 6px;
  display: inline-block;
  border: 1px solid;
  border-radius: 50%;
  width: 17px;
  text-align: center;
  height: 17px;
}
.job-plan-wrapper {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.job-plan-wrapper .job-plan {
  width: 48%;
}
.job-plan {
  border: 1px solid #EBEBEB;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
}
.job-plan .name {
  font-size: 36px;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  padding: 20px 0 10px;
}
.job-plan .prise {
  background: #2c3e50;
  color: #FFFFFF;
  text-align: center;
  display: flex;
  height: 90px;
  justify-content: center;
  align-items: center;
}
.job-plan .prise .larg {
  font-size: 32px;
  line-height: 40px;
}
.job-plan .option {
  margin: 0;
  padding: 0 15px;
}
.job-plan .option li {
  list-style: none;
  text-align: center;
  padding: 20px 0;
}
.job-plan .option li + li {
  border-top: 1px solid #EBEBEB;
}
.job-plan .btn-bottom {
  border-top: 1px solid #EBEBEB;
  text-align: center;
  padding: 20px 0;
}
.job-plan.plan-type-1 .name {
  color: #eabc33;
}
.job-plan.plan-type-2 .name {
  color: #00a859;
}
.job-step5 h2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  text-transform: uppercase;
  margin: 30px 0 0 0;
}
.job-step5 .item {
  margin-top: 25px;
}
.job-step5 .item .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.job-step5 .item h3 {
  color: #9e9e9e;
  margin: 4px 0 2px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
.job-step5 .item .edit-btns a,
.job-step5 .item .simle-btns a,
.job-step5 .item .edit-btns button,
.job-step5 .item .simle-btns button {
  font-size: 12px;
  font-weight: 600;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  margin: 0;
}
.job-step5 .item .edit-btns .save,
.job-step5 .item .simle-btns .save {
  display: none;
}
.job-step5 .item .fild-val {
  font-size: 16px;
  line-height: 26px;
  word-break: break-word;
  word-wrap: break-word;
}
.job-step5 .item .fild-val.descripton {
  white-space: pre-wrap;
  word-break: break-word;
  word-wrap: break-word;
}
.job-step5 .item .form-group {
  margin-top: 15px;
}
.job-step5.questions .item {
  border-bottom: 1px solid #CCCCCC;
  margin: 10px 0;
}
.job-step5.questions .item .title {
  flex-wrap: nowrap;
  align-items: flex-start;
}
.job-step5.questions .item .questions-btns {
  margin: 10px 0 0 20px;
  white-space: nowrap;
}
.job-step5.questions .item .questions-btns .edit-btns {
  display: inline-block;
}
.job-step5.questions .item .questions-btns .remove {
  font-size: 12px;
  font-weight: 600;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  margin-right: 7px;
}
.job-step5 + .after-add-more .middle-text {
  color: #9e9e9e;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
.job-step5 + .after-add-more:after {
  content: '';
  display: block;
  background: #CCCCCC;
  height: 1px;
  width: calc(100% - 30px);
  position: relative;
  left: 15px;
}
.job-step5 + .after-add-more ~ .item {
  margin-top: 20px;
}
.succes-btns .btn {
  max-width: 230px;
  margin: 15px auto;
}
.baron__clipper {
  position: relative;
  overflow: hidden;
}
.baron__scroller {
  overflow-y: scroll;
  -ms-overflow-style: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  border: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  /* remove line to customize scrollbar in iOs */
}
.baron__scroller::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.baron__track {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}
.baron._scrollbar .baron__track {
  display: block;
}
.baron__free {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 3px;
}
.baron__bar {
  display: none;
  position: absolute;
  right: 0;
  z-index: 1;
  width: 4px;
  background-color: #959595;
  border-radius: 2px;
}
.baron._scrollbar .baron__bar {
  display: block;
}
.baron__control {
  display: none;
}
.accordion-simple .panel {
  box-shadow: none;
  position: relative;
}
.accordion-simple .panel:before {
  background: linear-gradient(to right, #1a1a1a 0%, #1a1a1a 5%, #1a1a1a 10%, #1a1a1a 15%, red 30%, red 35%, red 40%, #ffd400 55%, #ffd400 65%, #ffd400 70%, #00a859 85%, #00a859 90%, #00a859 95%, #00a859 100%);
  content: "";
  height: 3px;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.accordion-simple .panel-heading {
  padding: 0;
}
.accordion-simple .panel-heading > a {
  padding: 20px 0;
  color: #2c3e50;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  text-transform: uppercase;
  text-decoration: none;
}
.accordion-simple .panel-heading > a[aria-expanded="true"] .edit-btn {
  transform: rotate(45deg);
}
.accordion-simple .panel-heading .edit-btn {
  color: #00a859;
  display: block;
  font-weight: 700;
  cursor: pointer;
  font-size: 25px;
  line-height: 0.8;
  transition: all 0.3s ease 0s;
}
.accordion-simple .panel-body {
  border: none!important;
  color: #363636;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin: 0 auto;
  word-wrap: break-word;
  width: 90%;
}
.error-page-wrapper {
  background-color: #fff;
  border: 1px solid #2c3e50;
  margin: 80px auto;
}
.error-page-wrapper .logo {
  display: block;
  margin: 30px auto;
}
.error-page-wrapper .logo img {
  max-width: 250px;
}
.error-page-wrapper .btn {
  padding: 11px 60px 6px;
}
.error-page-wrapper.maintenance-error {
  padding-bottom: 50px;
}
.error-page-wrapper.not-found-error {
  padding: 30px;
}
.error-page-wrapper p {
  line-height: 26px;
}
.error-page-wrapper a {
  text-decoration: none;
}
.error-page-wrapper .blocked-img {
  display: table;
  margin: -34px auto 0;
}
.error-page-wrapper .line {
  border-right: 1px solid #ebebeb;
}
.error500 > .container {
  flex: 1;
}
.error500 .header-main .header-top {
  padding-bottom: 15px;
}
.error500 .header-main .logo img {
  position: static;
}
.error500 #google_translate_element {
  margin-top: -53px;
}
.error500 .navbar,
.error500 .menu-top,
.error500 .search-navbar-form,
.error500 .footer-nav-bottom {
  display: none;
}
.error500 .footer-main {
  padding: 15px 0 5px;
}
.error500 .footer-main > .container > .row:nth-child(1) {
  display: none;
}
.error500 .footer-main hr {
  display: none;
}
.error404 > .container {
  flex: 1;
}
.error404 .header-main .header-top {
  padding-bottom: 15px;
}
.error404 .header-main .logo img {
  position: static;
}
.error404 #google_translate_element {
  margin-top: -53px;
}
.error404 .navbar,
.error404 .menu-top,
.error404 .search-navbar-form {
  display: none;
}
.has-error .control-label {
  color: red;
}
.has-error .form-control {
  border-color: red;
}
.field-errors {
  color: red;
}
.field-errors .error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 0;
  margin-left: 5px;
  line-height: 1;
}
.errorlist {
  list-style: none;
  padding: 0;
  margin: 0;
}
.errorlist li {
  color: red;
  font-size: 12px;
}
p.error,
.errors .error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 0;
  margin-left: 5px;
  line-height: 1;
}
.has-error .help-block {
  color: red;
  font-size: 12px;
}
.relevant-documents-list {
  padding: 5px 0px 0px 0px;
  margin-bottom: 0px;
  list-style: none;
}
.relevant-documents-list li {
  line-height: 16px;
  margin-bottom: 10px;
}
.relevant-documents-list li:last-child {
  margin-bottom: 0px;
}
.relevant-documents-list a {
  position: relative;
  width: 75%;
  padding-top: 2px;
  padding-left: 26px;
  float: left;
  color: #333;
  font-size: 16px;
  transition: .3s;
}
.relevant-documents-list a:hover {
  color: #337ab7;
}
.relevant-documents-list a:before {
  content: url('../images/external-link.png');
  position: absolute;
  top: 0px;
  left: 0px;
}
.relevant-documents-list .document-delete {
  width: 25%;
  float: right;
  padding-top: 2px;
  border: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: right;
  text-transform: uppercase;
  background-color: transparent;
}
@media (min-width: 992px) {
  .relevant-documents-list a {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.advertising-item {
  position: relative;
  display: block;
  max-width: 270px;
  height: 475px;
  padding-bottom: 50px;
  margin: 0px auto 40px;
  border: 1px solid #ebebeb;
}
.advertising-item--small {
  height: auto;
  min-height: 220px;
  padding-bottom: 0px;
}
.advertising-item--small .advertising-item__title {
  white-space: normal;
}
.advertising-item--no-margin {
  margin-bottom: 0px;
}
.advertising-item__status {
  position: absolute;
  left: 0;
  bottom: 100%;
  display: block;
  min-width: 80px;
  font-size: 10px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  padding: 5px 0px 3px;
  border-radius: 6px 6px 0px 0px;
}
.advertising-item__status.active {
  color: #ffffff;
  background-color: #00a859;
}
.advertising-item__status.inactive {
  color: #ffffff;
  background-color: #1a1a1a;
}
.advertising-item__photo img {
  display: block;
  max-width: 100%;
  margin: 0px auto;
}
.advertising-item__content {
  padding: 10px 15px 0px;
}
.advertising-item__title {
  margin: 0px 0px 20px;
  color: #252525;
  font-family: 'Didact Gothic', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.advertising-item__location {
  color: #bdbdbd;
  font-size: 14px;
  margin-bottom: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.advertising-item__specialty {
  margin-bottom: 10px;
  color: #252525;
  font-size: 13px;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.advertising-item__text {
  margin-bottom: 30px;
  color: #252525;
  font-size: 13px;
  overflow: hidden;
  line-height: 18px;
  word-wrap: break-word;
}
.advertising-item__caption {
  position: relative;
}
.advertising-item__caption p {
  margin-bottom: 3px;
  font-size: 13px;
}
.advertising-item__switcher {
  margin-bottom: 30px;
}
.advertising-item__btn {
  min-width: 105px;
  padding: 10px 20px 6px;
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
}
.advertising-item .two-btn {
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 10px;
}
.adv-form__status {
  padding: 10px 0px;
}
.adv-form__status small {
  display: inline-block;
  margin-bottom: 15px;
}
.adv-form__title {
  margin: 0px 0px 15px;
  color: #1a1a1a;
  font-family: 'Didact Gothic', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}
.adv-delete-form .is_deleted {
  display: none;
}
.adv-setting {
  border-top: 1px solid #e6e6e6;
}
.adv-setting__header {
  margin-bottom: 35px;
}
.adv-setting__title {
  margin-bottom: 5px;
}
.banner-top {
  background-image: linear-gradient(to right, rgba(255, 212, 0, 0.7) 0, rgba(26, 26, 26, 0.7) 33%, rgba(0, 168, 89, 0.7) 66%, rgba(255, 0, 0, 0.7) 100%), url("../images/banner-home.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  padding: 80px 0 0;
  position: relative;
  display: table;
  width: 100%;
}
.banner-top h2 {
  color: #fff;
  margin-bottom: 12px;
  margin-top: 0;
}
.banner-top p {
  font-size: 22px;
  line-height: 32px;
}
.banner-top .bigHalfCircle {
  fill: #fafafa;
}
.banner-top ~ section h2 {
  margin: 50px 0 40px;
}
section:before,
section:after {
  content: " ";
  display: table;
}
section:after {
  clear: both;
}
section h2 {
  font-size: 38px;
  font-weight: 700;
  color: #363636;
  text-transform: uppercase;
  margin: 100px 0 65px;
  letter-spacing: 1.2px;
}
section.slider-section:last-of-type {
  padding-bottom: 50px;
}
.slider-section h2 {
  margin: 10px 0;
}
.slider-section .lightSlider {
  list-style: none outside none;
  padding-left: 0;
  margin-bottom: 0;
}
.slider-section .lightSlider li {
  display: block;
  float: left;
  width: 100%;
}
.slider-section .btn-controls {
  text-align: center;
  margin-bottom: 30px;
}
.slider-section .btn-controls:before,
.slider-section .btn-controls:after {
  content: " ";
  display: table;
}
.slider-section .btn-controls:after {
  clear: both;
}
.slider-section .btn-controls div {
  cursor: pointer;
}
.slider-section .btn-controls .prevSlide,
.slider-section .btn-controls .nextSlide {
  border-bottom: 1px solid #363636;
  border-left: 1px solid #363636;
  height: 15px;
  width: 15px;
}
.slider-section .btn-controls .prevSlide {
  float: left;
  margin-left: 10px;
  transform: rotate(45deg);
}
.slider-section .btn-controls .nextSlide {
  float: right;
  margin-right: 10px;
  transform: rotate(-135deg);
}
.slider-section .btn-controls .playSlide {
  display: none;
  border-bottom: 8px solid transparent;
  border-left: 13px solid #2c3e50;
  border-top: 7px solid transparent;
  margin: 0 auto;
  height: 0;
  width: 0;
}
.slider-section .btn-controls .pauseSlide {
  border-left: 2px solid #363636;
  border-right: 2px solid #363636;
  width: 7px;
  height: 15px;
  margin: 0 auto;
}
.slider-section .slider-one-rows .lightSlider li {
  height: 540px;
}
.slider-section .slider-one-rows .lightSlider .item {
  background-color: #2c3e50;
  margin: 0 auto;
  max-width: 370px;
  position: relative;
  padding-top: 200px;
}
.slider-section .slider-one-rows .lightSlider .item > img {
  position: absolute;
  top: 0;
}
.slider-section .slider-one-rows .lightSlider .item .wrap {
  background: #fff;
  border: 1px solid #ebebeb;
  padding: 0 13px 60px;
  position: relative;
  height: 330px;
}
.slider-section .slider-one-rows .lightSlider .item .wrap p {
  color: #7b7b7b;
  font-family: 'Didact Gothic', sans-serif;
  font-size: 16px;
  line-height: 24px;
}
.slider-section .slider-one-rows .lightSlider .item .wrap h3 {
  color: #252525;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}
.slider-section .slider-one-rows .lightSlider .item .wrap .info {
  color: #bdbdbd;
  font-size: 12px;
  margin-bottom: 20px;
}
.slider-section .slider-one-rows .lightSlider .item .wrap .info .icon {
  display: inline-block;
  vertical-align: bottom;
  margin-right: 14px;
}
.slider-section .slider-one-rows .lightSlider .item .wrap .info .date {
  margin-right: 32px;
}
.slider-section .slider-one-rows .lightSlider .item .wrap .btn {
  border: none;
  color: #fff;
  margin-top: 15px;
  font-size: 13px;
  padding: 11px 36px;
  border-radius: 8px;
  text-transform: uppercase;
  position: absolute;
  bottom: 12px;
  left: 13px;
}
.slider-section .slider-two-rows .lightSlider .item {
  margin-top: 0;
  margin: 30px auto 0;
}
.slider-section .slider-two-rows .lightSlider .item + .block-brofile {
  margin-top: 30px;
}
.slider-section .slider-two-rows .block-brofile {
  margin-top: 0;
}
.slider-section .slider-two-rows .block-brofile + .block-brofile {
  margin-top: 30px;
}
.slider-section .slider-one-item {
  margin-bottom: 30px;
}
.slider-section .slider-one-item .lightSlider .item {
  margin-top: 0;
  margin: 30px auto 0;
}
.slider-section .slider-one-item .lightSlider .item + .block-brofile {
  margin-top: 30px;
}
.slider-section .slider-one-item .block-brofile {
  margin-top: 0;
}
.slider-section .slider-one-item .block-brofile + .block-brofile {
  margin-top: 30px;
}
.slider-section .slider-one-item .lightSlider .item {
  height: auto;
  padding-bottom: 20px;
  margin-top: 0;
}
.slider-section .slider-one-item .lightSlider .item p {
  padding: 0;
  word-wrap: break-word;
}
.slider-section .slider-one-item .lightSlider .block-brofile {
  margin-top: 0;
}
.block-brofile {
  background-color: #fff;
  text-align: center;
  float: left;
  margin: 30px 9px 0;
  border: 1px solid #ebebeb;
  padding: 0 24px 128px;
  position: relative;
  height: 400px;
  width: 270px;
}
.block-brofile .title {
  color: #fafafa;
  width: 160px;
  padding: 3px 0;
  margin: 0 auto 15px;
  letter-spacing: 1px;
  font-size: 10px;
  text-transform: uppercase;
  border-radius: 0 0 6px 6px;
}
.block-brofile p {
  color: #252525;
  font-size: 12px;
  padding: 0;
  word-wrap: break-word;
  line-height: 18px;
  overflow: hidden;
}
.block-brofile .name {
  color: #252525;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 15px 0 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.block-brofile .location {
  color: #bdbdbd;
  font-size: 12px;
  margin: 0 0 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.block-brofile .expertise {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.block-brofile .btn {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-radius: 8px;
  padding: 12px 0;
  font-size: 13px;
  margin: 7px 0 10px;
  line-height: 1;
  width: 100%;
}
.block-brofile .two-btn > * {
  width: 45%;
}
.block-brofile footer {
  position: absolute;
  max-width: 220px;
  bottom: 0;
  height: 133px;
  width: 100%;
}
.block-brofile footer .description {
  margin-top: 15px;
}
.block-brofile.black {
  border-top: 2px solid #1a1a1a;
}
.block-brofile.black .title {
  background-color: #1a1a1a;
}
.block-brofile.red {
  border-top: 2px solid #FF0000;
}
.block-brofile.red .title {
  background-color: #FF0000;
}
.block-brofile.green {
  border-top: 2px solid #00a859;
}
.block-brofile.green .title {
  background-color: #00a859;
}
.block-brofile.yellow {
  border-top: 2px solid #eabc33;
}
.block-brofile.yellow .title {
  background-color: #eabc33;
}
.block-brofile.purple {
  border-top: 2px solid #800080;
}
.block-brofile.purple .title {
  background-color: #800080;
}
.subscribe {
  background-color: rgba(0, 142, 74, 0.8);
  position: relative;
  padding: 25px 0;
  margin-top: 100px;
}
.subscribe .bigHalfCircle {
  fill: #fafafa;
}
.subscribe h2 {
  color: #fff;
  margin-bottom: 0;
}
#form-subscribe {
  margin: 25px auto 40px;
  max-width: 570px;
}
#form-subscribe .form-control {
  border: 2px solid #ffd400;
  border-radius: 4px 0 0 4px;
  padding-top: 13px;
  padding: 0 13px;
  font-size: 13px;
  height: 46px;
}
#form-subscribe .btn {
  correctcolortypeborder-radius: 0 4px 4px 0;
  color: #2f2f2f;
  padding: 0;
  height: 46px;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  width: 150px;
}
.activity-wrapper {
  margin-bottom: 40px;
}
.activity-wrapper .perspectives-slid .slider-section .slider-one-item .lightSlider .item {
  height: 340px;
}
.activity-wrapper .perspectives-slid .slider-section .slider-one-item .lightSlider .item .btn {
  position: absolute;
  margin: 0 0 0 24px;
  width: 220px;
  left: 0;
  bottom: 20px;
}
.activity-wrapper .perspectives-slid .slider-section .slider-one-item .lightSlider .item .wrap {
  position: relative;
  padding-bottom: 60px;
  height: 100%;
}
.activity-wrapper .slider-section > .tittle {
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.activity-wrapper.mt0 .slider-section .slider-one-item .lightSlider {
  margin-top: 0;
}
.activity-list {
  margin-top: 30px;
}
.activity-list > .media {
  margin-bottom: 30px;
}
.activity-list > .media > .media-left {
  padding-right: 30px;
}
.activity-list > .media > .media-left img {
  width: 70px;
  height: 70px;
}
.activity-list > .media > .media-body {
  background: #fff;
  border: 1px solid #2c3e50;
  border-radius: 4px;
  padding: 30px 30px 10px 30px;
}
.activity-list > .media > .media-body .media + .media {
  border-top: 1px solid #ebebeb;
  margin: 0;
  padding-top: 15px;
}
.activity-list > .media > .media-body .media-body {
  padding: 0;
}
.activity-list > .media > .media-body .media-body .name {
  position: static;
  white-space: normal;
  line-height: 21px;
}
.activity-list > .media > .media-body .media-right {
  white-space: nowrap;
}
.activity-list > .media > .media-body .media-right > * {
  display: inline-block;
  vertical-align: top;
}
.activity-list > .media > .media-body .media-right .btn {
  width: auto;
  margin-left: 15px;
}
.activity-list > .media > .media-body .media-right .follow-form .btn {
  width: 100px;
}
.about-project .yellow {
  background-color: #ffd400;
  padding-top: 20px;
  padding-bottom: 50px;
}
.about-project .two-colors {
  background: linear-gradient(to right, #ff0000 50%, #00a859 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0000', endColorstr='#00a859', GradientType=1);
  color: #fff;
  position: relative;
}
.about-project .two-colors h2 {
  color: #fff;
}
.about-project .two-colors .col-sm-6:nth-child(1) {
  padding-right: 80px;
}
.about-project .two-colors .col-sm-6:nth-child(2) {
  padding-left: 80px;
}
.about-project .two-colors .top {
  fill: url("#linearGradient-svg");
  top: -6.9%;
  bottom: auto;
}
.about-project .two-colors .bottom {
  fill: url(#linearGradient-svg-b);
  bottom: -6.9%;
  top: auto;
  z-index: 1;
}
.about-project h2 {
  margin: 40px 0 10px;
}
.about-project p {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}
.features {
  background: #1a1a1a;
  color: #fff;
  position: relative;
  padding-bottom: 50px;
}
.features .bigHalfCircle {
  fill: #fafafa;
}
.features h2 {
  color: #fff;
}
.features .img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  margin: 0 auto;
  overflow: hidden;
}
.features .img img {
  display: block;
  width: 140px;
  height: 140px;
}
.features .name {
  font-size: 22px;
  text-transform: uppercase;
  margin: 35px 0 20px;
}
.features p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 40px;
}
.team {
  padding-bottom: 50px;
}
.team h2 {
  margin: 60px 0;
}
.team .name {
  color: #252525;
  font-size: 16px;
}
.team .work-position {
  color: #bdbdbd;
  font-size: 12px;
}
.team .description {
  text-align: left;
  color: #252525;
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;
}
.team .img {
  margin: 0 auto 25px;
  display: inline-block;
}
.team .img img {
  width: 100%;
  height: auto;
}
.about-video {
  padding: 0px 15px 50px;
}
.about-video iframe {
  width: 100%;
  height: 220px;
}
.social-links {
  margin: 15px 0 5px;
}
.social-links a {
  padding: 6px;
}
@media (min-width: 568px) {
  .about-video iframe {
    height: 300px;
  }
}
@media (min-width: 768px) {
  .about-video iframe {
    max-width: 500px;
    height: 300px;
  }
  .about-project .two-colors {
    padding-bottom: 30px;
  }
}
@media (min-width: 992px) {
  .about-video iframe {
    max-width: 600px;
    height: 350px;
  }
}
@media (min-width: 1200px) {
  .about-video iframe {
    max-width: 800px;
    height: 500px;
  }
}
.left-sidebar {
  background: #fff;
  border: 1px solid #ebebeb;
  float: left;
  margin-top: 40px;
  width: 270px;
}
.right-content {
  margin: 40px -12px 40px 287px;
}
.right-content h2 {
  margin: 0;
  padding-left: 10px;
}
.middle-content {
  margin: 40px -12px 40px 287px;
  margin: 0;
  flex: 1;
  padding: 40px 30px;
  min-width: 270px;
}
.middle-content h2 {
  margin: 0;
  padding-left: 10px;
}
.middle-content + .right-sidebar {
  flex: 1;
  max-width: 270px;
  min-width: 270px;
}
.middle-content + .right-sidebar .slider-one-item .lightSlider {
  margin-top: 0;
}
.middle-content + .right-sidebar .block-gray {
  margin: 15px 0 35px 0;
}
.middle-content + .right-sidebar .block-gray footer {
  border-top: 1px solid #2c3e50;
  padding-top: 15px;
}
.forms-filter {
  padding: 0 13px;
}
.forms-filter .item .title {
  color: #1a1a1a;
  padding: 12px 0;
  border-bottom: 1px solid #2c3e50;
  margin-bottom: 15px;
}
.forms-filter .item .title small {
  display: block;
  color: #959595;
  font-size: 12px;
  margin-top: 5px;
}
.forms-filter .item .list-find {
  font-family: 'Didact Gothic', sans-serif;
  font-size: 14px;
  line-height: 18px;
  padding-top: 5px;
  padding-bottom: 10px;
}
.forms-filter .item .list-find > div {
  margin: 8px 0;
}
.forms-filter .item .list-find .col-sm-4 {
  color: #959595;
}
.forms-filter .item .list-find a {
  color: #1a1a1a;
}
.forms-filter .item .list-find a.active {
  color: #00a859;
}
.forms-filter .btn-reset {
  display: table;
  margin: 0 auto 25px;
  width: 100%;
  max-width: 164px;
  padding: 9px 0;
  border-radius: 8px;
}
.forms-filter .btn-reset .glyphicon {
  margin-right: 10px;
  font-size: 15px;
  top: 4px;
}
.forms-filter a {
  text-decoration: none;
}
.result-btns,
.keywords {
  padding: 7px 0 6px;
}
.result-btns .btn,
.keywords .btn {
  margin: 6px 8px 0;
  border-radius: 5px;
  padding: 5px 18px 5px;
  border: none;
  letter-spacing: 0.8px;
  font-family: 'Didact Gothic';
}
.result-btns .btn.black,
.keywords .btn.black {
  cursor: default;
}
.result-btns .btn .remove-filter,
.keywords .btn .remove-filter {
  padding: 0 0 0 6px;
  position: relative;
  font-family: "Myriad Pro";
  top: -1px;
}
.result-num {
  border-top: 1px solid #2c3e50;
  padding-top: 20px;
  margin: 0 10px;
  display: inline-block;
  width: 97%;
}
.result-num .dropdown-toggle,
.result-num .pull-left {
  color: #1a1a1a;
  font-size: 14px;
}
.list-find-checkbox .check {
  margin: 15px 0;
}
.show-more-group {
  margin-bottom: 20px;
}
.show-more-group .check {
  margin: 0 0 15px;
}
.show-more-group a {
  text-decoration: none;
}
.show-more-group .glyphicon {
  color: #2c3e50;
  font-size: 11px;
  margin-right: 6px;
}
.show-more-group .collaps-link {
  color: #FF0000;
  font-family: 'Didact Gothic', sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.show-more-group .collapse.in + .accordion-toggle .glyphicon {
  transform: rotate(-180deg);
}
.chosen-list {
  padding: 0;
  margin: 10px 0;
}
.chosen-list li {
  list-style: none;
  color: #1a1a1a;
  font-family: 'Didact Gothic', sans-serif;
  padding: 15px 0;
  font-size: 14px;
}
.chosen-list li a {
  color: #1a1a1a;
  text-decoration: none;
  line-height: 7px;
  font-size: 23px;
}
.btn-filter-mobile {
  border-radius: 0 5px 5px 0;
  position: fixed;
  left: -2px;
  top: 62px;
  display: none;
  z-index: 1;
}
.pagination-wrapper .pagination {
  margin-top: 30px;
  width: 100%;
}
.pagination-wrapper .pagination li {
  margin: 3px 4px;
  display: inline-block;
}
.pagination-wrapper .pagination li a {
  border-radius: 2px;
  color: #959595;
  font-size: 16px;
  font-weight: 400;
  height: 32px;
}
.pagination-wrapper .pagination li a .glyphicon {
  vertical-align: middle;
  margin-top: -3px;
}
.pagination-wrapper .pagination li a.active {
  background-color: #2c3e50;
  border-color: #2c3e50;
  color: #fff;
}
.pagination-wrapper .pagination li a.active:hover {
  background-color: #2c3e50;
  color: #fff;
}
.pagination-wrapper .pagination li a:hover {
  background: #fff;
  border-color: #2c3e50;
  color: #2c3e50;
}
.pagination-wrapper .pagination li a.green {
  color: #00a859;
}
.pagination-wrapper .pagination li a.green:hover {
  background: #fff;
  border-color: #00a859;
  color: #00a859;
}
.larg-text {
  font-family: 'Didact Gothic', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}
.advertising-partners.advertising-list-empty {
  padding: 80px 0px 100px;
}
.advertising-partners .block-brofile {
  padding: 0 0 128px;
  overflow: hidden;
  height: 500px;
}
.advertising-partners .block-brofile img {
  display: block;
  height: auto;
  max-height: 160px;
  margin-bottom: 30px;
  width: 100%;
}
.advertising-partners .block-brofile p {
  padding: 0;
}
.advertising-partners .block-brofile .wrap {
  padding: 0 28px;
}
.advertising-partners .block-brofile footer {
  left: 50%;
  height: 72px;
  transform: translateX(-50%);
}
.select2-selection__choice {
  display: none;
}
.filter-btns,
.skills-btns,
.keywords-btns {
  padding: 0;
  margin: 0;
  display: inline-block;
  vertical-align: middle;
}
.filter-btns li,
.skills-btns li,
.keywords-btns li {
  float: left;
  display: inline-block;
  list-style: none;
}
.filter-btns li .btn,
.skills-btns li .btn,
.keywords-btns li .btn {
  border-radius: 5px;
}
.filter-btns #id_all_clear,
.skills-btns #id_all_clear,
.keywords-btns #id_all_clear {
  margin-bottom: 6px;
}
.filter-btns .tag-selected,
.skills-btns .tag-selected,
.keywords-btns .tag-selected,
.filter-btns .tag-checked,
.skills-btns .tag-checked,
.keywords-btns .tag-checked {
  background: #1a1a1a;
  font-weight: 600;
  color: #fff;
  padding: 6px 20px 6px 12px;
  line-height: 1.42857143;
  vertical-align: middle;
  border-radius: 4px;
  margin: 5px 10px;
  word-break: break-word;
  word-wrap: break-word;
  position: relative;
}
.filter-btns .tag-selected a,
.skills-btns .tag-selected a,
.keywords-btns .tag-selected a,
.filter-btns .tag-checked a,
.skills-btns .tag-checked a,
.keywords-btns .tag-checked a {
  float: right;
  margin-left: 10px;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  right: 6px;
  top: 6px;
}
.filter-btns .tag-selected.green,
.skills-btns .tag-selected.green,
.keywords-btns .tag-selected.green,
.filter-btns .tag-checked.green,
.skills-btns .tag-checked.green,
.keywords-btns .tag-checked.green {
  background: #00a859;
}
.selected-btns {
  padding: 0;
  margin: 0;
}
.selected-btns li {
  list-style: none;
  margin: 20px 0;
  position: relative;
}
.selected-btns li a {
  color: #1a1a1a;
  position: absolute;
  top: 0;
  right: 0;
  text-decoration: none;
  cursor: pointer;
}
.advertising-partners .left-sidebar {
  margin-top: 10px;
}
.advertising-partners .right-content {
  margin-top: 0;
}
.right-sidebar {
  float: right;
  margin-top: 58px;
  width: 270px;
}
.left-content {
  margin: 40px 287px 40px 0;
}
.breadcrumb {
  background: #00a859;
}
.breadcrumb a {
  color: #fff;
}
.forum-wrapper,
.job-details-wrapper {
  display: inline-block;
  width: 100%;
}
.forum-wrapper .left-content,
.job-details-wrapper .left-content {
  margin: 40px 0 40px 0;
  width: 100%;
  display: inline-block;
  float: left;
  padding-right: 287px;
}
.forum-wrapper .right-sidebar,
.job-details-wrapper .right-sidebar {
  margin-top: 58px;
  width: 270px;
  margin-left: -270px;
}
.forum-wrapper h2,
.job-details-wrapper h2 {
  margin: 40px 0 10px;
}
.forum-wrapper .btn-default,
.job-details-wrapper .btn-default {
  border-radius: 4px;
  border: none;
  padding: 6px 12px 5px;
}
.forum-wrapper .panel-default,
.job-details-wrapper .panel-default {
  border: none;
  border-radius: 0;
}
.forum-wrapper .panel-default .panel-heading,
.job-details-wrapper .panel-default .panel-heading {
  border-radius: 0;
}
.forum-wrapper .panel-default .panel-body,
.job-details-wrapper .panel-default .panel-body {
  border: 1px solid #ddd;
  border-top: none;
  display: inline-block;
  width: 100%;
}
.forum-wrapper .preview,
.job-details-wrapper .preview {
  display: inline-block;
  width: 100%;
}
.forum-wrapper .topic-review .avatar,
.job-details-wrapper .topic-review .avatar {
  float: left;
  margin-right: 10px;
}
.job-details-wrapper .right-sidebar {
  margin-top: 40px;
}
.job-details-wrapper .btn-default {
  border-radius: 8px;
  padding: 14px 12px 9px;
}
.forum-table {
  margin-bottom: 30px;
}
.forum-table .avatar {
  float: left;
  margin-right: 9px;
}
.forum-table .category .subject {
  margin-top: 0;
}
.forum-table .category a {
  text-decoration: none;
}
.forum-table.yellow thead {
  background-color: #ffd400;
}
.forum-table.green thead {
  background-color: #00a859;
}
.forum-table.green thead th {
  color: #fff;
}
.forum-table thead > tr > th {
  font-size: 16px;
  font-weight: 400;
  padding-top: 11px;
  text-transform: uppercase;
  border: none;
}
.forum-table thead > tr > th:last-child {
  width: 30%;
}
.forum-table tbody {
  background: #fff;
  border-right: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
}
.forum-table tbody > tr {
  border-bottom: 1px solid #ebebeb;
}
.forum-table tbody > tr > td {
  padding-top: 15px;
  padding-bottom: 15px;
}
.forum-table .category .icon-wrap {
  background-color: #ebebeb;
  border-radius: 50%;
  padding: 10px;
  vertical-align: middle;
  display: inline-block;
  margin: 0 30px 0 15px;
  width: 40px;
  height: 40px;
}
.forum-table .category .forumdescription {
  vertical-align: middle;
  display: inline-block;
  max-width: 357px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.forum-table .category .forumdescription a {
  font-size: 16px;
  font-weight: 700;
  color: #1a1a1a;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.forum-table .category .forumdescription p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.forum-table .forumlastpost .username-avatar {
  display: table-cell;
  vertical-align: top;
  padding-right: 15px;
}
.forum-table .forumlastpost .username-avatar img {
  height: 40px;
  width: 40px;
}
.forum-table .forumlastpost .forumlastpost-info {
  font-family: 'Didact Gothic', sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: top;
}
.forum-table .forumlastpost .forumlastpost-info .lastpostname {
  font-size: 14px;
  color: #1a1a1a;
}
.forum-table .forumlastpost .forumlastpost-info .lastpostusername {
  font-size: 12px;
}
.forum-table .forumlastpost .forumlastpost-info .lastpostdate {
  color: #959595;
  font-size: 12px;
}
.forum-table .forumlastpost .forumlastpost-info .icon {
  margin-right: 5px;
  width: 10px;
}
.forum-table .forumlastpost .forumlastpost-info p {
  margin-bottom: 2px;
}
.forum-table .topic,
.forum-table .amountspost {
  color: #959595;
  font-family: 'Didact Gothic', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  padding-top: 22px;
}
.forum-info {
  font-size: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.forum-info > div {
  display: flex;
}
.forum-info > div:nth-child(1) {
  width: 60%;
}
.forum-info > div:nth-child(2) {
  width: 40%;
}
.forum-info .icons-legend {
  background: #fff;
  width: 96%;
}
.forum-info .icons-legend li {
  margin: 25px;
}
.forum-info .permissions {
  background: #fff;
  width: 100%;
}
.forum-info .permissions ul {
  padding: 20px;
}
.forum-info .permissions ul li {
  margin: 10px 0;
}
.forum-info .title {
  background-color: #1a1a1a;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  padding: 9px 10px 5px;
  text-transform: uppercase;
}
.forum-info ul {
  margin: 0;
  padding: 0;
}
.forum-info ul li {
  list-style: none;
  display: block;
  font-family: 'Didact Gothic', sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.forum-info ul li .icon {
  vertical-align: middle;
  margin-right: 25px;
  float: left;
}
.twitter .twitter-widget {
  margin-bottom: 30px;
}
.twitter .title {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.partners h2 {
  margin: 40px 0 10px;
  display: inline;
}
.partners .item {
  background-color: #ebebeb;
  padding: 0 40px;
  margin-bottom: 40px;
  margin-top: 40px;
  height: 360px;
  display: flex;
  align-items: center;
}
.partners .item > div {
  margin: 0 auto;
}
.partners .item h4 {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.partners .item p {
  font-family: 'Didact Gothic', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
}
.partners .item img {
  height: auto;
  width: 100%;
}
.post-edit > .panel-heading {
  background: #ffd400;
}
.topic-review .panel-heading {
  background: #ffd400;
}
.reply {
  min-width: 80px;
  padding: 6px 12px;
  margin-bottom: 20px;
}
.sign-in-option {
  font-size: 16px;
  margin: 20px 0px;
}
.sign-in-option a {
  font-weight: bolder;
}
.sign-in-option .color-red {
  color: #FF0000;
}
.sign-in-option .color-green {
  color: #00a859;
}
.prifile-wrapper {
  margin: 80px 0;
}
.profile-info {
  background-color: #fff;
  border: 1px solid #2c3e50;
  padding: 0 15px;
  margin-bottom: 25px;
  position: relative;
}
.profile-info .skills-btns .tag-selected {
  margin: 10px 0;
}
.profile-info .skills-btns .tag-selected + .tag-selected {
  margin-left: 10px;
}
.profile-info .title {
  position: absolute;
  left: 0;
  bottom: 100%;
  width: 100%;
}
.profile-info .title .qualifications {
  display: inline-block;
  padding: 6px 20px;
  color: #fafafa;
  font-family: 'Didact Gothic', sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 6px 6px 0 0;
  bottom: 0;
  left: -1px;
  position: absolute;
}
.profile-info .title .setting-btn {
  border-radius: 8px 8px 0 0;
  background-color: #2c3e50;
  border-color: #2c3e50;
  margin-right: -1px;
  float: right;
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  max-width: 200px;
  top: -2px;
  text-align: right;
  position: relative;
  padding: 10px 16px 5px;
  z-index: 1;
}
.profile-info .title .switch-btn {
  float: right;
  margin-right: -18px;
  min-width: 210px;
  position: relative;
  top: -2px;
}
.profile-info .title .switch-btn .btn {
  border-bottom-color: transparent;
  border-radius: 8px 8px 0 0;
  padding: 10px 16px 5px;
  text-align: left;
  z-index: 0;
}
.profile-info .user-name {
  color: #252525;
  font-weight: 600;
  letter-spacing: 2px;
  word-wrap: break-word;
  float: left;
  line-height: 1;
  margin: 0;
  width: 80%;
}
.profile-info .btn-group {
  position: absolute;
  right: 20px;
  top: 0;
}
.profile-info p {
  font-size: 16px;
  clear: both;
  width: 100%;
  word-wrap: break-word;
}
.profile-info h3 {
  color: #9e9e9e;
  margin: 4px 0 2px;
  font-size: 13px;
  float: left;
  font-weight: 600;
  text-transform: uppercase;
}
.profile-info a.edit,
.profile-info .btn {
  font-size: 12px;
  font-weight: 600;
  float: right;
  margin-top: 6px;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
}
.profile-info .two-btn {
  margin-bottom: 20px;
}
.profile-info .two-btn .btn,
.profile-info .two-btn a {
  float: none;
  padding: 6px 8px;
  vertical-align: baseline;
  text-decoration: none;
}
.profile-info a.edit {
  display: none;
  text-decoration: none;
}
.profile-info a.edit.collapsed {
  display: inline-block;
}
.profile-info .marg-row {
  margin: 15px 0;
}
.profile-info .marg-row:before,
.profile-info .marg-row:after {
  content: " ";
  display: table;
}
.profile-info .marg-row:after {
  clear: both;
}
.profile-info .marg-row .item .collapse.in,
.profile-info .marg-row .item .collapsing {
  margin-top: 0;
}
.profile-info .marg-row #edit-location .two-btn,
.profile-info .marg-row #edit-certificates .two-btn,
.profile-info .marg-row #edit-languages .two-btn {
  margin-bottom: 4px;
}
.profile-info.green {
  border-top: 2px solid #00a859;
}
.profile-info.green .qualifications {
  background-color: #00a859;
}
.profile-info.black {
  border-top: 2px solid #1a1a1a;
}
.profile-info.black .qualifications {
  background-color: #1a1a1a;
}
.profile-info.red {
  border-top: 2px solid #FF0000;
}
.profile-info.red .qualifications {
  background-color: #FF0000;
}
.profile-info.yellow {
  border-top: 2px solid #eabc33;
}
.profile-info.yellow .qualifications {
  background-color: #eabc33;
}
.profile-info.purple {
  border-top: 2px solid #800080;
}
.profile-info.purple .qualifications {
  background-color: #800080;
}
.profile-info .info-right {
  width: 360px;
  text-align: center;
  float: right;
  position: relative;
  margin: 0;
}
.profile-info .info-right .edit {
  display: block;
  position: absolute;
  right: 15px;
  top: 15px;
}
.profile-info .info-right #edit-avatar {
  position: absolute;
  right: 15px;
  top: 65px;
  z-index: 2;
  width: 200px;
  border-radius: 4px;
  overflow: hidden;
}
.profile-info .info-right #edit-avatar .btn {
  display: block;
  float: none;
  border-radius: 0;
  width: 100%;
  margin: 0;
}
.profile-info .info-right #edit-avatar .avatar-profile {
  display: none;
}
.profile-info .avatar-profile {
  background: #ccc;
  margin: -36px auto 0;
  display: table;
  z-index: 2;
  position: relative;
  height: 170px;
  width: 170px;
}
.profile-info .avatar-profile img {
  height: 170px;
  width: 170px;
}
.profile-info .followers,
.profile-info .following {
  margin: 30px 0 20px;
}
.profile-info .vertical-line {
  background: #ededed;
  height: 100%;
  width: 1px;
  display: block;
  position: absolute;
  right: 390px;
  top: 0;
}
.profile-info .list-certifications {
  border-top: 1px solid #ccc;
  clear: both;
  margin-top: 25px;
  font-family: 'Didact Gothic', sans-serif;
  height: auto !important;
  opacity: 1 !important;
}
.profile-info .list-certifications .item {
  border-bottom: 1px solid #ccc;
  overflow: hidden;
  padding: 0;
  width: 100%;
}
.profile-info .list-certifications .item > p {
  margin: 5px 0;
}
.profile-info .list-certifications .item .name {
  margin-top: 3px;
  display: inline-block;
}
.profile-info .list-certifications .item .defete-item,
.profile-info .list-certifications .item .edit-item {
  font-size: 12px;
  font-weight: 600;
  margin-top: 6px;
  margin-left: 10px;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
}
.profile-info .list-languages {
  border-top: 1px solid #ccc;
  margin-top: 25px;
  font-family: 'Didact Gothic', sans-serif;
  height: auto !important;
  opacity: 1 !important;
  border: none;
  clear: both;
}
.profile-info .list-languages .item {
  border-bottom: 1px solid #ccc;
  overflow: hidden;
  padding: 0;
  width: 100%;
}
.profile-info .list-languages .item > p {
  margin: 5px 0;
}
.profile-info .list-languages .item .name {
  margin-top: 3px;
  display: inline-block;
}
.profile-info .list-languages .item .defete-item,
.profile-info .list-languages .item .edit-item {
  font-size: 12px;
  font-weight: 600;
  margin-top: 6px;
  margin-left: 10px;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
}
.profile-info .list-languages .item {
  border: none;
}
.profile-info .collapse.in,
.profile-info .collapsing {
  display: inline-block;
  margin-top: -30px;
  clear: both;
  overflow: hidden;
  width: 100%;
}
.profile-info .collapse.in + *,
.profile-info .collapsing + * {
  opacity: 0;
  height: 0;
}
.profile-info .active-tble {
  border: none;
  border-radius: 0;
  margin-bottom: 0;
}
.followers,
.following {
  display: inline-block;
  text-align: center;
  width: 49%;
  clear: both;
}
.followers p,
.following p {
  font-size: 12px;
}
.followers span,
.following span {
  font-size: 36px;
}
.profile-detail .profile-info h3 {
  float: none;
}
.relevant-documents .file-group .file-name {
  line-height: 24px;
}
.relevant-documents .file-group .btn-file-name {
  line-height: 10px;
}
.edit-btn-cancel {
  color: #1a1a1a;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
.employer_profile {
  margin: 40px 0;
}
.employer_profile .employer-name {
  font-weight: 700;
  word-wrap: break-word;
  width: 85%;
}
.employer_profile .employer-name.pull-left {
  margin-top: 0;
}
.employer_profile .profile-info {
  padding: 30px 30px 0;
  display: flex;
}
.employer_profile .profile-info > .row {
  width: 100%;
  flex: 1;
}
.employer_profile .profile-info .two-btn > * {
  display: inline-block;
  margin: 0 1%;
  width: 47%;
}
.employer_profile .profile-info .marg-row {
  margin-top: 0;
}
.employer_profile .profile-info p {
  transition: all 0.4s ease 0s;
  width: 100%;
  word-wrap: break-word;
}
.employer_profile .profile-info .collapse.in,
.employer_profile .profile-info .collapsing {
  display: block;
  margin-top: -30px;
  overflow: hidden;
  clear: both;
  width: 100%;
}
.employer_profile .profile-info .collapse.in + *,
.employer_profile .profile-info .collapsing + * {
  opacity: 0;
  height: 0;
}
.employer_profile aside .two-btn {
  max-width: 270px;
  margin: 0 auto;
}
.employer_profile aside .two-btn .btn {
  margin: 10px 0;
}
.employer_profile aside .profile-settings {
  margin: 0 auto;
  text-align: right;
}
.employer_profile aside .profile-settings .edit {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 20px;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
}
.employer_profile aside .profile-settings #edit-avatar {
  margin-bottom: 15px;
}
.employer_profile aside .profile-settings #edit-avatar .btn {
  display: inline-block;
  margin: 0 1%;
  font-size: 12px;
  padding: 6px 8px;
  vertical-align: baseline;
  text-decoration: none;
  width: 47%;
}
.employer_profile .company-logo {
  background: #fff;
  border: 1px solid #2c3e50;
  max-width: 270px;
  text-align: center;
  margin: 0 auto 10px;
}
.employer_profile .company-logo img {
  height: auto;
  width: 100%;
}
.employer_profile .company-logo .title {
  padding: 5px 0;
  margin: 0 auto -24px;
  font-size: 14px;
  font-family: 'Didact Gothic', sans-serif;
  text-transform: uppercase;
  border-radius: 0 0 6px 6px;
  position: relative;
  width: 180px;
}
.employer_profile .company-logo.red {
  border-top: 2px solid #FF0000;
}
.employer_profile .company-logo.red .title {
  color: #fafafa;
  background-color: #FF0000;
}
.employer_profile .switch-btn .btn {
  margin: 10px 0 20px;
}
.employer_profile .followers,
.employer_profile .following {
  margin-top: 10px;
}
.unregistered-block {
  position: relative;
  padding: 25px 0;
}
.unregistered-block .info {
  font-size: 36px;
  font-weight: 700;
  margin: 30px auto;
  text-transform: uppercase;
}
.unregistered-block.yellow {
  background-color: #ffd400;
}
.unregistered-block .bigHalfCircle {
  fill: #fafafa;
}
.unregistered-block .btn {
  min-width: 175px;
}
.profile-settings-wrapper {
  background-color: #fff;
  border: 1px solid #2c3e50;
  border-radius: 4px;
  padding: 0 30px 30px;
  margin: 80px auto;
  max-width: 368px;
}
.profile-settings-wrapper .avatar-profile {
  border-radius: 50%;
  overflow: hidden;
  margin: -40px auto 40px;
  height: 170px;
  width: 170px;
}
.profile-settings-wrapper .avatar-profile img {
  height: 170px;
  width: 170px;
}
.profile-settings-wrapper .setting-btn-group .btn {
  margin-top: 30px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
}
.profile-settings-wrapper .back-btn {
  background: none;
  border: none;
  color: #eabc33;
  font-size: 16px;
  font-weight: 700;
  display: table;
  margin: 20px auto 0;
}
.profile-settings-wrapper .upload-logo-wrapper span {
  font-size: 16px;
}
.profile-settings-wrapper .upload-logo-wrapper .btn {
  margin-top: -10px;
}
.profile-settings,
.active-jobs {
  margin: 40px auto;
}
.profile-settings .menu-settings,
.active-jobs .menu-settings,
.profile-settings .menu-aside,
.active-jobs .menu-aside {
  background-color: #fff;
  border: 1px solid #2c3e50;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 20px;
  position: fixed;
}
.profile-settings .menu-settings .tittle,
.active-jobs .menu-settings .tittle,
.profile-settings .menu-aside .tittle,
.active-jobs .menu-aside .tittle {
  background: #2c3e50;
  color: #FFFFFF;
  padding: 15px 23px;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
}
.profile-settings .menu-settings a,
.active-jobs .menu-settings a,
.profile-settings .menu-aside a,
.active-jobs .menu-aside a {
  border-left: 4px solid transparent;
  color: #757575;
  display: block;
  font-size: 14px;
  font-weight: 700;
  padding: 20px;
  text-decoration: none;
}
.profile-settings .menu-settings a + a,
.active-jobs .menu-settings a + a,
.profile-settings .menu-aside a + a,
.active-jobs .menu-aside a + a {
  border-top: 1px solid #ededed;
}
.profile-settings .menu-settings a.active,
.active-jobs .menu-settings a.active,
.profile-settings .menu-aside a.active,
.active-jobs .menu-aside a.active,
.profile-settings .menu-settings a:hover,
.active-jobs .menu-settings a:hover,
.profile-settings .menu-aside a:hover,
.active-jobs .menu-aside a:hover {
  color: #00a859;
  border-left-color: #00a859;
}
.profile-settings .settings-block,
.active-jobs .settings-block {
  background-color: #fff;
  border: 1px solid #2c3e50;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 40px;
}
.profile-settings .settings-block .panel,
.active-jobs .settings-block .panel {
  margin: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.profile-settings .settings-block .tittle,
.active-jobs .settings-block .tittle {
  background-color: #2c3e50;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  padding: 30px;
  text-transform: uppercase;
}
.profile-settings .settings-block .item + .item,
.active-jobs .settings-block .item + .item {
  border-top: 1px solid #ededed;
}
.profile-settings .settings-block .item h4,
.active-jobs .settings-block .item h4 {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 6px;
}
.profile-settings .settings-block .item p,
.active-jobs .settings-block .item p {
  color: #757575;
  font-family: 'Didact Gothic', sans-serif;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  word-wrap: break-word;
}
.profile-settings .settings-block .item a > p,
.active-jobs .settings-block .item a > p {
  margin: 0;
}
.profile-settings .settings-block .item .wrap-item,
.active-jobs .settings-block .item .wrap-item {
  border-left: 4px solid transparent;
  padding: 20px;
  display: block;
  color: #1a1a1a;
  text-decoration: none;
}
.profile-settings .settings-block .item .wrap-item[aria-expanded="true"],
.active-jobs .settings-block .item .wrap-item[aria-expanded="true"] {
  border-left-color: #00a859;
}
.profile-settings .settings-block .item .wrap-item[aria-expanded="true"] h4,
.active-jobs .settings-block .item .wrap-item[aria-expanded="true"] h4 {
  color: #00a859;
}
.profile-settings .settings-block .item .wrap-item[aria-expanded="true"] .edit-btn,
.active-jobs .settings-block .item .wrap-item[aria-expanded="true"] .edit-btn {
  transform: rotate(45deg);
}
.profile-settings .settings-block .item .wrap-item > p,
.active-jobs .settings-block .item .wrap-item > p {
  line-height: 18px;
  width: 90%;
}
.profile-settings .settings-block .item .short,
.active-jobs .settings-block .item .short {
  max-width: 350px;
}
.profile-settings .settings-block .item .collapse,
.active-jobs .settings-block .item .collapse,
.profile-settings .settings-block .item .collapsing,
.active-jobs .settings-block .item .collapsing {
  padding: 0 20px 20px;
  border-left: 4px solid #00a859;
}
.profile-settings .settings-block .item .edit-btn,
.active-jobs .settings-block .item .edit-btn {
  color: #00a859;
  display: block;
  font-weight: 700;
  cursor: pointer;
  padding: 0 10px;
  font-size: 25px;
  line-height: 0.8;
  transition: all 0.3s ease 0s;
}
.profile-settings .settings-block .item .two-btn .btn,
.active-jobs .settings-block .item .two-btn .btn {
  width: 45%;
}
.profile-settings .settings-block .item .radio-right,
.active-jobs .settings-block .item .radio-right {
  padding-left: 20px;
  border: none;
  box-shadow: none;
}
.profile-settings .settings-block .item .avatar-profile,
.active-jobs .settings-block .item .avatar-profile {
  margin: 0 10px 10px;
  height: 60px;
  width: 60px;
}
.profile-settings .settings-block .item .avatar-profile img,
.active-jobs .settings-block .item .avatar-profile img {
  height: 60px;
  width: 60px;
}
.profile-settings .settings-block .item .upload-logo-wrapper,
.active-jobs .settings-block .item .upload-logo-wrapper {
  display: inline-block;
  margin-left: 10px;
}
.profile-settings .settings-block .item .check,
.active-jobs .settings-block .item .check {
  margin-bottom: 15px;
}
.profile-settings .settings-block .item .check label,
.active-jobs .settings-block .item .check label {
  line-height: 18px;
  width: 80%;
}
.active-jobs {
  margin: 40px 0;
}
.active-jobs .menu-aside {
  position: static;
}
.language-formset .form-group {
  display: inline-block;
  vertical-align: top;
  margin: 0 1%;
  width: 47%;
}
.fieldset .field-errors,
.fieldset .errors {
  position: absolute;
  bottom: -15px;
  left: 19px;
}
@media (max-width: 1200px) {
  .fieldset .field-errors,
  .fieldset .errors {
    bottom: -20px;
  }
}
#edit-certificates .form-group .col-sm-12.field-wrapper {
  margin-top: 20px;
}
#edit-certificates .line {
  top: 43%;
}
.modal-footer .pull-left {
  position: relative;
}
.full-avatar-url {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.full-avatar-url .cropit-image-input,
.full-avatar-url .cropit-image-input-ad {
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 0;
  height: 100%;
}
.message-chat-wrapper {
  background-color: #fff;
  border: 1px solid #2c3e50;
  margin: 40px 0 80px;
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
}
.message-chat-wrapper ul {
  padding: 0;
  margin: 0;
}
.message-chat-wrapper ul li {
  list-style: none;
}
.message-chat-wrapper .last-message,
.message-chat-wrapper .message {
  font-family: 'Didact Gothic', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #959595;
  line-height: 20px;
  white-space: pre-line;
  word-break: break-word;
}
.message-chat-wrapper .name {
  color: #252525;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  margin: 0 0 3px;
  text-overflow: ellipsis;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
}
.message-chat-wrapper .timestamp {
  font-family: 'Didact Gothic', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #959595;
  line-height: 20px;
  white-space: pre-line;
  word-break: break-word;
  max-height: 42px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
.message-chat-wrapper .last-message {
  max-height: 42px;
  overflow: hidden;
}
.message-chat-wrapper .message-list-wrapper {
  float: left;
  display: table-cell;
  vertical-align: top;
  width: 35%;
}
.message-chat-wrapper .message-list-wrapper .header {
  background-color: #2c3e50;
  padding: 20px 32px 5px;
}
.message-chat-wrapper .message-list-wrapper .header .action-header .dropdown-toggle {
  color: #feffff;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
}
.message-chat-wrapper .message-list-wrapper .header .messages-search {
  display: block;
  margin: 10px 0;
}
.message-chat-wrapper .message-list-wrapper .header .messages-search .form-control {
  border-right: none;
}
.message-chat-wrapper .message-list-wrapper .header .messages-search .btn {
  border-left: none;
  box-shadow: none;
  padding-bottom: 5px;
  height: 34px;
}
.message-chat-wrapper .message-list-wrapper .header .messages-search .btn .glyphicon {
  color: #2c3e50;
}
.message-chat-wrapper .message-list-wrapper .header .messages-search .btn:hover {
  background: #fff;
}
.message-chat-wrapper .message-list-wrapper .message-list {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 575px;
}
.message-chat-wrapper .message-list-wrapper .message-list .mark-as-unread {
  background-color: #2c3e50;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -18px;
  top: 50%;
  margin-top: -5px;
  height: 9px;
  width: 9px;
}
.message-chat-wrapper .message-list-wrapper .message-list li a {
  border-bottom: 1px solid #ebebeb;
  display: block;
  text-decoration: none;
  padding: 13px 30px 6px;
}
.message-chat-wrapper .message-list-wrapper .message-list li a.active {
  background: #00a859;
  color: #fff;
}
.message-chat-wrapper .message-list-wrapper .message-list li a.active .name,
.message-chat-wrapper .message-list-wrapper .message-list li a.active .last-message,
.message-chat-wrapper .message-list-wrapper .message-list li a.active .timestamp {
  color: #fff;
}
.message-chat-wrapper .message-list-wrapper .message-list li a.active .mark-as-unread {
  background-color: #fff;
}
.message-chat-wrapper .message-list-wrapper .message-list .media {
  overflow: visible;
}
.message-chat-wrapper .message-list-wrapper .message-list .small-img {
  height: 48px;
  width: 48px;
}
.message-chat-wrapper .message-list-wrapper .message-list .media-middle {
  position: relative;
}
.message-chat-wrapper .message-list-wrapper .message-list .media-body {
  position: relative;
  padding-top: 17px;
}
.message-chat-wrapper .message-list-wrapper .message-list .count {
  background: #2c3e50;
  border: 2px solid #fff;
  border-radius: 50%;
  display: block;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  line-height: 52px;
  margin: -50px 0 0 20px;
  position: relative;
  height: 52px;
  width: 52px;
}
.message-chat-wrapper .message-list-wrapper .message-list .count span {
  font-size: 14px;
  position: relative;
  top: -2px;
}
.message-chat-wrapper .chat-wrapper {
  border-left: 2px solid #ebebeb;
  display: inline-block;
  vertical-align: top;
  width: 65%;
}
.message-chat-wrapper .chat-wrapper .header {
  background-color: #2c3e50;
  padding: 6px 30px 10px;
  color: #fff;
}
.message-chat-wrapper .chat-wrapper .header .select2 {
  color: #333;
  padding-top: 5px !important;
}
.message-chat-wrapper .chat-wrapper .header .settings-show {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.message-chat-wrapper .chat-wrapper .header .message-list-show,
.message-chat-wrapper .chat-wrapper .header .chat-list-show {
  font-size: 11px;
  color: #fff;
  position: absolute;
  top: 40%;
  left: 10px;
}
.message-chat-wrapper .chat-wrapper .header .media {
  overflow: visible;
  position: relative;
}
.message-chat-wrapper .chat-wrapper .header .media .media-body {
  padding-top: 17px;
}
.message-chat-wrapper .chat-wrapper .header .type {
  background: #1a1a1a;
  border-radius: 6px;
  color: #fafafa;
  font-family: 'Didact Gothic', sans-serif;
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  display: inline-block;
  padding: 5px 20px;
  margin: 5px 0 7px;
}
.message-chat-wrapper .chat-wrapper .header .btn.btn-default.btn-circle {
  color: #fff;
  margin-top: -9px;
}
.message-chat-wrapper .chat-wrapper .header .btn.btn-default.btn-circle span {
  font-size: 27px;
  letter-spacing: 4px;
}
.message-chat-wrapper .chat-wrapper .header .btn.btn-default.btn-circle:hover,
.message-chat-wrapper .chat-wrapper .header .btn.btn-default.btn-circle:active {
  color: #000;
}
.message-chat-wrapper .chat-wrapper .header .name {
  color: #fff;
}
.message-chat-wrapper .chat-wrapper .header .compose_wrapper {
  margin-top: 20px;
}
.message-chat-wrapper .chat-wrapper .chat-list {
  overflow-x: hidden;
  overflow-y: auto;
  height: 457px;
}
.message-chat-wrapper .chat-wrapper .chat-list ul > li {
  padding: 20px 30px;
}
.message-chat-wrapper .chat-wrapper .chat-list .message {
  background: #F7F7F7;
  border-radius: 0 15px 15px 15px;
  clear: both;
  margin-top: 4px;
  display: inline-block;
  padding: 10px 15px;
  max-width: 80%;
  font-size: 19px;
  line-height: 1.4;
  width: auto;
}
.message-chat-wrapper .chat-wrapper .chat-list .message.pull-right {
  background: #15B4F1;
  border-radius: 15px 0 15px 15px;
  color: #fff;
}
.message-chat-wrapper .chat-wrapper .chat-list .media-body {
  padding-top: 17px;
}
.message-chat-wrapper .chat-wrapper .date-info {
  text-align: center;
  color: #2c3e50;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 15px auto;
}
.message-chat-wrapper .chat-wrapper .date-info span {
  background: #fff;
  padding: 0 15px;
  display: table;
  margin: -7px auto 0;
}
.message-chat-wrapper .chat-wrapper .date-info:before {
  background-color: #ebebeb;
  content: "";
  width: 100%;
  display: block;
  margin: 0 auto;
  height: 1px;
}
.message-chat-wrapper .chat-wrapper .media-body {
  position: relative;
}
.message-chat-wrapper .chat-wrapper .footer {
  background-color: #2c3e50;
  padding: 23px 30px 0;
}
.message-chat-wrapper .chat-wrapper .footer .media,
.message-chat-wrapper .chat-wrapper .footer .media-body {
  overflow: visible;
}
.message-chat-wrapper .chat-wrapper .footer .message-form {
  position: relative;
}
.message-chat-wrapper .chat-wrapper .footer .message-form textarea {
  resize: none;
  padding-right: 32px;
  height: 100px;
}
.message-chat-wrapper .chat-wrapper .footer .message-form .sticker {
  position: absolute;
  right: 15px;
  top: 15px;
}
.message-chat-wrapper .chat-wrapper .footer .message-form button {
  border: none;
  background: transparent;
}
.message-chat-wrapper .chat-wrapper .footer .attachments-bar {
  font-size: 14px;
  font-weight: 700;
  margin-top: 10px;
}
.message-chat-wrapper .chat-wrapper .footer .attachments-bar label {
  position: relative;
  cursor: pointer;
  float: left;
}
.message-chat-wrapper .chat-wrapper .footer .attachments-bar label input {
  position: absolute;
  visibility: hidden;
}
.message-chat-wrapper .chat-wrapper .footer .attachments-bar label .icon {
  vertical-align: text-bottom;
}
.message-chat-wrapper .chat-wrapper .footer .attachments-bar .pull-right {
  margin-top: 4px;
}
.message-chat-wrapper .conversation-settings {
  position: fixed;
  left: 0;
  right: auto;
  top: 0;
  height: 100%;
  display: block;
  opacity: 0;
  transform: translateX(100%);
  will-change: transform, opacity;
  transition: all 0.3s ease-in-out;
  z-index: 10;
  width: 100%;
}
.message-chat-wrapper .conversation-settings .header {
  background-color: #2c3e50;
  padding: 6px 30px 10px;
  color: #fff;
  padding: 3px 12px 5px 40px;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
}
.message-chat-wrapper .conversation-settings .header .select2 {
  color: #333;
  padding-top: 5px !important;
}
.message-chat-wrapper .conversation-settings .header .settings-show {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.message-chat-wrapper .conversation-settings .header .message-list-show,
.message-chat-wrapper .conversation-settings .header .chat-list-show {
  font-size: 11px;
  color: #fff;
  position: absolute;
  top: 40%;
  left: 10px;
}
.message-chat-wrapper .conversation-settings .header .media {
  overflow: visible;
  position: relative;
}
.message-chat-wrapper .conversation-settings .header .media .media-body {
  padding-top: 17px;
}
.message-chat-wrapper .conversation-settings .header .type {
  background: #1a1a1a;
  border-radius: 6px;
  color: #fafafa;
  font-family: 'Didact Gothic', sans-serif;
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  display: inline-block;
  padding: 5px 20px;
  margin: 5px 0 7px;
}
.message-chat-wrapper .conversation-settings .header .btn.btn-default.btn-circle {
  color: #fff;
  margin-top: -9px;
}
.message-chat-wrapper .conversation-settings .header .btn.btn-default.btn-circle span {
  font-size: 27px;
  letter-spacing: 4px;
}
.message-chat-wrapper .conversation-settings .header .btn.btn-default.btn-circle:hover,
.message-chat-wrapper .conversation-settings .header .btn.btn-default.btn-circle:active {
  color: #000;
}
.message-chat-wrapper .conversation-settings .header .name {
  color: #fff;
}
.message-chat-wrapper .conversation-settings .header .compose_wrapper {
  margin-top: 20px;
}
.message-chat-wrapper .conversation-settings .header a {
  color: #fff;
}
.message-chat-wrapper .conversation-settings .header .media {
  margin-top: 6px;
}
.message-chat-wrapper .conversation-settings .header .media .media-body {
  padding: 11px 0 7px;
}
.message-chat-wrapper .conversation-settings .wrapper {
  padding: 50px 5px 0;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
}
.message-chat-wrapper .conversation-settings .wrapper > .btn {
  margin: 10px 0;
  font-size: 16px;
}
.message-chat-wrapper .conversation-settings .list-group {
  border: 1px solid #2c3e50;
}
.message-chat-wrapper .conversation-settings .list-group .small-img {
  height: 48px;
  width: 48px;
}
.message-chat-wrapper .conversation-settings .list-group .list-group-item,
.message-chat-wrapper .conversation-settings .list-group a {
  border-radius: 0;
  color: #2c3e50;
  font-weight: 600;
  font-size: 16px;
}
.message-chat-wrapper .conversation-settings .list-group .tittle {
  background: #2c3e50;
  border: none;
  font-size: 12px;
  color: #fff;
}
.message-chat-wrapper .conversation-settings .settings-list-links .list-group-item {
  padding-top: 20px;
  padding-bottom: 20px;
}
.message-chat-wrapper .compose_wrapper {
  display: none;
}
.emojioneemoji {
  margin: 0 2px;
  height: 20px;
  width: 20px;
}
.emojionearea .emojionearea-editor {
  min-height: 7em;
  max-height: 7em;
  word-break: break-word;
}
.media-user {
  overflow: visible;
  position: relative;
}
.media-user .media-body {
  padding-top: 17px;
}
.media-user .media-body .name {
  color: #252525;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  margin: 0 0 3px;
  text-overflow: ellipsis;
  position: absolute;
  top: 0;
  overflow: hidden;
  width: 97%;
}
.media-user .media-body .type {
  background: #1a1a1a;
  border-radius: 6px;
  color: #fafafa;
  font-family: 'Didact Gothic', sans-serif;
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  display: inline-block;
  padding: 5px 20px;
  margin: 5px 0 7px;
}
.media-user .media-body .type.red {
  background: #FF0000;
}
.media-user .media-body .type.green {
  background: #00a859;
}
.media-user .media-body .type.yellow {
  background: #eabc33;
}
.media-user .media-body .type.purple {
  background: #800080;
}
.chat-live-fixed {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  right: 10px;
  will-change: transform, opacity;
  transition: all 0.3s ease-in-out;
}
.chat-live-fixed.closed {
  transform: translateY(100%);
}
.chat-live-fixed.closed .btn-chat-live {
  display: inline-block;
}
.chat-live-fixed .new-message,
.chat-live-fixed .add-people-select {
  display: none;
}
.chat-live-fixed .btn.btn-sm {
  color: #333;
}
.chat-live-fixed .chat-live-wrapper-show {
  font-size: 11px;
  color: #fff;
  position: absolute;
  top: 17px;
  left: 10px;
}
.chat-live-fixed .btn-chat-live {
  background: #2c3e50;
  border-radius: 6px 6px 0 0;
  border: 1px solid #fafafa;
  font-weight: 700;
  font-size: 15px;
  color: #fff;
  padding: 11px 30px 8px;
  text-decoration: none;
  display: none;
  position: absolute;
  right: 0;
  top: -40px;
  transition: all 0.3s ease-in-out;
}
.chat-live-fixed .btn-chat-live .icon {
  margin-right: 10px;
  vertical-align: middle;
}
.chat-live-fixed .chat-live-wrapper {
  background-color: #fff;
  border: 1px solid #2c3e50;
  margin: 40px 0 80px;
  overflow: hidden;
  position: relative;
  width: 100%;
  display: block;
  border: none;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  margin: 0;
  float: right;
  opacity: 1;
  will-change: transform, opacity;
  transition: all 0.3s ease-in-out;
}
.chat-live-fixed .chat-live-wrapper ul {
  padding: 0;
  margin: 0;
}
.chat-live-fixed .chat-live-wrapper ul li {
  list-style: none;
}
.chat-live-fixed .chat-live-wrapper .last-message,
.chat-live-fixed .chat-live-wrapper .message {
  font-family: 'Didact Gothic', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #959595;
  line-height: 20px;
  white-space: pre-line;
  word-break: break-word;
}
.chat-live-fixed .chat-live-wrapper .name {
  color: #252525;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  margin: 0 0 3px;
  text-overflow: ellipsis;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
}
.chat-live-fixed .chat-live-wrapper .timestamp {
  font-family: 'Didact Gothic', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #959595;
  line-height: 20px;
  white-space: pre-line;
  word-break: break-word;
  max-height: 42px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
.chat-live-fixed .chat-live-wrapper .last-message {
  max-height: 42px;
  overflow: hidden;
}
.chat-live-fixed .chat-live-wrapper .message-list-wrapper {
  float: left;
  display: table-cell;
  vertical-align: top;
  width: 35%;
}
.chat-live-fixed .chat-live-wrapper .message-list-wrapper .header {
  background-color: #2c3e50;
  padding: 20px 32px 5px;
}
.chat-live-fixed .chat-live-wrapper .message-list-wrapper .header .action-header .dropdown-toggle {
  color: #feffff;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
}
.chat-live-fixed .chat-live-wrapper .message-list-wrapper .header .messages-search {
  display: block;
  margin: 10px 0;
}
.chat-live-fixed .chat-live-wrapper .message-list-wrapper .header .messages-search .form-control {
  border-right: none;
}
.chat-live-fixed .chat-live-wrapper .message-list-wrapper .header .messages-search .btn {
  border-left: none;
  box-shadow: none;
  padding-bottom: 5px;
  height: 34px;
}
.chat-live-fixed .chat-live-wrapper .message-list-wrapper .header .messages-search .btn .glyphicon {
  color: #2c3e50;
}
.chat-live-fixed .chat-live-wrapper .message-list-wrapper .header .messages-search .btn:hover {
  background: #fff;
}
.chat-live-fixed .chat-live-wrapper .message-list-wrapper .message-list {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 575px;
}
.chat-live-fixed .chat-live-wrapper .message-list-wrapper .message-list .mark-as-unread {
  background-color: #2c3e50;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -18px;
  top: 50%;
  margin-top: -5px;
  height: 9px;
  width: 9px;
}
.chat-live-fixed .chat-live-wrapper .message-list-wrapper .message-list li a {
  border-bottom: 1px solid #ebebeb;
  display: block;
  text-decoration: none;
  padding: 13px 30px 6px;
}
.chat-live-fixed .chat-live-wrapper .message-list-wrapper .message-list li a.active {
  background: #00a859;
  color: #fff;
}
.chat-live-fixed .chat-live-wrapper .message-list-wrapper .message-list li a.active .name,
.chat-live-fixed .chat-live-wrapper .message-list-wrapper .message-list li a.active .last-message,
.chat-live-fixed .chat-live-wrapper .message-list-wrapper .message-list li a.active .timestamp {
  color: #fff;
}
.chat-live-fixed .chat-live-wrapper .message-list-wrapper .message-list li a.active .mark-as-unread {
  background-color: #fff;
}
.chat-live-fixed .chat-live-wrapper .message-list-wrapper .message-list .media {
  overflow: visible;
}
.chat-live-fixed .chat-live-wrapper .message-list-wrapper .message-list .small-img {
  height: 48px;
  width: 48px;
}
.chat-live-fixed .chat-live-wrapper .message-list-wrapper .message-list .media-middle {
  position: relative;
}
.chat-live-fixed .chat-live-wrapper .message-list-wrapper .message-list .media-body {
  position: relative;
  padding-top: 17px;
}
.chat-live-fixed .chat-live-wrapper .message-list-wrapper .message-list .count {
  background: #2c3e50;
  border: 2px solid #fff;
  border-radius: 50%;
  display: block;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  line-height: 52px;
  margin: -50px 0 0 20px;
  position: relative;
  height: 52px;
  width: 52px;
}
.chat-live-fixed .chat-live-wrapper .message-list-wrapper .message-list .count span {
  font-size: 14px;
  position: relative;
  top: -2px;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper {
  border-left: 2px solid #ebebeb;
  display: inline-block;
  vertical-align: top;
  width: 65%;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .header {
  background-color: #2c3e50;
  padding: 6px 30px 10px;
  color: #fff;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .header .select2 {
  color: #333;
  padding-top: 5px !important;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .header .settings-show {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .header .message-list-show,
.chat-live-fixed .chat-live-wrapper .chat-wrapper .header .chat-list-show {
  font-size: 11px;
  color: #fff;
  position: absolute;
  top: 40%;
  left: 10px;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .header .media {
  overflow: visible;
  position: relative;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .header .media .media-body {
  padding-top: 17px;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .header .type {
  background: #1a1a1a;
  border-radius: 6px;
  color: #fafafa;
  font-family: 'Didact Gothic', sans-serif;
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  display: inline-block;
  padding: 5px 20px;
  margin: 5px 0 7px;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .header .btn.btn-default.btn-circle {
  color: #fff;
  margin-top: -9px;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .header .btn.btn-default.btn-circle span {
  font-size: 27px;
  letter-spacing: 4px;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .header .btn.btn-default.btn-circle:hover,
.chat-live-fixed .chat-live-wrapper .chat-wrapper .header .btn.btn-default.btn-circle:active {
  color: #000;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .header .name {
  color: #fff;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .header .compose_wrapper {
  margin-top: 20px;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .chat-list {
  overflow-x: hidden;
  overflow-y: auto;
  height: 457px;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .chat-list ul > li {
  padding: 20px 30px;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .chat-list .message {
  background: #F7F7F7;
  border-radius: 0 15px 15px 15px;
  clear: both;
  margin-top: 4px;
  display: inline-block;
  padding: 10px 15px;
  max-width: 80%;
  font-size: 19px;
  line-height: 1.4;
  width: auto;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .chat-list .message.pull-right {
  background: #15B4F1;
  border-radius: 15px 0 15px 15px;
  color: #fff;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .chat-list .media-body {
  padding-top: 17px;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .date-info {
  text-align: center;
  color: #2c3e50;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 15px auto;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .date-info span {
  background: #fff;
  padding: 0 15px;
  display: table;
  margin: -7px auto 0;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .date-info:before {
  background-color: #ebebeb;
  content: "";
  width: 100%;
  display: block;
  margin: 0 auto;
  height: 1px;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .media-body {
  position: relative;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .footer {
  background-color: #2c3e50;
  padding: 23px 30px 0;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .footer .media,
.chat-live-fixed .chat-live-wrapper .chat-wrapper .footer .media-body {
  overflow: visible;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .footer .message-form {
  position: relative;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .footer .message-form textarea {
  resize: none;
  padding-right: 32px;
  height: 100px;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .footer .message-form .sticker {
  position: absolute;
  right: 15px;
  top: 15px;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .footer .message-form button {
  border: none;
  background: transparent;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .footer .attachments-bar {
  font-size: 14px;
  font-weight: 700;
  margin-top: 10px;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .footer .attachments-bar label {
  position: relative;
  cursor: pointer;
  float: left;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .footer .attachments-bar label input {
  position: absolute;
  visibility: hidden;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .footer .attachments-bar label .icon {
  vertical-align: text-bottom;
}
.chat-live-fixed .chat-live-wrapper .chat-wrapper .footer .attachments-bar .pull-right {
  margin-top: 4px;
}
.chat-live-fixed .chat-live-wrapper .conversation-settings {
  position: fixed;
  left: 0;
  right: auto;
  top: 0;
  height: 100%;
  display: block;
  opacity: 0;
  transform: translateX(100%);
  will-change: transform, opacity;
  transition: all 0.3s ease-in-out;
  z-index: 10;
  width: 100%;
}
.chat-live-fixed .chat-live-wrapper .conversation-settings .header {
  background-color: #2c3e50;
  padding: 6px 30px 10px;
  color: #fff;
  padding: 3px 12px 5px 40px;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
}
.chat-live-fixed .chat-live-wrapper .conversation-settings .header .select2 {
  color: #333;
  padding-top: 5px !important;
}
.chat-live-fixed .chat-live-wrapper .conversation-settings .header .settings-show {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.chat-live-fixed .chat-live-wrapper .conversation-settings .header .message-list-show,
.chat-live-fixed .chat-live-wrapper .conversation-settings .header .chat-list-show {
  font-size: 11px;
  color: #fff;
  position: absolute;
  top: 40%;
  left: 10px;
}
.chat-live-fixed .chat-live-wrapper .conversation-settings .header .media {
  overflow: visible;
  position: relative;
}
.chat-live-fixed .chat-live-wrapper .conversation-settings .header .media .media-body {
  padding-top: 17px;
}
.chat-live-fixed .chat-live-wrapper .conversation-settings .header .type {
  background: #1a1a1a;
  border-radius: 6px;
  color: #fafafa;
  font-family: 'Didact Gothic', sans-serif;
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  display: inline-block;
  padding: 5px 20px;
  margin: 5px 0 7px;
}
.chat-live-fixed .chat-live-wrapper .conversation-settings .header .btn.btn-default.btn-circle {
  color: #fff;
  margin-top: -9px;
}
.chat-live-fixed .chat-live-wrapper .conversation-settings .header .btn.btn-default.btn-circle span {
  font-size: 27px;
  letter-spacing: 4px;
}
.chat-live-fixed .chat-live-wrapper .conversation-settings .header .btn.btn-default.btn-circle:hover,
.chat-live-fixed .chat-live-wrapper .conversation-settings .header .btn.btn-default.btn-circle:active {
  color: #000;
}
.chat-live-fixed .chat-live-wrapper .conversation-settings .header .name {
  color: #fff;
}
.chat-live-fixed .chat-live-wrapper .conversation-settings .header .compose_wrapper {
  margin-top: 20px;
}
.chat-live-fixed .chat-live-wrapper .conversation-settings .header a {
  color: #fff;
}
.chat-live-fixed .chat-live-wrapper .conversation-settings .header .media {
  margin-top: 6px;
}
.chat-live-fixed .chat-live-wrapper .conversation-settings .header .media .media-body {
  padding: 11px 0 7px;
}
.chat-live-fixed .chat-live-wrapper .conversation-settings .wrapper {
  padding: 50px 5px 0;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;
}
.chat-live-fixed .chat-live-wrapper .conversation-settings .wrapper > .btn {
  margin: 10px 0;
  font-size: 16px;
}
.chat-live-fixed .chat-live-wrapper .conversation-settings .list-group {
  border: 1px solid #2c3e50;
}
.chat-live-fixed .chat-live-wrapper .conversation-settings .list-group .small-img {
  height: 48px;
  width: 48px;
}
.chat-live-fixed .chat-live-wrapper .conversation-settings .list-group .list-group-item,
.chat-live-fixed .chat-live-wrapper .conversation-settings .list-group a {
  border-radius: 0;
  color: #2c3e50;
  font-weight: 600;
  font-size: 16px;
}
.chat-live-fixed .chat-live-wrapper .conversation-settings .list-group .tittle {
  background: #2c3e50;
  border: none;
  font-size: 12px;
  color: #fff;
}
.chat-live-fixed .chat-live-wrapper .conversation-settings .settings-list-links .list-group-item {
  padding-top: 20px;
  padding-bottom: 20px;
}
.chat-live-fixed .chat-live-wrapper .compose_wrapper {
  display: none;
}
.chat-live-fixed .chat-live-wrapper > .close {
  color: #fff;
  opacity: 1;
  margin-left: 15px;
  font-weight: 100;
  position: absolute;
  right: 11px;
  top: 12px;
  z-index: 1000;
}
.chat-live-fixed .chat-live-wrapper ul {
  margin: 0 0 10px 0;
}
.chat-live-fixed .chat-live-wrapper .header {
  padding: 16px 22px 4px 30px!important;
  position: relative;
  max-height: 100%;
}
.chat-live-fixed .chat-live-wrapper .header .name {
  position: static;
  text-align: left;
  color: #fff;
  float: left;
}
.chat-live-fixed .chat-live-wrapper .header .btn.btn-default.btn-circle {
  background: none!important;
  border: none!important;
  width: 34px;
  height: 34px;
}
.chat-live-fixed .chat-live-wrapper .header .btn.btn-default.btn-circle:hover,
.chat-live-fixed .chat-live-wrapper .header .btn.btn-default.btn-circle:active,
.chat-live-fixed .chat-live-wrapper .header .btn.btn-default.btn-circle:focus {
  color: #fff;
}
.chat-live-fixed .chat-live-wrapper .header .btn.btn-default.btn-circle span {
  font-size: 19px;
  color: #fff;
}
.chat-live-fixed .chat-live-wrapper .header .form-group {
  margin-top: 15px;
}
.chat-live-fixed .chat-live-wrapper .header .btn-group {
  margin: -11px 12px 8px 0;
}
.chat-live-fixed .chat-live-wrapper .header .compose-btn {
  margin: -3px 14px 3px 0;
  display: block;
}
.chat-live-fixed .chat-live-wrapper .message-list-wrapper {
  max-width: 370px;
  width: 100%;
  will-change: transform, opacity;
  transition: all 0.3s ease-in-out;
}
.chat-live-fixed .chat-live-wrapper .message-list-wrapper .message-list {
  max-height: 292px;
  min-height: 292px;
}
.chat-live-fixed .chat-live-wrapper .message-list-wrapper .message-list ul {
  padding: 0 0 57px 0;
}
.chat-live-fixed .chat-live-wrapper .message-list-wrapper .message-list .see-all-conversations {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  background: #2c3e50;
  color: #fff;
  padding: 25px 0 17px;
  text-decoration: none;
  font-size: 15px;
  z-index: 100;
}
.chat-live-fixed .chat-live-wrapper .message-list-wrapper.closed {
  opacity: 0;
  position: absolute;
  transform: translateX(-100%);
}
.chat-live-fixed .chat-live-wrapper .chat-live {
  border-left: 2px solid #ebebeb;
  display: inline-block;
  vertical-align: top;
  width: 65%;
  max-width: 370px;
  margin-left: 0;
  width: 100%;
  border: none;
  will-change: transform, opacity;
  transition: all 0.3s ease-in-out;
}
.chat-live-fixed .chat-live-wrapper .chat-live .header {
  background-color: #2c3e50;
  padding: 6px 30px 10px;
  color: #fff;
}
.chat-live-fixed .chat-live-wrapper .chat-live .header .select2 {
  color: #333;
  padding-top: 5px !important;
}
.chat-live-fixed .chat-live-wrapper .chat-live .header .settings-show {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.chat-live-fixed .chat-live-wrapper .chat-live .header .message-list-show,
.chat-live-fixed .chat-live-wrapper .chat-live .header .chat-list-show {
  font-size: 11px;
  color: #fff;
  position: absolute;
  top: 40%;
  left: 10px;
}
.chat-live-fixed .chat-live-wrapper .chat-live .header .media {
  overflow: visible;
  position: relative;
}
.chat-live-fixed .chat-live-wrapper .chat-live .header .media .media-body {
  padding-top: 17px;
}
.chat-live-fixed .chat-live-wrapper .chat-live .header .type {
  background: #1a1a1a;
  border-radius: 6px;
  color: #fafafa;
  font-family: 'Didact Gothic', sans-serif;
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  display: inline-block;
  padding: 5px 20px;
  margin: 5px 0 7px;
}
.chat-live-fixed .chat-live-wrapper .chat-live .header .btn.btn-default.btn-circle {
  color: #fff;
  margin-top: -9px;
}
.chat-live-fixed .chat-live-wrapper .chat-live .header .btn.btn-default.btn-circle span {
  font-size: 27px;
  letter-spacing: 4px;
}
.chat-live-fixed .chat-live-wrapper .chat-live .header .btn.btn-default.btn-circle:hover,
.chat-live-fixed .chat-live-wrapper .chat-live .header .btn.btn-default.btn-circle:active {
  color: #000;
}
.chat-live-fixed .chat-live-wrapper .chat-live .header .name {
  color: #fff;
}
.chat-live-fixed .chat-live-wrapper .chat-live .header .compose_wrapper {
  margin-top: 20px;
}
.chat-live-fixed .chat-live-wrapper .chat-live .chat-list {
  overflow-x: hidden;
  overflow-y: auto;
  height: 457px;
}
.chat-live-fixed .chat-live-wrapper .chat-live .chat-list ul > li {
  padding: 20px 30px;
}
.chat-live-fixed .chat-live-wrapper .chat-live .chat-list .message {
  background: #F7F7F7;
  border-radius: 0 15px 15px 15px;
  clear: both;
  margin-top: 4px;
  display: inline-block;
  padding: 10px 15px;
  max-width: 80%;
  font-size: 19px;
  line-height: 1.4;
  width: auto;
}
.chat-live-fixed .chat-live-wrapper .chat-live .chat-list .message.pull-right {
  background: #15B4F1;
  border-radius: 15px 0 15px 15px;
  color: #fff;
}
.chat-live-fixed .chat-live-wrapper .chat-live .chat-list .media-body {
  padding-top: 17px;
}
.chat-live-fixed .chat-live-wrapper .chat-live .date-info {
  text-align: center;
  color: #2c3e50;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 15px auto;
}
.chat-live-fixed .chat-live-wrapper .chat-live .date-info span {
  background: #fff;
  padding: 0 15px;
  display: table;
  margin: -7px auto 0;
}
.chat-live-fixed .chat-live-wrapper .chat-live .date-info:before {
  background-color: #ebebeb;
  content: "";
  width: 100%;
  display: block;
  margin: 0 auto;
  height: 1px;
}
.chat-live-fixed .chat-live-wrapper .chat-live .media-body {
  position: relative;
}
.chat-live-fixed .chat-live-wrapper .chat-live .footer {
  background-color: #2c3e50;
  padding: 23px 30px 0;
}
.chat-live-fixed .chat-live-wrapper .chat-live .footer .media,
.chat-live-fixed .chat-live-wrapper .chat-live .footer .media-body {
  overflow: visible;
}
.chat-live-fixed .chat-live-wrapper .chat-live .footer .message-form {
  position: relative;
}
.chat-live-fixed .chat-live-wrapper .chat-live .footer .message-form textarea {
  resize: none;
  padding-right: 32px;
  height: 100px;
}
.chat-live-fixed .chat-live-wrapper .chat-live .footer .message-form .sticker {
  position: absolute;
  right: 15px;
  top: 15px;
}
.chat-live-fixed .chat-live-wrapper .chat-live .footer .message-form button {
  border: none;
  background: transparent;
}
.chat-live-fixed .chat-live-wrapper .chat-live .footer .attachments-bar {
  font-size: 14px;
  font-weight: 700;
  margin-top: 10px;
}
.chat-live-fixed .chat-live-wrapper .chat-live .footer .attachments-bar label {
  position: relative;
  cursor: pointer;
  float: left;
}
.chat-live-fixed .chat-live-wrapper .chat-live .footer .attachments-bar label input {
  position: absolute;
  visibility: hidden;
}
.chat-live-fixed .chat-live-wrapper .chat-live .footer .attachments-bar label .icon {
  vertical-align: text-bottom;
}
.chat-live-fixed .chat-live-wrapper .chat-live .footer .attachments-bar .pull-right {
  margin-top: 4px;
}
.chat-live-fixed .chat-live-wrapper .chat-live .new-message-name {
  display: none;
}
.chat-live-fixed .chat-live-wrapper .chat-live.closed {
  opacity: 0;
  position: absolute;
  transform: translateX(100%);
}
.chat-live-fixed .chat-live-wrapper .chat-live.compose .chat-list ul,
.chat-live-fixed .chat-live-wrapper .chat-live.compose .baron__track,
.chat-live-fixed .chat-live-wrapper .chat-live.compose .new-message-name + .name {
  display: none;
}
.chat-live-fixed .chat-live-wrapper .chat-live.compose .new-message-name {
  position: static;
  text-align: left;
  color: #fff;
  float: left;
  max-width: 74%;
}
.chat-live-fixed .chat-live-wrapper .chat-live.compose .new-message,
.chat-live-fixed .chat-live-wrapper .chat-live.compose .new-message-name {
  display: block;
}
.chat-live-fixed .chat-live-wrapper .chat-live.compose .chat-list {
  height: 123px;
}
.chat-live-fixed .chat-live-wrapper .chat-live.compose .chat-list .message {
  font-style: 13px;
}
.chat-live-fixed .chat-live-wrapper .chat-live.more-people .add-people-select {
  display: block;
}
.chat-live-fixed .chat-live-wrapper .chat-live.more-people .btn-group {
  display: none;
}
.chat-live-fixed .chat-live-wrapper .chat-live.more-people .chat-list {
  height: 100px;
}
.chat-live-fixed .chat-live-wrapper .chat-live .header .btn.btn-default.btn-circle {
  background: none!important;
  border: none!important;
  width: 34px;
  height: 34px;
  margin-top: -3px;
}
.chat-live-fixed .chat-live-wrapper .chat-live .header .btn.btn-default.btn-circle:hover,
.chat-live-fixed .chat-live-wrapper .chat-live .header .btn.btn-default.btn-circle:active,
.chat-live-fixed .chat-live-wrapper .chat-live .header .btn.btn-default.btn-circle:focus {
  color: #fff;
}
.chat-live-fixed .chat-live-wrapper .chat-live .header .btn.btn-default.btn-circle span {
  font-size: 19px;
}
.chat-live-fixed .chat-live-wrapper .chat-live .date-info:before {
  width: 100%;
}
.chat-live-fixed .chat-live-wrapper .chat-live .footer {
  background-color: #2c3e50;
  padding: 7px 10px 0;
}
.chat-live-fixed .chat-live-wrapper .chat-live .footer .message-form textarea,
.chat-live-fixed .chat-live-wrapper .chat-live .footer .message-form .emojionearea .emojionearea-editor {
  height: 50px;
  font-size: 12px;
  min-height: 100%;
}
.chat-live-fixed .chat-live-wrapper .chat-live .footer .attachments-bar {
  font-size: 11px;
}
.chat-live-fixed .chat-live-wrapper .chat-live .footer .attachments-bar label {
  margin-right: 10px;
}
.chat-live-fixed .chat-live-wrapper .chat-live .footer .attachments-bar .close {
  color: #333;
}
.chat-live-fixed .chat-live-wrapper .chat-live .chat-list {
  height: 193px;
  width: 370px;
}
.chat-live-fixed .chat-live-wrapper .chat-live .chat-list .message {
  max-width: 100%;
  font-size: 16px;
}
.chat-live-fixed .chat-live-wrapper .chat-live .chat-list .small-img {
  overflow: hidden;
  height: 45px;
  width: 45px;
}
.chat-live-fixed .chat-live-wrapper .chat-live .chat-list ul > li {
  padding: 10px 0 0;
}
.chat-live-fixed .chat-live-wrapper .chat-live .chat-list ul > li .media {
  padding: 0 13px;
  position: relative;
}
.chat-live-fixed .chat-live-wrapper .chat-live .chat-list ul > li .media .media-body {
  padding-left: 0;
  padding-top: 19px;
}
.chat-live-fixed .chat-live-wrapper .chat-live .chat-list ul > li .media .media-body .name {
  top: 3px;
}
.chat-live-fixed .chat-live-wrapper .chat-live .chat-list ul > li .media .media-body .name.text-right {
  max-width: 86%;
}
.chat-live-fixed .chat-live-wrapper .chat-live .chat-list ul > li .media .media-body .timestamp {
  float: right;
  margin-top: -3px;
}
.chat-live-fixed .chat-live-wrapper .chat-live .chat-list ul > li .message-attach-file {
  padding-bottom: 10px;
}
.chat-live-fixed .select2 {
  margin: 0 0 5px;
}
.chat-live-fixed .select2-container--default .select2-selection--multiple .select2-selection__choice {
  display: block;
  background: #1a1a1a;
  font-weight: 600;
  color: #fff;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-align: center;
  vertical-align: middle;
  border-radius: 4px;
  margin: 2px 2px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-menu.dropdown-menu-right:after {
  content: '';
  position: absolute;
  top: -6px;
  right: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}
.list-group-people {
  overflow: hidden;
  max-height: 140px;
  transition: max-height 0.15s ease-out;
}
.list-group-people.open {
  max-height: 500px;
  transition: max-height 0.25s ease-in;
}
.mobile-version .chat-live-fixed {
  display: none;
}
.attachment-file-wrapper {
  display: none;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border-radius: 3px;
  position: relative;
  clear: both;
  margin: 27px 0 10px;
  padding: 7px 20px 7px 5px;
  width: 260px;
}
.attachment-file-wrapper .top-right {
  position: absolute;
  right: 5px;
  top: 5px;
}
.attachment-file-wrapper .top-right .close {
  opacity: 1;
  font-size: 18px;
  font-weight: 100;
}
.attachment-file-wrapper .top-right .ajax-loader {
  width: 15px;
  height: 15px;
  position: absolute;
  left: -4px;
}
.message-attach-file {
  clear: both;
  display: table;
  background: #F7F7F7;
  margin-top: 4px;
  border-radius: 0 15px 15px;
  padding: 10px 15px;
  color: #333;
}
.message-attach-file a {
  color: #333;
}
.message-attach-file > div {
  display: inline-block;
}
.message-attach-file > div > * {
  display: block;
}
.message-attach-file > div .name-file {
  white-space: nowrap;
  max-width: 143px;
  display: inline-block;
  text-overflow: ellipsis;
  margin: 0 -3px -2px 0;
  overflow: hidden;
}
.message-attach-file > div .size-file {
  color: #959595;
  margin: 3px 0;
  font-weight: 100;
}
.message-attach-file.pull-right {
  background: #15B4F1;
  border-radius: 15px 0 15px 15px;
  color: #fff;
}
.message-attach-file.pull-right a {
  color: #fff;
}
.message-attach-file.pull-right > div .size-file {
  color: #fff;
}
.icon-type {
  background-image: url(../images/types_files.png);
  width: 42px;
  height: 42px;
  display: inline-block;
  vertical-align: middle;
  float: left;
  margin-right: 10px;
}
.icon-type.docx {
  background-position: 0 0;
}
.icon-type.doc {
  background-position: -42px 0;
}
.icon-type.jpg {
  background-position: -84px 0;
}
.icon-type.png {
  background-position: -126px 0;
}
.icon-type.jpeg {
  background-position: -168px 0;
}
.icon-type.pdf {
  background-position: 0 -42px;
}
.icon-type.xls {
  background-position: -42px -42px;
}
.icon-type.xlsx {
  background-position: -84px -42px;
}
.bell-anime {
  background: red;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  top: -12px;
  right: 10px;
  height: 25px;
  width: 25px;
}
.bell-anime.off {
  display: none;
}
.bell-anime.bounceIn {
  animation: bounceIn 0.7s linear;
}
.bell-anime:after {
  background: red;
  border-radius: 50%;
  content: "";
  display: block;
  left: 0;
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  animation: burst 5s 1s linear infinite;
}
.bell-anime img {
  margin: 0 auto;
  padding-top: 7px;
  width: 10px;
  animation: ring 5s 1s ease-in-out infinite;
  transform-origin: 50% 10px;
  position: relative;
  z-index: 1;
}
@keyframes ring {
  0% {
    transform: rotate(0);
  }
  9% {
    transform: rotate(10deg);
  }
  13% {
    transform: rotate(-13deg);
  }
  17% {
    transform: rotate(15deg);
  }
  21% {
    transform: rotate(-17deg);
  }
  25% {
    transform: rotate(15deg);
  }
  29% {
    transform: rotate(-13deg);
  }
  33% {
    transform: rotate(11deg);
  }
  37% {
    transform: rotate(-19deg);
  }
  41% {
    transform: rotate(17deg);
  }
  45% {
    transform: rotate(-15deg);
  }
  49% {
    transform: rotate(13deg);
  }
  53% {
    transform: rotate(-11deg);
  }
  57% {
    transform: rotate(9deg);
  }
  61% {
    transform: rotate(-7deg);
  }
  65% {
    transform: rotate(5deg);
  }
  69% {
    transform: rotate(-3deg);
  }
  73% {
    transform: rotate(1deg);
  }
  77% {
    transform: rotate(1deg);
  }
  93% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
@-webkit-keyframes ring {
  0% {
    transform: rotate(0);
  }
  9% {
    transform: rotate(10deg);
  }
  13% {
    transform: rotate(-13deg);
  }
  17% {
    transform: rotate(15deg);
  }
  21% {
    transform: rotate(-17deg);
  }
  25% {
    transform: rotate(15deg);
  }
  29% {
    transform: rotate(-13deg);
  }
  33% {
    transform: rotate(11deg);
  }
  37% {
    transform: rotate(-19deg);
  }
  41% {
    transform: rotate(17deg);
  }
  45% {
    transform: rotate(-15deg);
  }
  49% {
    transform: rotate(13deg);
  }
  53% {
    transform: rotate(-11deg);
  }
  57% {
    transform: rotate(9deg);
  }
  61% {
    transform: rotate(-7deg);
  }
  65% {
    transform: rotate(5deg);
  }
  69% {
    transform: rotate(-3deg);
  }
  73% {
    transform: rotate(1deg);
  }
  77% {
    transform: rotate(1deg);
  }
  93% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
@keyframes burst {
  0% {
    opacity: 0.5;
  }
  10% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes burst {
  0% {
    opacity: 0.5;
  }
  10% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  20% {
    transform: scale(0.6);
  }
  30% {
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  70% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  20% {
    transform: scale(0.6);
  }
  30% {
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
  70% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.post-wrapper h2 {
  margin: 10px 0;
}
.post-wrapper .post-item-wrapper:nth-child(3n-2) .btn {
  background-color: #1a1a1a;
  border-color: #1a1a1a;
  color: #fff;
}
.post-wrapper .post-item-wrapper:nth-child(3n-1) .btn {
  background-color: #00a859;
  border-color: #00a859;
  color: #fff;
}
.post-wrapper .post-item-wrapper:nth-child(3n) .btn {
  background-color: red;
  border-color: red;
  color: #fff;
}
.post-wrapper .post-item {
  padding-bottom: 30px;
  margin-right: 15px;
}
.post-wrapper .post-item + .post-item {
  border-top: 1px solid #2c3e50;
  padding-top: 30px;
}
.post-wrapper .post-item .post-image img {
  display: block;
  width: 100%;
}
.post-wrapper .post-item .title {
  display: block;
  color: #252525;
  margin: 30px 0 15px;
  font-size: 27px;
  font-weight: 700;
}
.post-wrapper .post-item .post-short-entry {
  margin: 20px 0;
  color: #7b7b7b;
  font-family: 'Didact Gothic', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.post-wrapper .post-item .post-entry {
  margin: 20px 0;
  color: #7b7b7b;
  font-family: 'Didact Gothic', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.post-wrapper .post-item .btn {
  padding: 8px 30px 5px;
  border-radius: 8px;
  margin-top: 16px;
}
.timestamp {
  font-size: 12px;
}
.post-info {
  font-size: 13px;
}
.post-info .icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 14px;
}
.post-info > span {
  margin-right: 25px;
}
.post-sidebar-wrapper {
  margin-bottom: 15px;
}
.post-sidebar-wrapper .item > .title {
  border-bottom: 1px solid #2c3e50;
  font-size: 15px;
  font-weight: 700;
  padding: 10px 0;
  margin-bottom: 15px;
  text-transform: uppercase;
  text-align: center;
}
.post-sidebar-wrapper .item .recent-comments p {
  font-family: 'Didact Gothic', sans-serif;
  line-height: 18px;
  margin-bottom: 15px;
}
.list-popular-posts {
  margin: 0;
  padding: 0;
}
.list-popular-posts li {
  list-style: none;
  padding-bottom: 15px;
}
.list-popular-posts li + li {
  border-top: 1px solid #ebebeb;
  padding-top: 15px;
}
.list-popular-posts li .media-object {
  width: 100px;
  height: 70px;
}
.list-popular-posts li .media-object img {
  height: auto;
  width: 100%;
}
.list-popular-posts li .title {
  color: #252525;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
.add-comment-wrapper .title {
  margin: 0 0 30px;
}
.add-comment-wrapper .add-comment-form textarea {
  padding: 20px;
  min-height: 130px;
  resize: none;
}
.add-comment-wrapper .add-comment-form .btn {
  margin: 20px 0 40px;
  height: 44px;
  width: 170px;
}
.add-comment-wrapper .comment-list .comment-item {
  margin-top: 25px;
}
.add-comment-wrapper .comment-list .comment-item .btn-default {
  padding: 6px 12px 5px;
}
.add-comment-wrapper .comment-list .comment-item .comment-text {
  color: #757575;
  font-family: 'Didact Gothic', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding-top: 10px;
}
.add-comment-wrapper .comment-list .comment-item .comment-text + div a {
  text-decoration: none;
}
.add-comment-wrapper .comment-list .comment-item .icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 14px;
}
.add-comment-wrapper .comment-list .comment-item .avatar {
  height: 70px;
  width: 70px;
}
.add-comment-wrapper .comment-list .comment-item .media-body {
  margin-top: 0;
  padding-top: 0;
  padding-left: 20px;
}
.add-comment-wrapper .comment-list .comment-item .media-body .name {
  white-space: normal;
  position: static;
}
.add-comment-wrapper .comment-list .comment-item > .comment-item {
  padding-left: 5%;
}
.add-comment-wrapper .comment-list .follow-form .btn {
  width: 100px;
}
.share-wrapper {
  font-family: 'Didact Gothic', sans-serif;
  margin: -25px 0 30px;
  font-size: 16px;
}
.share-wrapper strong {
  display: inline-block;
  vertical-align: middle;
  font-family: "Myriad Pro", sans-serif;
}
.share-wrapper .addthis_sharing_toolbox {
  list-style: none;
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
  padding: 0;
}
.share-wrapper .addthis_sharing_toolbox .at-share-btn {
  text-align: center;
  border-radius: 50%;
  margin: 0 10px;
}
.share-wrapper .addthis_sharing_toolbox .at-share-btn .at-icon {
  width: 24px!important;
  height: 24px!important;
  margin-top: 4px;
}
.share-wrapper a {
  color: #959595;
  font-size: 14px;
  margin-left: 18px;
  text-decoration: none;
}
.share-wrapper .icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 14px;
}
.followers-list-wrapper {
  margin: 40px 0 80px;
}
.followers-list-wrapper h1 {
  text-transform: uppercase;
}
.followers-list-wrapper ul.list-items {
  padding: 0;
  margin: 0;
}
.followers-list-wrapper ul.list-items li {
  list-style: none;
  padding: 20px 0;
}
.followers-list-wrapper ul.list-items li + li {
  border-top: 1px solid #959595;
}
.followers-list-wrapper ul.list-items li .small-img {
  margin-right: 20px;
  height: 70px;
  width: 70px;
}
.followers-list-wrapper ul.list-items li .btn {
  font-size: 12px;
  padding-top: 11px;
  padding-bottom: 10px;
  margin: 0 auto;
  height: 40px;
  width: 96px;
}
.followers-list-wrapper ul.list-items li .media-body .name {
  position: static;
}
.post-sidebar-wrapper a {
  color: #333;
}
#show_more_comments {
  margin-top: 15px;
}
.post-items .post-item {
  padding-bottom: 15px;
  margin-right: 0px;
  margin-bottom: 60px;
  background: white;
  border: 1px solid #bdbdbd;
}
.post-items .post-item .btn {
  margin-left: 15px;
}
.post-items .description {
  padding: 0px 15px;
}
.post-items .description .title {
  margin-top: 15px;
  font-size: 16px;
  overflow: hidden;
  text-transform: none;
}
.post-items .description .post-info span {
  font-size: 12px;
  line-height: 12px;
}
.post-items .description .post-info .comment-amt {
  margin-right: 0px;
}
.post-items .description .post-info .views {
  display: inline-block;
  margin-right: 0px;
}
.post-items .description p {
  font-size: 16px;
  line-height: 24px;
}
@media (min-width: 992px) {
  .post-items .post-item {
    position: relative;
    height: 460px;
    padding-bottom: 65px;
  }
  .post-items .post-item .btn {
    position: absolute;
    left: 15px;
    bottom: 15px;
    margin-left: 0px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .post-items .post-item-wrapper:nth-child(2n-1) {
    clear: left;
  }
  .post-items .post-item-wrapper:nth-child(2n-1):after {
    content: '';
    display: block;
    clear: both;
  }
}
@media (min-width: 1200px) {
  .post-items .post-item {
    height: 480px;
  }
  .post-items .post-item .post-info .date {
    margin-right: 15px;
  }
  .post-items .post-item .post-info .icon-calendar,
  .post-items .post-item .post-info .icon-comments {
    margin-right: 5px;
  }
  .post-items .post-item-wrapper:nth-child(4n) {
    clear: left;
  }
  .post-items .post-item-wrapper:nth-child(4n):after {
    content: '';
    display: block;
    clear: both;
  }
}
.search-block-wrapper {
  padding: 40px 0 80px;
}
.search-block-wrapper .content-right {
  padding-left: 15px;
}
.search-block-wrapper h1 {
  margin: 0 0 20px;
}
.search-block-wrapper ul.list-items {
  padding: 0;
  margin: 0;
}
.search-block-wrapper ul.list-items li {
  list-style: none;
  padding-bottom: 10px;
}
.search-block-wrapper ul.list-items li + li {
  border-top: 1px solid #959595;
  padding-top: 25px;
}
.search-block-wrapper .list-group a {
  padding: 15px 5px 15px 30px;
}
.search-block-wrapper .list-group .badge {
  background: none;
  color: #959595;
}
.search-block-wrapper .list-group .active {
  background: #2c3e50;
  border-color: #2c3e50;
  color: #fff;
}
.search-block-wrapper .list-group .active .badge {
  color: #fff;
}
.search-block-wrapper .media-user .media-body {
  padding-top: 0;
}
.search-block-wrapper .media-user .media-body .name {
  position: static;
  white-space: normal;
}
.search-block-wrapper .media-user .media-right {
  white-space: nowrap;
}
.search-block-wrapper .media-user .media-right > * {
  display: inline-block;
  vertical-align: top;
}
.search-block-wrapper .media-user .media-right .btn {
  width: auto;
  margin-left: 15px;
}
.search-block-wrapper .media-user .media-right .follow-form .btn {
  width: 100px;
}
.search-block-wrapper .media-user .small-img {
  margin-right: 12px;
  height: 70px;
  width: 70px;
}
.search-block-wrapper .media-user .description {
  color: #7b7b7b;
  font-size: 16px;
  line-height: 24px;
}
.slider-filter-group {
  margin-bottom: 15px;
}
.slider-filter-group .slider-wrap {
  margin-left: 10px;
  margin-right: 14px;
}
.slider-filter-group .item {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: 108px;
}
.slider-filter-group .dash {
  background: #2C3E50;
  display: inline-block;
  vertical-align: middle;
  margin: 0 4px;
  height: 1px;
  width: 12px;
}
.slider.slider-horizontal {
  height: 24px;
  width: 100%;
}
.slider.slider-horizontal .slider-track {
  background: #D8D8D8;
  border-radius: 9px;
  margin-top: -2px;
  height: 4px;
  box-shadow: none;
}
.slider.slider-horizontal .slider-selection {
  background: #D8D8D8;
  border-radius: 9px;
  margin-top: -2px;
  height: 4px;
  box-shadow: none;
  background: #2C3E50;
  margin-top: 0;
}
.slider.slider-horizontal .slider-handle {
  background: #00A859;
  text-align: center;
  height: 24px;
  width: 24px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.slider.slider-horizontal .slider-handle:after {
  content: "";
  background: #2C3E50;
  border-radius: 50%;
  display: inline-block;
  margin: 6px auto 0;
  height: 12px;
  width: 12px;
}
.block-job {
  background-color: #FFFFFF;
  border: 1px solid #2c3e50;
  border-radius: 4px;
  margin-top: 30px;
  padding: 10px 20px 20px;
  position: relative;
}
.block-job .name {
  margin-bottom: 3px;
  margin-top: 1px;
  word-break: break-word;
  word-wrap: break-word;
  max-width: 380px;
}
.block-job .description {
  color: #1a1a1a;
  font-size: 16px;
  line-height: 24px;
  margin-top: 10px;
  word-break: break-word;
  word-wrap: break-word;
}
.block-job .media {
  margin-top: 8px;
}
.block-job .small-avatar {
  border-radius: 50%;
}
.featured-tittle {
  font-family: 'Didact Gothic', sans-serif;
  font-size: 10px;
  background: #00a859;
  display: inline-block;
  padding: 3px 20px;
  color: #FFFFFF;
  margin-top: 30px;
  letter-spacing: 1px;
  line-height: 13px;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: 6px 6px 0 0;
}
.featured-tittle + .block-job {
  margin-top: 0;
  border-radius: 0 4px 4px 4px;
}
.featured-tittle-top {
  background: #00a859;
  padding: 1px 0 0;
  font-size: 12px;
  line-height: 24px;
  border-radius: 4px;
  display: inline-block;
  color: #fff;
  margin-bottom: 15px;
  width: 71px;
  text-align: center;
}
.basic-tittle-top {
  background: #00a859;
  padding: 1px 0 0;
  font-size: 12px;
  line-height: 24px;
  border-radius: 4px;
  display: inline-block;
  color: #fff;
  margin-bottom: 15px;
  width: 71px;
  text-align: center;
  color: #2c3e50;
  background: #EEEEEE;
}
.lightSlider .block-job {
  display: table;
  margin: 0;
  border: 1px solid #ebebeb;
  padding: 12px;
  height: 330px;
  width: 270px;
}
.lightSlider .block-job .name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 242px;
}
.lightSlider .block-job .btn {
  border-radius: 8px;
  padding: 12px 0;
  font-size: 13px;
  margin: 16px 0 10px;
  line-height: 1;
  width: 100%;
}
.lightSlider .block-job .media {
  opacity: 1;
}
.lightSlider .block-job .description {
  display: table-row;
  height: 100%;
  min-height: 100%;
}
.lightSlider .block-job .description div {
  width: 242px;
}
.job-details-wrapper {
  display: table-row;
  height: 100%;
}
.job-details-wrapper .block-info {
  overflow: hidden;
  flex: 1;
}
.job-details-wrapper .block-info .job-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.job-details-wrapper .block-info .job-info .item {
  display: inline-block;
  flex: 1;
  margin-top: 15px;
  padding-right: 16px;
  display: flex;
  align-items: flex-start;
}
.job-details-wrapper .block-info .job-info .item .icon {
  margin-right: 5px;
  margin-top: -3px;
  display: table;
}
.job-details-wrapper .job-name {
  font-size: 26px;
  line-height: 38px;
  margin: 0;
  word-break: break-word;
  word-wrap: break-word;
}
.job-details-wrapper .description {
  white-space: pre-wrap;
  word-break: break-word;
  word-wrap: break-word;
  margin: 30px 0 0;
}
.job-details-wrapper .btns-job {
  margin-top: 15px;
}
.job-details-wrapper .btns-job > * {
  margin: 0;
}
.job-details-wrapper .btns-job a {
  margin: 15px 5px;
  text-transform: uppercase;
}
.job-details-wrapper .btns-job .btn {
  min-width: 170px;
}
.job-details-wrapper .btns-job .btn:last-child {
  margin-right: auto;
}
.salary-block {
  background: #2c3e50;
  border-radius: 8px;
  color: #FFFFFF;
  padding: 10px 20px;
}
.salary-block .larg {
  font-size: 24px;
  line-height: 30px;
}
.between {
  margin-left: -15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.between > * {
  margin-left: 15px;
}
.between.center {
  align-items: center;
}
.media.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.media.flex .media-body {
  flex: 1;
  width: auto;
}
.media.flex .form-inline label {
  margin-top: 0;
  line-height: 1;
}
.prev-btn-link {
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
}
.prev-btn-link .glyphicon {
  font-size: 21px;
  vertical-align: top;
}
.remove-link {
  color: #FF0000;
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
}
.list-job-applications {
  background-color: #fff;
  border: 1px solid #2c3e50;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 30px;
}
.list-job-applications .tittle {
  background-color: #2c3e50;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  padding: 20px;
  text-transform: uppercase;
}
.list-job-applications .item {
  padding: 15px;
}
.list-job-applications .item + .item {
  border-top: 1px solid #ededed;
}
.list-job-applications .item .media,
.list-job-applications .item p {
  margin-bottom: 15px;
}
.dotdotdot {
  overflow: hidden;
  display: block;
}
.dotdotdot .text-gray {
  margin-top: 15px;
}
.dotdotdot.open {
  height: auto!important;
}
.btn-more {
  cursor: pointer;
}
.btn-more .icon.top {
  transform: rotate(180deg);
  margin-bottom: 3px;
}
#modal-share .register-form-wrapper {
  border: none;
  margin: 0 15px;
  width: auto;
}
#modal-share .modal-dialog {
  max-width: 400px;
}
#modal-share .modal-dialog .modal-header h4 {
  float: left;
  width: 96%;
  margin: 0;
}
#modal-share .modal-dialog .modal-footer {
  padding: 15px 30px;
}
.apply-job-form {
  max-width: 570px;
  float: right;
  margin-right: 20px;
  width: 100%;
}
.apply-job-form .register-form-wrapper .form-wrapper {
  padding: 30px 15px;
}
.apply-job-form .register-form-wrapper .form-wrapper > p {
  word-break: break-word;
  word-wrap: break-word;
}
.file-group .btn-file-input,
.register-form-wrapper form .form-group .file-group .btn-file-input {
  position: relative;
  padding-right: 80px;
  line-height: 1;
  cursor: pointer;
  margin: 0;
  font-size: 0;
  width: 100%;
}
.file-group .btn-file-input input,
.register-form-wrapper form .form-group .file-group .btn-file-input input {
  display: none;
}
.file-group .btn-file-name,
.register-form-wrapper form .form-group .file-group .btn-file-name {
  padding: 14px 12px 8px;
  text-align: center;
  background: #2c3e50;
  border-radius: 0 4px 4px 0;
  color: #fff;
  position: absolute;
  font-size: 14px;
  right: 0;
  top: 0;
  height: 100%;
  width: 90px;
}
.file-group .file-name,
.register-form-wrapper form .form-group .file-group .file-name {
  line-height: 1.42857143;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-group .file-name span,
.register-form-wrapper form .form-group .file-group .file-name span {
  opacity: 0.6;
}
.caption-table {
  margin-bottom: 24px;
}
.caption-table h1 {
  margin-top: 7px;
  margin-bottom: 5px;
}
.active-tble {
  background-color: #fff;
  border: 1px solid #2c3e50;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 20px;
}
.active-tble .table-responsive .table {
  margin-bottom: 0;
  width: 100%;
}
.active-tble .table-responsive .table > thead {
  background: #F0F0F0;
}
.active-tble .table-responsive .table > thead > tr > th {
  border: none;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
  padding-top: 15px;
  text-transform: uppercase;
  white-space: nowrap;
}
.active-tble .table-responsive .table tbody > tr > td {
  vertical-align: middle;
  font-size: 13px;
  word-break: break-word;
  word-wrap: break-word;
}
.active-tble .table-responsive .table .nowrap {
  white-space: nowrap;
}
.active-tble .table-responsive .table .td-btn {
  max-width: 186px;
  white-space: normal;
}
.active-tble .table-responsive .table .finish-btn {
  width: 200px;
}
.active-tble .table-responsive .table .icons-th {
  width: 40px;
}
.active-tble .table-responsive .table .checkbox-th {
  width: 38px;
  text-align: center;
}
.active-tble .table-responsive .table .checkbox-th .check label {
  margin: 0 auto;
  display: block;
  padding: 0;
  min-height: 18px;
  line-height: 19px;
  width: 16px;
}
.active-tble .table-responsive .table .checkbox-th .check .checed {
  background: #fff;
}
.active-tble .table-responsive .table .checkbox-th .check input:checked + .checed {
  background: #00a859;
}
.active-tble .table-responsive .table .featured-tittle-top,
.active-tble .table-responsive .table .basic-tittle-top {
  margin-bottom: 0;
  line-height: 17px;
  font-size: 10px;
}
.active-tble + .btn-bottom .btn {
  text-transform: uppercase;
}
.active-jobs .pagination-wrapper .pagination {
  margin-top: 10px;
}
.job-plan-name {
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
}
.job-search-wrapper {
  margin: 40px 0;
  display: table;
  table-layout: fixed;
  width: 100%;
}
.job-search-wrapper .forms-filter {
  display: table-cell;
  vertical-align: top;
  padding: 0;
  width: 270px;
}
.job-search-wrapper .left-sidebar {
  float: none;
  padding: 0 13px;
  margin: 0;
}
.job-search-wrapper .middle-content {
  display: table-cell;
  vertical-align: top;
  padding: 0 310px 40px 30px;
  width: 100%;
}
.job-search-wrapper .middle-content .input-group {
  width: 100%;
}
.job-search-wrapper .right-sidebar {
  display: inline-block;
  vertical-align: top;
  margin-top: 0;
}
.job-search-wrapper .btn.post-new-job {
  padding: 4px 12px;
  margin-left: 5px;
}
#jobs-search-input {
  z-index: 0;
}
@media (max-width: 992px) {
  .profile-info .vertical-line {
    right: 340px;
  }
  .activity-wrapper .left-content {
    margin: 40px -6px;
  }
  .activity-wrapper .right-sidebar {
    display: none;
  }
  .block-job .name {
    max-width: 254px;
  }
  .job-search-wrapper {
    display: block;
    position: relative;
    min-height: 550px;
  }
  .job-search-wrapper .forms-filter {
    position: absolute;
    left: 0;
    top: 0;
  }
  .job-search-wrapper .middle-content {
    display: block;
    padding: 0 0 40px 310px;
  }
  .job-search-wrapper .right-sidebar {
    display: inline-block;
  }
}
@media (max-width: 767px) {
  body {
    display: block!important;
  }
  .advertising-item.advertising-item--no-margin {
    margin-bottom: 30px;
  }
  .header-main .header-top {
    padding: 5px 0;
  }
  .header-main .header-top.header-top-logout {
    padding-bottom: 40px;
  }
  .header-main .header-top.header-top-logout #google_translate_element {
    position: absolute;
    right: 10px;
    bottom: -80px;
    margin: 0px;
  }
  .header-main.header-auth .navbar .navbar-header {
    position: absolute;
    top: -108px;
    right: 26px;
  }
  .header-main.header-auth .navbar-toggle {
    margin: 0;
  }
  .header-main .logo img {
    position: static;
    max-width: 137px;
  }
  .header-main .menu-top {
    margin: 20px 0 0;
  }
  .header-main .menu-top .user-avatar-wrapper,
  .header-main .menu-top .dropdown-content {
    display: none !important;
  }
  .header-main .menu-top .notification-wrapper {
    margin: -3px 50px 0 2px;
  }
  .header-main .menu-top .menu-chat-wrapper {
    margin-right: 16px;
    margin-top: 0px;
  }
  .header-main .search-navbar-form {
    display: none;
    margin: 19px 0 0;
  }
  .header-main .search-navbar-form .input-group input {
    height: 38px;
    width: 60%;
  }
  .header-main .search-navbar-form .input-group select {
    height: 38px;
    width: 40%;
  }
  .header-main .search-navbar-form .input-group button {
    padding: 10px 12px;
    font-size: 13px;
  }
  .header-main .navbar {
    min-height: 0;
  }
  .header-main .navbar .navbar-header {
    position: absolute;
    top: -90px;
    right: 26px;
  }
  .header-main .navbar-inverse .navbar-nav li a {
    padding: 5px 30px;
  }
  .slider-section .slider-one-rows .lightSlider li {
    height: 560px;
  }
  .slider-section .slider-one-rows .lightSlider .item .wrap {
    height: 360px;
  }
  .navbar-toggle {
    float: none;
    margin: 8px auto;
    display: table;
  }
  .sign-in-form .form-group {
    margin-top: 20px;
  }
  .footer-main {
    padding-top: 20px;
  }
  .footer-main ul li {
    margin-bottom: 4px;
  }
  .footer-main ul li a {
    font-size: 11px;
  }
  .footer-main .title {
    margin: 0 0 15px;
  }
  .footer-main .logo {
    margin: 5px 0 10px;
  }
  .footer-main .social-links {
    margin-bottom: 15px;
  }
  .footer-main .copy {
    text-align: center;
  }
  .footer-main .footer-nav-bottom {
    float: none;
    text-align: center;
  }
  .footer-main .footer-nav-bottom li {
    float: none;
    display: inline-block;
  }
  svg.bigHalfCircle {
    max-height: 2%;
  }
  .banner-top p {
    font-size: 15px;
    line-height: 25px;
  }
  section h2 {
    font-size: 21px;
    margin: 43px 0 35px;
  }
  .about-project .two-colors {
    background: none;
  }
  .about-project .two-colors h2 {
    margin-top: 0;
    padding-top: 17px;
  }
  .about-project .two-colors p {
    margin-bottom: 0;
    padding-bottom: 10px;
  }
  .about-project .two-colors .col-sm-6:nth-child(1) {
    padding-right: 15px;
    background: red;
  }
  .about-project .two-colors .col-sm-6:nth-child(2) {
    padding-left: 15px;
    background: #00a859;
  }
  .about-project .two-colors .top {
    fill: #ff0000;
    top: -2%;
    bottom: auto;
  }
  .about-project .two-colors .bottom {
    fill: #00a859;
    bottom: -1.9%;
    top: auto;
    z-index: 1;
  }
  .left-sidebar {
    margin-top: 0;
    position: fixed;
    left: 0;
    right: auto;
    overflow-y: auto;
    overflow-x: hidden;
    top: 2%;
    height: 90%;
    display: block;
    opacity: 0;
    transform: translateX(-100%);
    will-change: transform, opacity;
    transition: all 0.3s ease-in-out;
    z-index: 10;
  }
  .left-sidebar.open {
    display: block;
    transform: translateX(0);
    opacity: 1;
    top: 60px;
    z-index: 100;
  }
  .right-content {
    margin: 40px 0;
  }
  .btn-filter-mobile {
    display: block;
    transform: translateX(0);
    will-change: transform;
    transition: all 0.3s ease-in-out;
    z-index: 100;
  }
  .btn-filter-mobile.open {
    transform: translateX(270px);
  }
  .right-sidebar {
    display: none;
  }
  .left-content {
    margin: 40px -6px;
  }
  .middle-content {
    flex: 0 0 100%;
    padding: 0;
    width: 100%;
  }
  .middle-content + .right-sidebar {
    display: block;
    margin: 0 auto;
    float: none;
  }
  .forum-wrapper .left-content,
  .job-details-wrapper .left-content {
    float: none;
    padding-right: 0;
  }
  .forum-wrapper .right-sidebar,
  .job-details-wrapper .right-sidebar {
    display: block;
    float: none;
    margin: 0 auto;
  }
  .forum-info .icons-legend {
    width: 100%;
  }
  .forum-info > div:nth-child(1) {
    width: 100%;
    margin: 0 auto 20px;
  }
  .forum-info > div:nth-child(2) {
    width: 100%;
  }
  .profile-info .title .setting-btn {
    max-width: 100px;
  }
  .profile-info .title .qualifications {
    bottom: -26px;
    border-radius: 0 0 6px 6px;
    left: 50%;
    transform: translateX(-50%);
  }
  .profile-info .title .setting-btn,
  .profile-info .title .switch-btn {
    width: 50%;
    max-width: 100%;
    margin-right: 0;
    min-width: auto;
  }
  .profile-info .avatar-profile {
    margin: 30px auto 0;
    float: none;
  }
  .profile-info .info-right {
    width: auto;
    float: none;
  }
  .profile-info .info-right .edit {
    right: 0;
  }
  .profile-info .vertical-line {
    display: none;
  }
  aside {
    float: none;
    width: 100%;
  }
  .content-right {
    margin-left: 0;
  }
  .profile-settings .menu-settings {
    position: static;
  }
  .modile-message-chat-wrapper .container {
    padding: 0;
  }
  .modile-message-chat-wrapper .row,
  .modile-message-chat-wrapper .col-sm-12 {
    padding: 0;
    margin: 0;
  }
  .modile-message-chat-wrapper .conversation-settings.open {
    transform: translateX(0);
    opacity: 1;
  }
  .modile-message-chat-wrapper .message-chat-wrapper {
    margin: 0;
    border: none;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .message-list-wrapper {
    margin-top: 0;
    position: fixed;
    top: 0;
    height: 100%;
    display: block;
    opacity: 0;
    transform: translateX(-100%);
    will-change: transform, opacity;
    transition: all 0.3s ease-in-out;
    z-index: 10;
    width: 100%;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .message-list-wrapper.open {
    display: block;
    transform: translateX(0);
    opacity: 1;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .message-list-wrapper .header {
    padding: 10px 6px 15px;
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .message-list-wrapper .header .messages-search {
    margin: 0;
    width: 86%;
    float: left;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .message-list-wrapper .header > .compose-btn {
    text-align: right;
    margin: 6px 7px 0 0;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .message-list-wrapper .baron {
    padding-top: 59px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100%;
    min-height: 100%;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .message-list-wrapper .message-list {
    max-height: 100%;
    overflow: visible;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .message-list-wrapper .message-list li a {
    padding: 10px 6px 5px 30px;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .message-list-wrapper .message-list .media {
    position: relative;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .message-list-wrapper .message-list .media .timestamp {
    position: absolute;
    right: 0;
    top: -3px;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .message-list-wrapper .message-list .name {
    padding-right: 53px;
    width: 74%;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper {
    border: none;
    margin: 0;
    bottom: 0;
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    will-change: transform, opacity;
    transition: all 0.3s ease-in-out;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper.closed {
    opacity: 0;
    transform: translateX(100%);
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper.closed-left {
    opacity: 0;
    transform: translateX(-100%);
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper .header {
    padding: 0 10px 0 40px;
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper .header .media {
    margin-top: 6px;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper .header .name {
    top: 14px;
    font-size: 14px;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper .header .small-img {
    height: 28px;
    width: 28px;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper .header .type,
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper .header p {
    display: none;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper .header .btn.btn-default.btn-circle {
    margin: 0;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper .header .media-left {
    vertical-align: middle;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper .chat-list {
    padding-top: 6px;
    height: 100%;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper .chat-list ul > li {
    padding: 10px 6px;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper .chat-list .small-img {
    height: 48px;
    width: 48px;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper .chat-list .media-left {
    padding-right: 0;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper .chat-list .name {
    padding-right: 68px;
    width: 95%;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper .baron {
    height: 100%;
    padding: 45px 0 100px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper .footer {
    padding: 10px 10px 5px 0;
    height: auto;
    position: fixed;
    bottom: 0;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper .footer .message-form textarea {
    height: 50px;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper .footer .media-left {
    display: none;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper .footer .attachments-bar label {
    margin-left: 15px;
    margin-right: 15px;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper .footer .pull-right {
    margin: 0;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper.compose_form_wrapper {
    overflow: hidden;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper.compose_form_wrapper .header {
    position: relative;
    padding: 6px 30px 10px;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper.compose_form_wrapper .baron {
    height: auto;
    padding: 0;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper.compose_form_wrapper .footer {
    position: relative;
  }
  .modile-message-chat-wrapper .message-chat-wrapper .chat-wrapper.compose_form_wrapper .footer .emojionearea-button {
    display: none;
  }
  .mobile-version .header-main,
  .mobile-version .footer-main {
    display: none;
  }
  .mobile-version .emojionearea .emojionearea-editor {
    min-height: 50px;
  }
  .mobile-version .chat-live-fixed {
    display: none;
  }
  .job-plan-wrapper .job-plan {
    width: 100%;
  }
  .between {
    margin-left: 0;
  }
  .between > * {
    margin-left: 0;
  }
  .job-details-wrapper .block-info {
    flex: 0 0 100%;
  }
  .job-details-wrapper .block-info .job-info .item {
    flex: 0 0 100%;
  }
  .job-details-wrapper .description-wrapper,
  .job-details-wrapper .job-name-wrapper {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  .job-details-wrapper .salary-block {
    margin-top: 12px;
  }
  .job-details-wrapper .btns-job {
    margin-left: 0;
    text-align: center;
  }
  .job-details-wrapper .btns-job > * {
    margin: 0 auto;
    width: 100%;
  }
  .job-details-wrapper .btns-job a {
    margin: 7px auto;
    display: table;
  }
  .job-details-wrapper .btns-job .prev-btn-link {
    margin: 15px auto;
    order: 1;
  }
  .apply-job-form {
    float: none;
    margin-right: 0;
  }
  .register-form-wrapper form .two-btn {
    margin: 0;
  }
  .register-form-wrapper form .two-btn .btn {
    float: none!important;
    margin: 10px auto;
    display: table;
    width: 100%;
  }
  .active-jobs .table-responsive {
    margin-bottom: 0;
  }
  .error404 #google_translate_element,
  .error500 #google_translate_element {
    margin-top: 0;
  }
  .confirm-modal .modal-footer .btn {
    margin: 8px auto 0;
  }
  .block-job .name {
    max-width: 200px;
  }
  .job-search-wrapper {
    margin: 0;
  }
  .job-search-wrapper .middle-content {
    padding: 0;
  }
}
@media (max-width: 480px) {
  .register-form-wrapper .steps-wrapper .line-step {
    margin: 0;
    width: 5%;
  }
  .register-form-wrapper #step-1 .radio-wraper {
    padding: 30px 0;
    height: 90px;
    width: 90%;
  }
  .block-brofile {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
  .share-wrapper {
    text-align: center;
  }
  .share-wrapper .share-buttons {
    margin-top: 15px;
  }
  .share-wrapper .pull-right {
    float: none !important;
  }
  .followers-list-wrapper .media-right,
  .followers-list-wrapper .btn {
    display: block;
    width: 100% !important;
  }
  .partners .pull-right {
    float: none !important;
  }
  .activity-wrapper .h1 {
    font-size: 30px;
  }
  .activity-wrapper .activity-list > .media > .media-left {
    display: block;
    padding-right: 30px;
    padding-bottom: 5px;
  }
  .activity-wrapper .activity-list > .media .media {
    overflow: hidden;
  }
  .activity-wrapper .activity-list > .media > .media-body {
    padding: 10px;
  }
  .activity-wrapper .activity-list > .media .media-right {
    display: block;
    margin: 10px 0;
    padding: 0;
  }
  .successfully-wrapper {
    padding: 17px 0;
    margin: 20px auto 20px;
  }
  .successfully-wrapper .successfully .logo {
    margin-bottom: 25px;
  }
  .search-block-wrapper .media-user .media-right {
    display: block;
  }
  .share-wrapper .addthis_sharing_toolbox {
    margin: 15px;
  }
  .post-info .icon {
    margin-right: 8px;
  }
  .post-info > span {
    margin-right: 10px;
  }
  .chat-live-fixed {
    right: auto;
    left: 0;
  }
  .chat-live-fixed .chat-live-wrapper .chat-live .chat-list {
    max-width: 320px;
  }
  .chat-live-fixed .chat-live-wrapper .message-list-wrapper {
    max-width: 320px;
  }
  .slider-section .slider-one-rows .lightSlider .item {
    padding-top: 150px;
  }
  .slider-section .slider-one-rows .lightSlider .item .wrap {
    height: auto;
  }
  .slider-section .slider-one-rows .lightSlider li {
    height: auto;
  }
  .register-form-wrapper form .form-group label {
    margin-bottom: 4px;
  }
  .register-form-wrapper form label.error {
    margin-top: 0;
  }
  .register-form-wrapper form .form-group label {
    line-height: 1.2;
  }
  .register-form-wrapper {
    margin: 0 auto 10px;
  }
  .register-form-wrapper #step-3 .prev-step {
    margin: 10px auto 5px;
  }
  .croppie-container .cr-boundary {
    width: 100% !important;
  }
  .language-formset .form-group {
    width: 100%;
  }
  .profile-info .list-languages .item .name {
    width: 64%;
  }
  .profile-info h3 {
    width: 57%;
  }
  .profile-settings .upload-logo-wrapper .btn + .btn {
    margin-top: 10px;
  }
  .modal-header {
    padding: 6px;
  }
  .modal-body {
    padding: 5px;
  }
  .crop-img-wrapper.ready #upload-advertise,
  .crop-img-wrapper.ready #upload-img {
    margin: 3px auto 24px;
  }
  .profile-settings .settings-block .item .short {
    max-width: 248px;
  }
  .add-comment-wrapper .comment-list .comment-item .media-right {
    display: block;
  }
  #form-subscribe .btn {
    width: 100px;
  }
  .footer-main .social-links a {
    margin: 0 1px;
    width: 15%;
  }
  .accordion-simple .panel-body {
    width: 100%;
  }
  .message-chat-wrapper .chat-wrapper .chat-list li {
    position: relative;
  }
  .message-chat-wrapper .chat-wrapper .chat-list .message {
    max-width: 90%;
  }
  .message-chat-wrapper .chat-wrapper .chat-list .media-right {
    position: absolute;
    right: 12px;
    top: 6px;
  }
  .message-chat-wrapper .chat-wrapper .chat-list .name.text-right {
    padding-right: 68px;
  }
  .table-responsive .username {
    white-space: normal !important;
  }
  .team .social-links .icon {
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 3px;
    height: 30px;
    width: 30px;
  }
  .team .social-links .icon-facebook {
    background-image: url(../images/facebook.png);
  }
  .team .social-links .icon-google {
    background-image: url(../images/google.png);
  }
  .team .social-links .icon-linkedin {
    background-image: url(../images/linkedin.png);
  }
  .team .social-links .icon-twitter {
    background-image: url(../images/twitter.png);
  }
  .job-steps.steps-wrapper:after {
    display: none;
  }
  .job-steps.steps-wrapper .step {
    font-size: 14px;
    width: 57px;
    height: 57px;
  }
  .job-steps.steps-wrapper .step:before {
    height: 20px;
    width: 11px;
  }
}
@media (min-width: 768px) {
  .header-main {
    height: 190px;
  }
  .header-main.header-home {
    height: 167px;
  }
  .header-main.header-auth {
    height: 202px;
  }
  .header-main .header-content {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    background-color: #2c3e50;
  }
  .header-main .nav-wrapper {
    border-color: #1A2B3A;
  }
  .profile-info #edit-description,
  .profile-info #edit-hiring,
  .profile-info .col-sm-12 #edit-skills {
    width: 45%;
  }
}
@media (max-width: 1200px) {
  .header-main .navbar-inverse .navbar-nav li a {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media (max-width: 992px) {
  .header-main .navbar-inverse .navbar-nav li a {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 15px;
  }
}
