.header-main {
    background-color: @gray-dark;
    color: #fff;
    position: relative;
    z-index: 2000;

    .header-top {
        padding: 20px 0 0;
        &.header-top-logout {
            padding-bottom: 20px;
        }
        &.no-padding-bottom {
            padding-bottom: 0px;
        }
    }
    .logo {
        margin-top: 5px;
        display: block;
        position: relative;

        img {
            max-width: 228px;
            height: auto;
            position: absolute;
            top: -12px;
            width: 100%;
        }
    }

    .search-navbar-form {
        margin: 7px 0;

        .input-group {

            input {
                border-right: none;
                height: 46px;
                width: 70%;
            }
            select, .styler {
                height: 46px;
                line-height: 34px;
                width: 30%;
            }
            button {
                color: #fff;
                padding: 12px 17px;
                line-height: 1;
                font-size: 17px;
                height: 46px;
            }
        }

        .jq-selectbox__select-text {
            padding-right: 10px;
        }
    }

    .menu-top {
        padding: 0;
        margin: 30px 0 0 0;
        li {
            list-style: none;
            float: right;
            position: relative;
            margin: 0 0 0 20px;
            a {
                font-size: 14px;
                text-transform: uppercase;
            }
            .color-red {
                color: @color-red;
            }
            .color-yellow {
                color: @color-yellow;
            }
        }
    }

    .navbar-inverse {
        background-color: transparent;
        border: none;
        margin: 0;
        border-top: 0.5px solid @color-gray;
        .navbar-nav {
            float: none;
            margin: 0 auto;
            display: table;

            li {
                &:hover, &.active {
                    &:nth-child(3n+1) {
                        a {
                            color: @color-green;
                        }
                    }
                    &:nth-child(3n+2) {
                        a {
                            color: @color-yellow;
                        }
                    }
                    &:nth-child(3n+3) {
                        a {
                            color: @color-red;
                        }
                    }
                }
                a {
                    color: #ccc;
                    font-size: 16px;
                    text-transform: uppercase;
                    padding: 20px 30px 18px;

                    .icon {
                        margin-right: 5px;
                    }
                }
                hr {
                    margin: 2px 0 6px;
                }
            }

            .active {
                a {
                    background-color: transparent;
                    color: @color-green;
                }
            }
        }
    }

    .user-menu-top {
        margin-top: 12px;

        .notification-wrapper {
            margin: 12px 30px 0 0;
            .dropdown-content {
                a {
                   & + a {
                       border-top: 1px solid #ebebeb;
                   }
               }
            }
        }

        .menu-chat-wrapper {
           .notification-wrapper;
        }

        .user-avatar-wrapper {
            margin: 0;
            height: 44px;
            width: 44px;

            img {
                height: 44px;
                width: 44px;
            }

            .dropdown-content {
                a {
                    &:hover, .active {
                        background: @color-blue;
                        color: #fff;
                        text-decoration: none;
                    }
                }

                .type {
                    border-radius: 6px;
                    font-family: @Didact-Gothic;
                    font-size: 10px;
                    padding: 7px 20px;
                    text-transform: uppercase;
                    text-decoration: none;
                    &.yellow {
                        background-color: @yellow-lighter;
                        border-color: @yellow-lighter;
                    }
                }
            }


        }

        > li  {
            position: relative;

            &:hover {
                .dropdown-content {
                    display: block;
                }
            }
        }

        .count{
            background: @color-red;
            font-family: @Didact-Gothic;
            font-size: 11px;
            color: #fff;
            border-radius: 50%;
            text-align: center;
            width: 19px;
            height: 19px;
            display: inline-block;
            position: absolute;
            line-height: 17px;
            top: -6px;
            right: -4px;
        }

        .dropdown-content {
            position: absolute;
            right: 0;
            top: 100%;
            padding-top: 10px;
            display: none;
            width: 280px;
            z-index: 10000;

            .wrapper {
                border-radius: 4px;
                box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
                position: relative;

                &:before {
                  content: '';
                  position: absolute;
                  top: -7px;
                  right: 9px;
                  display: inline-block;
                  border-right: 7px solid transparent;
                  border-bottom: 7px solid #ccc;
                  border-left: 7px solid transparent;
                  border-bottom-color: rgba(0, 0, 0, 0.2);
                }

                &:after {
                  content: '';
                  position: absolute;
                  top: -6px;
                  right: 10px;
                  display: inline-block;
                  border-right: 6px solid transparent;
                  border-bottom: 6px solid #ffd400;
                  border-left: 6px solid transparent;
                }

                > * {
                    background-color: #fff;
                    display: block;
                    padding: 15px 12px;
                    color: @color-gray;
                    text-transform: none;
                    text-decoration: none;
                }

                .tittle {
                    background-color: #ffd400;
                    border-radius: 4px 4px 0 0;
                    color: @color-blue;
                    font-weight: 700;
                    padding: 15px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .icon {
                    margin-right: 5px;
                    vertical-align: text-top;
                }

                .logout {
                    border-top: 1px solid #ccc;
                    border-radius: 0 0 4px 4px;
                }
                .media-user {
                    .media-body {
                        padding-top: 0;
                    }
                }

                .media {
                    .small-img {
                        width: 48px;
                        height: 48px;
                    }
                    .name {
                        font-size: 12px;
                        position: relative;
                        white-space: normal;
                    }
                }

                .see-all {
                    background: @color-green;
                    border-radius: 0 0 4px 4px;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 700;
                    text-align: center;
                    text-transform: uppercase;
                    text-decoration: none;
                }
            }


        }
    }
}

.top-alert {
    background: @yellow-lighter;
    padding: 10px 0;
    button {
        background: transparent;
        border: none;
        font-weight: 700;
        text-decoration: underline;
        padding: 0;
        &:hover {
            text-decoration: none;
        }
    }
}

#google_translate_element {
    height: 28px;
    width: 220px;
    margin-top: 20px;
    margin-bottom: 20px;
    float: right;
    .goog-te-gadget-simple {
        background: @color-yellow;
        border-radius: 8px;
        font-size: 14px;
        line-height: 20px;
        padding: 4px 10px;
        border: none;
        display: block;
        width: 220px;
        .goog-te-gadget-icon {
            display: none;
        }
        .goog-te-menu-value {
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
                border: none!important;
                &[aria-hidden="true"] {
                    color: @color-black!important;
                    float: right;
                    display: inline-block;
                    font-size: 0;
                    width: 0;
                    height: 0;
                    border-style: solid!important;
                    border-width: 5px 5px 0 5px!important;
                    border-color: #1a1a1a transparent transparent transparent !important;
                }
            }
        }
    }
    &.open {

    }
}
.goog-te-menu-frame {
    margin-top: 4px;
    width: 220px!important;
}
